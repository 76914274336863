import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Loader from '../Loader'

const AuthRoute = ({ children }) => {
  const { currentUser, loading } = useAuth()
  const location = useLocation()

  if(loading) return <Loader />

  if(!currentUser) return <Navigate to="/login" state={{from: location}} replace/>

  return children
};

export default AuthRoute;