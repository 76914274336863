import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Carousel, Image } from "react-bootstrap";
import { useNavigate } from "react-router";

const HomePageCarousel = ({ list }) => {

  const navigate = useNavigate()
  
  const [index, setIndex] = useState(0)

  return (
    <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
      <div className="d-flex align-items-center">
        <FaChevronLeft onClick={() => {if(index > 0) setIndex(prev => prev-1)}} style={{cursor: 'pointer', color: index <= 0 && 'grey'}}/>
      </div>
      <Carousel activeIndex={index} indicators={false} interval={null} controls={false} style={{width: '100%'}}>
        {!!list && list?.length === 0 &&
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            <span>No Items To Show</span>
          </div>
        }
        {!!list && list.map(item => (
          <Carousel.Item className="card border-muted text-center shadow-sm">
            <div className="p-1 text-center">
              <h6
                className="bg-light p-2 rounded border-muted shadow-sm"
                style={{whiteSpace: 'nowrap', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
              >
                <span className="font-weight-bold">{item.title}</span>
              </h6>
              {!item.image ?
                <div style={{
                  height: '300px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                  }}
                >
                  Failed to load capsule pages
                </div>
              :
                <Image
                  fluid
                  src={item?.image}
                  style={{height: '300px', objectFit: 'cover', width: '100%', cursor: 'pointer'}}
                  onClick={() => navigate(item?.link)}
                />
              }
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      {!!list && (
        <div className="d-flex align-items-center">
          <FaChevronRight onClick={() => {if(index < list.length - 1) setIndex(prev => prev+1)}} style={{cursor: 'pointer', color: index >= list.length - 1 && 'grey'}}/>
        </div>
      )}
    </div>
  )
  
  
};

export default HomePageCarousel;
