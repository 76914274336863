import React, { useState, useEffect, useRef, useCallback } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { collection, doc, getDoc, getDocs, query, where, orderBy, limit, startAfter } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Card from "react-bootstrap/Card";
import { useAuth } from "../context/AuthContext";
import { ButtonGroup, Button } from "react-bootstrap";
import { FaTable } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import Acquisitions from "../components/Acquisitions";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";


function AcquisitionStatusBuyer() {

  const [display, setDisplay] = useState('card')
  
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [itemsPerPage] = useState(8)
  const [hasMore, setHasMore] = useState(true)
  const observer = useRef()

  const { currentUser } = useAuth()

  const getOrders = useCallback(async () => {
    if(loading || !hasMore) return;
    setLoading(true)

    try {
      const lastDoc = orders.length > 0 ? orders[orders.length - 1].docRef : null
      let q = query(
        collection(db, "orders"),
        where('userId', '==', currentUser?.uid),
        orderBy('createdOn', 'desc'),
        limit(itemsPerPage)
      )

      if(lastDoc){
        q = query(q, startAfter(lastDoc))
      }

      const querySnapshot = await getDocs(q)

      if(!querySnapshot.empty){
        const newData = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id, docRef: doc}))
        const ordersWithDetails = await Promise.all(newData.map(async order => {
          const pageDoc = await getDoc(doc(db, 'posts', order?.postId, 'pages', order?.pageNumber < 10 ? `0${order?.pageNumber}` : order?.pageNumber.toString()))
          const page = pageDoc.data()
          return {...order, details: page?.text || ''}
        }))
        setOrders(prev => prev.concat(ordersWithDetails))
        if(querySnapshot.size < itemsPerPage) setHasMore(false)
      }else{
        setHasMore(false)
      }

    } catch (error) {
      console.error('Error fetching data:', error)
    } finally { setLoading(false)}


  }, [orders, loading, hasMore, currentUser])
  
  const lastElementRef = useCallback(node => {
    if(loading) return;
    if(observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore){
        getOrders()
      }
    })
    if(node) observer.current.observe(node)
  }, [loading, hasMore, getOrders])

  useEffect(() => {
    if(currentUser){
      getOrders()
    }
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {currentUser && orders && (
        <>
          {" "}
          <Card>
            <span className="text-center">
              <Card.Header as="h5">INCOMING ACQUISITIONS</Card.Header>
            </span>
            <Card.Body>
              <div className="d-flex w-full mb-3" style={{justifyContent: 'center'}}>
                <ButtonGroup size="sm">
                  <Button
                    variant="secondary"
                    className={`d-flex justify-center align-items-center ${display !== 'card' && 'bg-light border-light'}`}
                    onClick={() => setDisplay('card')}
                  >
                    <BsFillGrid3X3GapFill style={{color: display !== 'card' && 'black'}}/>
                  </Button>
                  <Button
                    variant="secondary"
                    className={`d-flex justify-center align-items-center ${display !== 'table' && 'bg-light border-light'}`}
                    onClick={() => setDisplay('table')}
                  >
                    <FaTable style={{color: display !== 'table' && 'black'}}/>
                  </Button>
                </ButtonGroup>
              </div>
              <Card.Title as="h6" className="mx-3 text-muted">
                <span>
                  Here are inquiries on artworks you want to purchase.
                </span><br/>
                <span>
                  The seller will update the status of the inquiry and you will receive notifications.
                </span><br/>
                <span>
                  Once an inquiry is no longer active, you can archive it.
                </span>
              </Card.Title>
              <div className="d-flex justify-content-end mx-2">
                <Link to='/archived-acquisitions' style={{fontSize: '20px', color: 'black', textDecoration: 'none'}}>VIEW ARCHIVED</Link>
              </div>
              <Acquisitions items={orders} getItems={getOrders} setOrders={setOrders} display={display}/>
              {loading && <Loader/>}
              <div ref={lastElementRef}/>
            </Card.Body>
          </Card>
        </>
      )}
    </DefaultLayout>
  );
}

export default AcquisitionStatusBuyer;
