// @ts-check
import { Upload } from 'lucide-react'
import React from 'react'

/**
 * @typedef {import('./types').IDragAndDrop} IDragAndDrop
 */

/**
 * @param {IDragAndDrop} props 
 */
export const DragAndDrop = ({ onChange, disabled, onError, legend }) => {
  
  /**
   * @type {import('react').DragEventHandler<HTMLLabelElement>} 
   */
  const onDrop = (e) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    if (e.dataTransfer) {
      [...e.dataTransfer.items].forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          if (file) {
            onChange(file);
          }
        }
      });
    }
  };

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onChangeInputFile = (event) => {
    const { validity, files } = event.target;
    console.log("on change",files);
    
    if (FileReader && validity.valid && files?.length) {
      const mFile = files[0];
      if (mFile) {
        onChange(mFile);
      }
    }
  };
  return (
    <>
      <input
        type="file"
        accept=".pdf"
        className="sr-only hidden"
        id="drop-file"
        onChange={onChangeInputFile}
        disabled={disabled}
      />
      <label
        data-testid="label-input-file"
        draggable
        htmlFor="drop-file"
        className={`my-4 flex flex-col items-center justify-center rounded-md border-[1px] border-dashed py-4 dark:border-tertiary dark:text-tertiary ${
          !disabled
            ? "border-black"
            : "border-secondary-clear text-secondary-clear"
        }`}
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <Upload className="h-10 w-10 mb-4 stroke-1" />
        <p className="w-1/2 text-center font-medium dark:text-tertiary">
          {legend ? (
            legend
          ) : (
            <span>
              Drag & Drop or <span className="text-[#1E64B8]">Choose file</span>{" "}
              to upload
            </span>
          )}
        </p>
        <p className="font-light">PDF files only, max 30MB each</p>
      </label>
    </>
  );
}
