import React, { createContext, useState, useEffect, useContext } from 'react';
import {
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    getAuth,
    sendPasswordResetEmail,
    confirmPasswordReset,
  } from "firebase/auth";
import 'firebase/auth';
import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from "../firebaseConfig"
import moment from 'moment';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState()
  const [currentUserRealTime, setCurrentUserRealTime] = useState()
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(true)
  const auth = getAuth()

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  };

  const logout = () => {
    return signOut(auth)
  };

  const forgotPassword = (email) => {
    return sendPasswordResetEmail(auth, email)
  }

  const changePassword = (code, newPassword) => {
    return confirmPasswordReset(auth, code, newPassword)
  }

  const isConnoisseur = () => currentUserRealTime?.role === 'seller'
  const isCurious = () => currentUserRealTime?.role === 'buyer'
  const isVendor = () => currentUserRealTime?.role === 'vendor'

  const mySub = () => currentUserRealTime?.role?.charAt(0).toUpperCase() + currentUserRealTime?.role?.slice(1)

  useEffect(() => {
    if(currentUser){
      const docRef = doc(db, "users", currentUser.uid)
      const subCollectionRef = collection(docRef, "notifications")
      
      const unsubscribe = onSnapshot(subCollectionRef, (snapshot) => {
        const data = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
        setNotifications(data.sort((a, b) => {
          const momentA = moment(a.createdAt, 'YYYY-MM-DD hh:mm:ss a');
          const momentB = moment(b.createdAt, 'YYYY-MM-DD hh:mm:ss a');
        
          return momentB.utc().valueOf() - momentA.utc().valueOf();
        }))
      })
  
      return () => unsubscribe()
    }  
  }, [currentUser])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setLoading(true)
      if(user){
        getDoc(doc(db, "users", user.uid)).then(async response => {
          setCurrentUser({
            ...user,
            ...response.data(),
          })
        }).then(() => setLoading(false))
      }else{
        setCurrentUser(user)
        setLoading(false)
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if(currentUser){
      const unsubscribe = onSnapshot(doc(db, 'users', currentUser?.uid), (snapshot) => {
        setCurrentUserRealTime({...snapshot.data(), uid: snapshot.id});
      })

      return () => unsubscribe()
    }
    
  }, [currentUser]);

  const value = {
    currentUser,
    currentUserRealTime,
    notifications,
    isConnoisseur,
    isCurious,
    isVendor,
    signup,
    login,
    logout,
    loading,
    mySub,
    forgotPassword,
    changePassword
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
