import React from "react";
import DefaultLayout from "../components/DefaultLayout";

const Help = () => {

  return (
    <DefaultLayout>
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 pb-5 mb-5 d-flex justify-content-center">
          Please email us at info@romulus.art
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Help;
