import DefaultLayout from "../components/DefaultLayout";
import React, { useEffect, useState } from "react";
import PriceCard from "../components/cards/PriceCard";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { db } from "../firebaseConfig";
import {
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  where,
  query,
  onSnapshot,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

const Plans = () => {

  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState([]);
  const [prices, setPrices] = useState([]);
  const dispatch = useDispatch();

  const { currentUserRealTime: currentUser } = useAuth()

  const [subscription, setSubscription] = useState(null);
  const [subscribed, setSubscribed] = useState(false);

  const startDataListeners = async () => {
    setCount(count + 1);
    
    dispatch({ type: "showLoading" });

    const productsQuery = query(
      collection(db, "products"),
      where("active", "==", true)
    );
    const productsTemp = [];

    const pricesTemp = [];
    const querySnapshot = await getDocs(productsQuery);
    querySnapshot.forEach((product) => {
      productsTemp.push({ ...product.data(), id: product.id });
    });
    setData(productsTemp);
    productsTemp.forEach(async (item) => {

      const docRef = doc(db, "products", item.id);
      const docSnap = await getDoc(docRef);

      const pricesQuery = query(collection(docSnap.ref, "prices"));
      const priceSnap = await getDocs(pricesQuery);

      priceSnap.forEach((price) => {
        pricesTemp.push({ ...price.data(), id: price.id });
      });
      setPrices(pricesTemp);
    });

  };

  const connectStripe = async (docRef) => {

    const checkRef = doc(db, "users", currentUser?.uid, "checkout_sessions", docRef.id);

    onSnapshot(checkRef, async (snap) => {
      const { sessionId } = snap.data();
      if (sessionId) {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
        stripe.redirectToCheckout({ sessionId });
      }
    });
  };

  const handleClick = async (e, price) => {
    e.preventDefault();

    if (subscribed) {
      navigate("/dashboard");
      return;
    }

    addDoc(collection(db, "users", currentUser?.uid, "checkout_sessions"), {
      price: price.id,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      allow_promotion_codes: true,
    })
      .then((docRef) => {
        console.log("docRef object from addDoc: ", docRef);
        console.log("docRef returned from addDoc: ", docRef.id);
        toast.success("Checking out with Stripe, redirecting now!");
        dispatch({ type: "hideLoading" });
        connectStripe(docRef);
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
        toast.error("Something went wrong");
      });
  };

  const checkSubs = async () => {
    const subscriptionsTemp = [];
    const subsQuery = query(
      collection(db, "users", currentUser?.uid, "subscriptions"),
      where("status", "in", ["trialing", "active"])
    );

    const subsSnapshot = await getDocs(subsQuery);

    if (subsSnapshot) {
      subsSnapshot.forEach((subscription) => {
        subscriptionsTemp.push({ ...subscription.data(), id: subscription.id });
      });
    }

    if (subscriptionsTemp.length === 0) {
      setSubscribed(false);
    } else if (subscriptionsTemp.length === 1) {
      setSubscription(subscriptionsTemp[0]);
      setSubscribed(true);
    }

    dispatch({ type: "hideLoading" });
  };

  useEffect(() => {
    if (flag && count === 1) {
      startDataListeners();
    }
  }, [flag]);

  useEffect(() => {
    if (!flag) {
      startDataListeners().then(setFlag(true));
    }
  }, [currentUser]);

  useEffect(() => {
    checkSubs();
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {" "}
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        <div className="col-12 col-lg-10 col-xl-9 pb-5 mb-5">
          <div className="pb-5">
            {" "}
            <Card className="mb-5">
              <span className="text-center">
                <Card.Header as="h4">Choose a plan</Card.Header>
              </span>
              <Card.Body>
                <div className="row pt-4 pb-3 text-center d-flex justify-content-center">
                  {prices &&
                    count === 2 &&
                    prices.map((price) => {
                      return (
                        <PriceCard
                          key={price.id}
                          price={price}
                          handleSubscription={handleClick}
                          subscribed={subscribed}
                          subscription={subscription}
                        />
                      );
                    })}
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Plans;
