/**
 * @typedef {import("./types").NavBarDropDownItemProps}  NavBarDropDownItemProps
 */

import React from "react";
import { Badge } from "react-bootstrap";
import { useLocation } from "react-router-dom";

/**
 * @param {object} props
 * @param {NavBarDropDownItemProps} props.item
 */
export const NavBarDropDownItem = ({ item }) => {
  const location = useLocation();
  const isOnSelectedLocation = item.path === location.pathname;

  return (
    <li
      key={item.path}
      onClick={() => item.onClick?.()}
      className="hover:bg-gray-100"
    >
      <a
        href={item.path}
        className={`text-black text-base block px-1 lg:p-4 py-1.5 lg:py-1 ${
          isOnSelectedLocation ? "underline" : ""
        } ${item?.extraClass ?? ""}`}
      >
        {item.title}
        {(item?.count || 0) > 0 && (
          <Badge pill bg="secondary" className="ml-2">
            {item.count}
          </Badge>
        )}
      </a>
    </li>
  );
};
