import { MDBBadge } from "mdb-react-ui-kit";
import AcqusitionsStatusTable from "./AcquisitionsStatusTable";
import OrderGrid from "./OrderGrid";
import { useAuth } from "../context/AuthContext";


function Acquisitions({ items, getItems, setOrders, display }) {

  const { isCurious, isConnoisseur } = useAuth()

  const filterOrders = order => {
    if(isConnoisseur()) return !order.archivedByConnoisseur
    if(isCurious()) return !order.archivedByCurious
    return true
  }

  return (
    <div className="d-flex justify-content-center row text-center">
      {display === 'card' && 
        <div className="row text-center mt-4">
          {items?.filter(filterOrders).map((item) => {
            return (
              <div
                key={item.id}
                className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4"
              >
                <div className="d-inline-flex position-relative" style={{width: '100%'}}>
                  <OrderGrid order={item} getOrders={getItems} setOrders={setOrders}/>
                  <div>
                    <MDBBadge
                      pill
                      color={item.statusColor}
                      className="position-absolute top-0 my-4 mx-3 end-0 p-2 border-dark shadow-sm text-dark"
                    >
                      <span className="visually-hidden">
                        Status Color
                      </span>
                    </MDBBadge>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      }
      {display === 'table' && <AcqusitionsStatusTable orders={items?.filter(filterOrders)}/>}
    </div>
  );
}

export default Acquisitions;
