import { useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { IconElement } from "components/atoms/IconElement";
import React, { useRef, useImperativeHandle, forwardRef } from "react";

/**
 * @typedef {Object} ShareUser
 * @property {string} label - The name of the user, or the email if not registered
 * @property {string} value - The email of the user (or new email to share with)
 * @property {string|null} [id] - Id of user (when registered)
 * @property {string} [sub] - User sub/role (when registered)
 * @property {boolean} [isFav] - Whether the user is favorite (when registered)
 * @property {boolean} [__isNew__] - Whether the user is new (not registered)
 */

/**
 *
 * @param {Object} params
 * @param {object} params.shareUsersList
 * @param {object} [params.network]
 * @param {string} params.currentUserId
 * @param {string[]} params.alreadySharedUsersIds
 * @param {function} params.onChange - The function to call when the value changes. Returns an array of shareUser objects
 * @param {React.Ref} ref - Ref passed from the parent to control the child component
 */
export const SelectShareUser = forwardRef(
  (
    { shareUsersList, alreadySharedUsersIds, network, currentUserId, onChange },
    ref
  ) => {
    const selectInputRef = useRef();

    const MultiValueRemove = (props) => {
      return (
        <components.MultiValueRemove {...props}>
          <IconElement type="x" />
        </components.MultiValueRemove>
      );
    };
    useImperativeHandle(ref, () => ({
      clearSelected,
    }));

    const clearSelected = () => {
      selectInputRef.current.clearValue();
    };

    const options = useMemo(() => {
      const isAlreadySharedWithUser = (userId) => {
        return alreadySharedUsersIds?.includes(userId);
      };

      const isUserFavorite = (userId) => {
        if (!network) return false;
        return network.some((user) => user.id === userId && user.isFav);
      };

      let options = shareUsersList
        .filter((user) => !isAlreadySharedWithUser(user.id))
        .map((user) => {
          return {
            value: user.email,
            label: currentUserId === user.id ? user.name + " (You)" : user.name,
            id: user.id,
            isFav: isUserFavorite(user.id),
            sub: user.sub,
          };
        })
        .sort((a, b) => b.isFav - a.isFav);

      return options;
    }, [shareUsersList, alreadySharedUsersIds, network, currentUserId]);

    return (
      <CreatableSelect
        id="creatable-select-share-user"
        className="w-full"
        isClearable={false}
        isMulti
        placeholder="Add people from your network or an external email address"
        options={options}
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,
            primary: "#262626",
          },
        })}
        components={{
          MultiValueRemove,
          ClearIndicator: () => null,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        ref={selectInputRef}
        onChange={onChange}
      />
    );
  }
);
