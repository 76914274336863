import React, { useState, useCallback, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

function CollectionClientItem({ item }) {
  const [lgShow, setLgShow] = useState(false);
  const imgRef = useRef();

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  return (
    <>
      <div className="h-full">
        {item && (
          <>
            <img
              src={item.imageURL}
              className="w-full cursor-pointer h-full object-cover rounded-xl shadow-md"
              layout="fill"
              onClick={() => setLgShow(true)}
              alt={
                "Artwork by " + item.artistFirstName + " " + item.artistLastName
              }
            />
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  <span className="text-secondary">
                    {item.artistFirstName} {item.artistLastName}
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <div className="d-flex justify-content-center align-items-center">
                  {" "}
                  <QuickPinchZoom
                    onUpdate={onUpdate}
                    doubleTapToggleZoom={true}
                    doubleTapZoomOutOnMaxScale={true}
                    draggableUnZoomed
                  >
                    <img
                      ref={imgRef}
                      alt="img"
                      src={item.imageURL}
                      className="img-fluid rounded"
                      style={{
                        cursor: "pointer",
                        maxHeight: "80vh",
                        width: "100",
                      }}
                    />
                  </QuickPinchZoom>
                </div>
                <div className="text-center p-2 pb-2 pt-2 mt-2 rounded border-muted">
                  <div
                    style={{
                      fontWeight: "300",
                      fontSize: 16,
                      fontStyle: "italic",
                    }}
                  >
                    {/* {item.createdOn.split(" ")[0]} */}
                    {item.artworkTitle}
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontWeight: "300",
                      fontSize: 14,
                      // fontStyle: "italic",
                    }}
                  >
                    {/* {item.createdOn.split(" ")[0]} */}
                    {item.artworkDate}
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontWeight: "300",
                      fontSize: 14,
                      // fontStyle: "italic",
                    }}
                  >
                    {/* {item.createdOn.split(" ")[0]} */}
                    {item.artworkMedium}
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontWeight: "300",
                      fontSize: 14,
                      // fontStyle: "italic",
                    }}
                  >
                    {/* {item.createdOn.split(" ")[0]} */}
                    {item.artworkDims}
                  </div>
                  <div
                    className="mt-1"
                    style={{
                      fontWeight: "300",
                      fontSize: 14,
                      // fontStyle: "italic",
                    }}
                  >
                    {/* {item.createdOn.split(" ")[0]} */}
                    {item.inventoryNo}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
      </div>
    </>
  );
}

export default CollectionClientItem;
