import {
  Check,
  CircleDollarSign,
  Hand,
  Heart,
  MessageCircle,
  Star,
  X,
} from "lucide-react";

/**
 * @param {object} props
 * @param {"heart"|"money"|"hand"|"dialog"|"x"|"star"|"check"} props.type
 * @param {string} [props.className]
 * @param {string} [props.strokeWidth]
 * @param {number} [props.size]
 */
export const IconElement = ({
  type,
  className,
  size = 17,
  strokeWidth = "1.5",
}) => {
  switch (type) {
    case "dialog":
      return (
        <MessageCircle
          className={className}
          strokeWidth={strokeWidth}
          size={size}
        ></MessageCircle>
      );
    case "money":
      return (
        <CircleDollarSign
          className={className}
          strokeWidth={strokeWidth}
          size={size}
        ></CircleDollarSign>
      );
    case "hand":
      return (
        <Hand
          className={className}
          strokeWidth={strokeWidth}
          size={size}
        ></Hand>
      );
    case "heart":
      return (
        <Heart
          className={className}
          strokeWidth={strokeWidth}
          size={size}
        ></Heart>
      );
    case "x":
      return <X className={className} strokeWidth={strokeWidth} size={size} />;
    case "star":
      return (
        <Star className={className} strokeWidth={strokeWidth} size={size} />
      );
    case "check":
      return (
        <Check className={className} strokeWidth={strokeWidth} size={size} />
      );

    default:
      return (
        <Heart
          className={className}
          strokeWidth={strokeWidth}
          size={size}
        ></Heart>
      );
  }
};
