import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Highlight } from "react-instantsearch";

export const IncomingPreviewHit = ({ hit }) => {
  const navigate = useNavigate();

  return (
    <article
      style={{ display: "flex", cursor: "pointer", width: "100%" }}
      onClick={() => navigate(`/post-s-grid/${hit.objectID}`)}
    >
      <Image
        fluid
        src={hit?.imageURL}
        style={{
          height: "100px",
          objectFit: "cover",
          width: "100px",
          minWidth: "100px",
        }}
      />
      <div
        style={{ marginLeft: "20px", display: "flex", flexDirection: "column" }}
      >
        <span style={{ fontWeight: "bold" }}>
          <Highlight attribute="description" hit={hit} />
        </span>
        <span>
          <span style={{ fontWeight: "bold" }}>Shared By: </span>
          <Highlight attribute="userDisplayName" hit={hit} />
        </span>
      </div>
    </article>
  );
};
