import React from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { makeNotificationSeen } from "../utils/notification";
import moment from "moment";

function Notification({ notification }) {
  const { currentUser, isConnoisseur } = useAuth();
  const navigate = useNavigate();

  const types = {
    sharePost: {
      title: "New Post Shared",
      description: "shared a new artwork with you",
      redirect: `/post-s-grid/${notification.postId}`,
    },
    shareOrder: {
      title: "Order Shared",
      description: "shared a acquisition with you",
      redirect: `/order-s-status/${notification.orderId}`,
    },
    newComment: {
      title: "New Comment",
      description: "comment on your post",
      redirect: `/post-o-page/${notification.postId}#${notification?.pageNumber}`,
    },
    replyComment: {
      title: "New Reply",
      description: "reply to your comment",
      redirect: `/post-${isConnoisseur() ? "o" : "s"}-page/${
        notification.postId
      }#${notification?.pageNumber}`,
    },
    orderCreated: {
      title: "New Acquisition Inquiry",
      description: "wants to purchase an artwork",
      redirect: `/order-o-status/${notification.orderId}`,
    },
    orderUpdated: {
      title: "Order Status Updated",
      description: "updated your order status",
      redirect: `/order-s-status/${notification.orderId}`,
    },
    connectionInvite: {
      title: "New Connection",
      description: "added you to network",
      redirect: `/connections/`,
    },
    connectionResponse: {
      title: "New Invite Reply",
      description: "replied you invitation",
      redirect: `/connections/`,
    },
  };

  const handleNotificationClick = async (notification) => {
    await makeNotificationSeen(currentUser, notification?.id);
    navigate(types[notification?.type]?.redirect, { replace: true });
  };

  const handleTimeDifference = (createdAt) => {
    const now = moment();
    const specificTime = moment(createdAt);

    const difference = now.diff(specificTime, "seconds");

    const years = Math.floor(difference / (365 * 24 * 60 * 60));
    const days = Math.floor(
      (difference % (365 * 24 * 60 * 60)) / (24 * 60 * 60)
    );
    const hours = Math.floor((difference % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((difference % (60 * 1000)) / 1000);

    let timeDiff;
    if (years > 0) {
      timeDiff = `${years}y`;
    } else if (days > 0) {
      timeDiff = `${days}d`;
    } else if (hours > 0) {
      timeDiff = `${hours}h`;
    } else if (minutes > 0) {
      timeDiff = `${minutes}m`;
    } else {
      timeDiff = `${seconds}s`;
    }

    return timeDiff;
  };

  return (
    <div className="flex justify-between w-full py-2 px-4 hover:bg-gray-100">
      <div
        className="w-[90%]"
        onClick={() => handleNotificationClick(notification)}
      >
        <p className="text-base">
          <strong>{types[notification?.type]?.title}</strong>
        </p>
        <p className="text-sm m-0 whitespace-nowrap overflow-hidden text-ellipsis">
          <strong>{notification.fromDisplayName}</strong>{" "}
          {types[notification?.type]?.description}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "end",
          width: "20px",
        }}
      >
        {!notification?.seen ? (
          <div
            style={{
              backgroundColor: "blue",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
            }}
          />
        ) : (
          <div />
        )}
        <p style={{ fontSize: "12px", margin: 0, color: "#65676B" }}>
          {handleTimeDifference(notification.createdAt)}
        </p>
      </div>
    </div>
  );
}

export default Notification;
