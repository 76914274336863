import React, { useState, useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { collection, doc, getDoc, getDocs, where, query } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PostArchivedShares from "../components/PostArchivedShares";
import Card from "react-bootstrap/Card";
import { useAuth } from '../context/AuthContext'
import moment from "moment";
import { IoCaretBackSharp } from "react-icons/io5";


function ArchivedShares() {

  const [incomingPosts, setIncomingPosts] = useState([])
  const [outgoingPosts, setOutgoingPosts] = useState([])
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { currentUser } = useAuth()

  const getOutgoingPosts = async () => {
    const querySnapshot = await getDocs(collection(db, "posts"));

    const temp = [];
    querySnapshot.forEach((doc) => {
      temp.push({ ...doc.data(), id: doc.id });
    });
    const filteredPosts = temp
      .filter((post) => post.userId === currentUser?.uid)
      .filter(post => post.archived)
      setOutgoingPosts(
      filteredPosts
        .map(obj => ({...obj, cameFrom: 'outgoing'}))
        .sort((a, b) => {
          const momentA = moment(a.createdOn, 'YYYY-MM-DD hh:mm:ss a');
          const momentB = moment(b.createdOn, 'YYYY-MM-DD hh:mm:ss a');
        
          return momentB.utc().valueOf() - momentA.utc().valueOf();
      })
    );

    dispatch({ type: "hideLoading" });
  };

  const getIncomingPosts = async () => {
    const response = await getDocs(
      query(
        collection(db, 'sharedPosts'),
        where('sharedWith', '==', currentUser?.uid),
        where('isArchived', '==', true)
      )
    )
    const shares = response.docs.map(doc => ({...doc.data(), id: doc.id}))

    const sharedPosts = await Promise.all(
      shares.map(async sh => {
        const postDoc = await getDoc(doc(db, 'posts', sh.post))
        const user = await getDoc(doc(db, 'users', sh.sharedBy))
        return {...sh, post: postDoc.data(), sharedBy: user.data(), cameFrom: 'incoming', sharedPostId: sh.id}
      })
    )
    
    setIncomingPosts(
      sharedPosts
        .sort((a, b) => {
          const momentA = moment(a.createdAt, 'YYYY-MM-DD hh:mm:ss a');
          const momentB = moment(b.createdAt, 'YYYY-MM-DD hh:mm:ss a');
    
          return momentB.utc().valueOf() - momentA.utc().valueOf();
        })
    )
    dispatch({ type: "hideLoading" });
  };


  useEffect(() => {
    getIncomingPosts()
    getOutgoingPosts()
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {currentUser && (
        <>
          <Card>
            <Card.Header as="h5">
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                  <div style={{position: 'absolute', left: 0}}>
                    <IoCaretBackSharp
                      size={17}
                      color="dark"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(-1)}
                    />
                  </div>
                  Archived
                </div>
              </Card.Header>
            <Card.Body>
              <span className="text-center">
                <Card.Title as="h6">
                  <span className="text-muted">capsules shared with you</span>
                </Card.Title>
              </span>
              <div className="d-flex justify-content-center row text-center">
                <div className="row text-center mt-4 mb-5">
                  {[...incomingPosts.map(obj => ({
                    ...obj.post,
                    cameFrom: obj.cameFrom,
                    sharedBy: obj.sharedBy.username,
                    sharedPostId: obj.sharedPostId
                  })), ...outgoingPosts].map((post) => {

                    return (
                      <div
                        key={post.mapKey}
                        className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4"
                      >
                        <div className="d-flex p-2 rounded border-muted card shadow-sm">
                          <span className="p-1 pt-2 rounded shadow-sm bg-light">
                            <h6
                              style={{
                                fontWeight: "400",
                                fontSize: 16,
                                fontStyle: "italic",
                              }}
                            >
                              {post.cameFrom === 'incoming' ? `Shared with you by @${post.sharedBy}` : 'Your upload'}
                            </h6>{" "}
                          </span>
                        </div>

                        <div style={{width: '100%'}}>
                          <PostArchivedShares
                            post={post}
                            getOutgoingPosts={getOutgoingPosts}
                            getIncomingPosts={getIncomingPosts}
                            isOwner={post.cameFrom === 'outgoing'}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </DefaultLayout>
  );
}

export default ArchivedShares;
