import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { FaComment, FaHandPaper } from "react-icons/fa";
import {
  AiFillHeart,
  AiFillDollarCircle,
  AiOutlineDelete,
} from "react-icons/ai";
import { GrRevert } from "react-icons/gr";
import { MDBBadge } from "mdb-react-ui-kit";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import Image from 'react-bootstrap/Image';
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useAuth } from "../context/AuthContext";

function PostArchivedShares({ post, getOutgoingPosts, getIncomingPosts, isOwner }) {

  const revertRef = useRef(false);
  const deleteRef = useRef(false);
  const [postRefresh, setPostRefresh] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((store) => store);
  const [alreadyBuy, setAlreadyBuy] = useState(false);
  const [alreadyUrgent, setAlreadyUrgent] = useState(false);
  const [alreadyLiked, setAlreadyLiked] = useState(false);
  const [alreadyComment, setAlreadyComment] = useState(false);
  const [alreadyOnHold, setAlreadyOnHold] = useState(false)
  const [showFeedbackBar, setShowFeedbackBar] = useState(false);
  const [alreadyPhoned, setAlreadyPhoned] = useState(false);
  const [alreadyShareRequested, setAlreadyShareRequested] = useState(false);
  const [alreadyArchived, setAlreadyArchived] = useState(false);
  const [phoneData, setPhoneData] = useState();
  const [shareRequestData, setShareRequestData] = useState();

  const { currentUserRealTime: currentUser } = useAuth()

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const buyCount = (post, userID) => {
    function checkBuy(buy) {
      return buy.id === userID;
    }
    const filteredBuys = post.buys?.filter(checkBuy);
    return filteredBuys.length;
  };

  const urgentCount = (post, userID) => {
    function checkUrgent(urgent) {
      return urgent.id === userID;
    }
    const filteredUrgents = post.urgents.filter(checkUrgent);
    return filteredUrgents.length;
  };

  const likeCount = (post, userID) => {
    function checkLike(like) {
      return like.id === userID;
    }
    const filteredLikes = post.likes?.filter(checkLike);
    return filteredLikes.length;
  };

  const flagCount = (post) => {
    function checkFlag(flag) {
      return flag;
    }
    const filteredFlags = post.flags?.filter(checkFlag);
    return filteredFlags.length;
  };

  const commentCount = (post, userID) => {
    function checkComment(comment) {
      return comment.id === userID;
    }
    const filteredComments = post.comments?.filter(checkComment);
    return filteredComments.length;
  };

  const onHoldCount = (post) => {
    function checkOnHold(hold) {
      return hold.id === currentUser?.uid && !isOwner;
    }
    const filteredLikes = post?.onHold?.filter(checkOnHold) || []
    return filteredLikes.length;
  };

  const getData = () => {
    let postId = post.id;

    const filteredPhones = [];
    const filteredShareRequests = [];

    getDoc(doc(db, "posts", postId))
      .then((response) => {
        setPostRefresh({ ...response.data(), id: response.id });
        const temp = response.data();

        if (temp.hasOwnProperty("phones")) {
          dispatch({ type: "showLoading" });
          if (temp.phones?.length > 0) {
            for (let i = 0; i < temp.phones?.length; i++) {
              if (temp.phones[i].id === currentUser.uid) {
                filteredPhones.push(temp.phones[i]);
                setPhoneData(temp.phones[i]);
              }
            }
          }
        }
        if (filteredPhones.find((user) => user.id === currentUser.uid)) {
          setAlreadyPhoned(true);
        } else {
          setAlreadyPhoned(false);
        }

        if (temp.hasOwnProperty("shareRequests")) {
          dispatch({ type: "showLoading" });
          if (temp.shareRequests?.length > 0) {
            for (let i = 0; i < temp.shareRequests?.length; i++) {
              if (temp.shareRequests[i].id === currentUser.uid) {
                filteredShareRequests.push(temp.shareRequests[i]);
                setShareRequestData(temp.shareRequests[i]);
              }
            }
          }
        }
        if (
          filteredShareRequests.find((user) => user.id === currentUser.uid)
        ) {
          setAlreadyShareRequested(true);
        } else {
          setAlreadyShareRequested(false);
        }

        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
      });
  };

  const getPosts = () => {
    getIncomingPosts()
    getOutgoingPosts()
  }

  const deletePost = async () => {
    dispatch({ type: "showLoading" });
    deleteRef.current = true;
    deleteDoc(doc(db, 'posts', post.id)).then(() => {
      deleteRef.current = false
      dispatch({ type: "hideLoading" })
      getPosts()
    }).catch(err => {
      console.log(err)
      deleteRef.current = false
      dispatch({ type: "hideLoading" })
    })
  };

  const revertIncomingPost = async () => {
    dispatch({ type: "showLoading" });

    revertRef.current = true;
    updateDoc(doc(db, 'sharedPosts', post.sharedPostId), {
      isArchived: false
    }).then(() => {
      revertRef.current = false;
      dispatch({ type: "hideLoading" });
      getPosts()
    })
  };

  const revertOutgoingPost = async () => {
    dispatch({ type: "showLoading" });

    revertRef.current = true;

    updateDoc(doc(db, "posts", post.id), {
      archived: false
    }).then(() => {
      revertRef.current = false;
      dispatch({ type: "hideLoading" });
      getPosts();
    });
  };

  useEffect(() => {
    if (
      alreadyBuy === true ||
      alreadyLiked === true ||
      alreadyComment === true ||
      alreadyOnHold === true
    ) {
      setShowFeedbackBar(true);
    }
  }, [alreadyBuy || alreadyLiked || alreadyComment || alreadyOnHold]);

  useEffect(() => {
    if (post && currentUser) {
      getData();
      setAlreadyUrgent(isOwner ? post.urgents?.length > 0 : urgentCount(post, currentUser?.uid) > 0)
      setAlreadyLiked(isOwner ? post.likes?.length > 0 : likeCount(post, currentUser?.uid) > 0)
      setAlreadyBuy(isOwner ? post.buys?.length > 0 : buyCount(post, currentUser?.uid) > 0)
      setAlreadyComment(isOwner ? post.comments?.length > 0 : commentCount(post, currentUser?.uid) > 0)
      setAlreadyOnHold(isOwner ? post?.onHold?.length > 0 : onHoldCount(post, currentUser?.uid) > 0)
    }
  }, [currentUser, post]);

  return (
    <div className="card border-muted text-center shadow-sm" style={{width: '100%'}}>
      {post && currentUser && (
        <>
          <div className="p-1">
            <h6 className="bg-light p-2 rounded border-muted shadow-sm">
              <span className="font-weight-bold">{post.description}</span>
            </h6>
            <Image
              fluid
              src={post.imageURL}
              style={{height: '300px', objectFit: 'cover', width: '100%', cursor: 'pointer'}}
              onClick={() => isOwner
                ? navigate(`/post-o-grid/${post.id}`)
                : navigate(`/post-s-grid/${post.id}`)
              }
            />
            <div className="row p-1">
              <div className="pt-0">
                {showFeedbackBar && (
                  <div style={{width: '100%', marginTop: '5px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-around'}} className={`bg-white shadow-sm rounded p-1 ${
                      alreadyUrgent === true ? "border border-warning" : ""
                    }`}>
                      {post.urgents.map((urgent) => {
                        if (urgent.id === currentUser.uid)
                          return <div key={urgent.mapKey}></div>;
                      })}
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <AiFillHeart
                          size={28}
                          color={alreadyLiked ? "red" : "lightGray"}
                        />
                        {alreadyLiked && (
                          <div>
                            <MDBBadge
                              pill
                              color="white"
                              className="text-dark"
                              style={{
                                border: "1px solid #C0C0C0",
                              }}
                            >
                              {isOwner ? post.likes?.length : likeCount(post, currentUser.uid)}
                              <span className="visually-hidden">
                                New alerts
                              </span>
                            </MDBBadge>
                          </div>
                        )}
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <FaHandPaper
                          size={28}
                          color={alreadyOnHold ? "#FFAB01" : "lightGray"}
                        />
                        {alreadyOnHold && (
                          <div>
                            <MDBBadge
                              pill
                              color="white"
                              className="text-dark"
                              style={{
                                border: "1px solid #C0C0C0",
                              }}
                            >
                              {isOwner ? post?.onHold?.length: onHoldCount(post, currentUser.uid)}
                              <span className="visually-hidden">
                                New alerts
                              </span>
                            </MDBBadge>
                          </div>
                        )}
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <AiFillDollarCircle
                          size={28}
                          color={alreadyBuy ? "blue" : "lightGray"}
                        />
                        {alreadyBuy && (
                          <div>
                            <MDBBadge
                              pill
                              color={
                                alreadyUrgent && post.buys?.length === 1
                                  ? "warning"
                                  : "white"
                              }
                              className="text-dark"
                              style={{
                                border: `1px solid ${
                                  alreadyUrgent && post.buys?.length > 1
                                    ? "#FFC107 "
                                    : "#C0C0C0 "
                                }`,
                              }}
                            >
                              {isOwner ? post.buys?.length : buyCount(post, currentUser.uid)}
                              <span className="visually-hidden">
                                New alerts
                              </span>
                            </MDBBadge>
                          </div>
                        )}
                      </div>
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <FaComment
                          size={28}
                          color={alreadyComment ? "pink" : "lightGray"}
                        />
                        {alreadyComment && (
                          <div>
                            <MDBBadge
                              pill
                              color="white"
                              className="text-dark"
                              style={{
                                border: "1px solid #C0C0C0",
                              }}
                            >
                              {isOwner ? post.comments?.length : commentCount(post, currentUser.uid)}
                              <span className="visually-hidden">
                                New alerts
                              </span>
                            </MDBBadge>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-2 mb-1">
                <BootstrapTooltip title="Undo archive">
                  <MDBBadge
                    pill
                    color="light"
                    className="p-2 me-4 ms-4 bg-light text-dark border-dark shadow-sm"
                  >
                    {" "}
                    {loading && revertRef.current ? (
                      <Loader />
                    ) : (
                      <GrRevert
                        size={20}
                        color={"black"}
                        onClick={isOwner ? revertOutgoingPost : revertIncomingPost}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </MDBBadge>
                </BootstrapTooltip>

                <BootstrapTooltip title="Delete archive">
                  <MDBBadge
                    pill
                    color="light"
                    className="p-2 ms-4 me-4 bg-light text-dark border-dark shadow-sm"
                  >
                    {loading && deleteRef.current ? (
                      <Loader />
                    ) : (
                      <AiOutlineDelete
                        ref={deleteRef}
                        size={20}
                        color={"black"}
                        onClick={deletePost}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </MDBBadge>
                </BootstrapTooltip>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PostArchivedShares;
