import React, { useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";


const Calendar = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {" "}
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5 rounded border bg-white border-light p-2">
        <div className="d-flex justify-content-center mb-5 w-100">
          {" "}
          <iframe
            src={process.env.REACT_APP_GOOGLE_CALENDAR_SRC}
            style={{ border: 0 }}
            width="800"
            height="600"
            frameborder="0"
            className="col-12 col-sm-11 col-md-10 col-lg-10 col-xl-8 pb-5 mb-5"
            //scrolling="no"
          ></iframe>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Calendar;
