// Author: Ciaran O'Keeffe
// Copyright © 2023 Ciaran O'Keeffe All Rights Reserved

import React, { useState, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import DefaultLayout from "../components/DefaultLayout";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
// import axios from "axios";
// import { UserContext } from "../context";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
// import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const Account = () => {
  // console.log(
  //   "Code author: Ciaran O'Keeffe, Copyright © 2023 Ciaran OKeeffe All Rights Reserved"
  // );

  const navigate = useNavigate();
  // const [state, setState] = useContext(UserContext);
  const [subscriptions, setSubscriptions] = useState([]);
  //let checked = false;
  const [userID, setUserID] = useState();
  // const auth = getAuth();

  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     // User is signed in, see docs for a list of available properties
  //     // https://firebase.google.com/docs/reference/js/firebase.User
  //     const uid = user.uid;
  //     setUserID(uid);
  //     //setUserID(uid);
  //     //console.log("user.uid: ", uid);
  //     //console.log("auth.currentuser: ", auth.currentUser);
  //     // ...
  //   } else {
  //     // User is signed out
  //     // ...
  //     // console.log(
  //     //   "inside of else block to auth onAuthStateChanged function, next lines logout() annd navigate to /login"
  //     // );
  //     // logout();
  //   }
  // });

  // const logout = async () => {
  //   // setState({ user: {}, token: "" });
  //   // localStorage.removeItem("auth");
  //   await signOut(auth)
  //     .then(() => {
  //       console.log("Signed Out");
  //       localStorage.removeItem("romulus_0-user");
  //       setUserID("");
  //       // navigate("/login");
  //     })
  //     .catch((e) => {
  //       console.error("Sign Out Error", e);
  //       // navigate("/login");
  //     });
  //   // navigate("/login");
  // };

  // const checkDisplayName = () => {
  //   const auth = getAuth();
  //   console.log("auth.currentUser: ", auth.currentUser);
  // };

  // useEffect(() => {
  //   checkDisplayName();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {" "}
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6 pb-5 mb-5">
          {" "}
          <div className="pb-5 mb-5">
            {" "}
            <div className="pb-5 mb-4">
              {" "}
              <Card className="mb-5">
                <span className="text-center">
                  <Card.Header as="h5">Account</Card.Header>
                </span>
                <Card.Body>
                  <span className="text-center">
                    <Card.Title as="h6">
                      <span className="text-secondary">
                        manage your subscription
                      </span>
                    </Card.Title>
                  </span>
                  <div className="row text-center pt-3">
                    <UserOutlined className="display-5 mt-3 mb-5" />

                    {/* <p className="lead pb-4 mt-4">Subscription status</p> */}
                    {/* <pre>{JSON.stringify(subscriptions, null, 4)}</pre> */}
                  </div>
                  {/* <Card.Text>
          With supporting text below as a natural lead-in to additional
          content.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Account;
