import { BsPersonPlus } from "react-icons/bs";
import { isEmail } from "utils/text";

export const EmailInput = ({
  value,
  onChange,
  placeholder,
  isEmailValid,
  setIsEmailValid,
  className,
}) => {
  const onInputChange = (e) => {
    onChange(e.target.value);
    const isEmpty = e.target.value === "";
    setIsEmailValid(isEmpty || isEmail(e.target.value));
  };

  return (
    <div className={`relative w-full ${className}`}>
      <input
        type="email"
        class={`pl-10 pr-4 py-2 border w-full font-light ${
          !isEmailValid ? "!border-red-500" : ""
        }`}
        placeholder={placeholder}
        value={value}
        onChange={onInputChange}
      />
      <div
        class="absolute inset-y-0 left-0 pl-3  
                    flex items-center  
                    pointer-events-none"
      >
        <BsPersonPlus size={16} />
      </div>
    </div>
  );
};
