//@ts-check
import React, { useRef, useEffect } from "react";

/**
 *
 */

/**
 * @typedef {Object} RefType
 * @property {Object} current
 * @property {(any) => boolean} current.contains
 */
/**
 * Hook that alerts clicks outside of the passed ref
 * @param {React.MutableRefObject<HTMLDivElement|null>} ref
 * @param {()=>void|Promise<void>} callback
 */
export function useClickOutsideDetector(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}
