// @ts-check
import { Avatar } from "components/atoms/Avatar";
import React from "react";
/**
 * @param {object} props
 * @param {string} props.title 
 * @param {string} props.subtitle 
 * @param {string} props.profilePictureUrl 
 * @param {()=>void} [props.onAvatarClick] 
 * @returns 
 */
export const UserListItem = ({title,subtitle, profilePictureUrl, onAvatarClick}) => {
  return (
    <div className="flex w-full items-center">
      <Avatar onClick={onAvatarClick} src={profilePictureUrl} name={title} />
      <div className="pl-3">
        <p className="font-regular mb-.5">{title}</p>
        <p className="text-tertiary-gray capitalize text-sm">{subtitle}</p>
      </div>
    </div>
  );
};
