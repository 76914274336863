import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Card from "react-bootstrap/Card";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";

import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import AcquisitionChat from "../components/AcquisitionChat";
import { useAuth } from "../context/AuthContext";
import { IoCaretBackSharp } from "react-icons/io5";

function OrderStatusSpage() {

  const { currentUser, isVendor } = useAuth()

  const params = useParams()
  const navigate = useNavigate()
  
  const [order, setOrder] = useState(null);
  const [artDescription, setArtDesription] = useState(null)
  const [vendorInfo, setVendorInfo] = useState(null)

  const theme = createTheme({
    palette: {
      primary: {
        main: grey[700],
      },
    },
  });

  const getData = () => {
    getDoc(doc(db, "orders", params?.id))
      .then((response) => {
        setOrder({ ...response.data(), id: response.id });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getPostInfo = async (id) => {
    const pagesSnapshot = await getDoc(doc(db, 'posts', id, 'pages', order.pageNumber < 10 ? `0${order.pageNumber}` : order.pageNumber.toString())).catch((error) => console.log(error))
    const page = pagesSnapshot.data()
    setArtDesription(page.text)
  }

  const getVendorInfo = () => {
    getDoc(doc(db, "orders", params?.id, 'vendorInfo', currentUser?.uid))
    .then((response) => {
      setVendorInfo({ ...response.data(), id: response.id });
    })
    .catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getData();
  }, [params]);

  useEffect(() => {
    if(order) getPostInfo(order?.postId)
    if(order && isVendor()) getVendorInfo()
  }, [order])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      <div className="container" style={{ height: "100vh" }}>
        {order && (
          <Card>
            <Card.Header as="h5">
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                <div style={{position: 'absolute', left: 0}}>
                  <IoCaretBackSharp
                    size={17}
                    color="dark"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  />
                </div>
                Acquisition {isVendor() ? 'Information' : 'Status'}
              </div>
            </Card.Header>
            <Card.Body className="d-flex justify-content-center">
              <div>
                {!isVendor() && 
                <div className="d-flex justify-content-center flex-wrap py-3">
                  {" "}
                  <ThemeProvider theme={theme}>
                    <Box sx={{ width: "90%" }}>
                      <Stepper
                        activeStep={order?.events?.length - 1}
                        alternativeLabel
                        sx={{
                          maxWidth: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {order?.events?.map((event) => (
                          <Step key={event.mapKey}>
                            <StepLabel>{event.purpose}</StepLabel>
                          </Step>
                        ))}
                        <Step>
                          <StepLabel
                          // style={{
                          //   cursor: "pointer",
                          // }}
                          // onClick={() => {
                          //   updateStatus();
                          // }}
                          >
                            Update Pending
                          </StepLabel>
                        </Step>
                        {/* <Step>
                  <StepLabel>Acquisition Complete</StepLabel>
                </Step> */}
                      </Stepper>
                    </Box>
                  </ThemeProvider>
                </div>}

                <div className="d-flex row w-100 justify-content-center g-0 pb-3">
                  <div className="col-12 col-md-6 d-flex">
                    <div className="card rounded bg-white text-center w-100 d-flex flex-column" style={{height: 'inherit'}}>
                    {!isVendor() &&
                      <>
                        <h6
                          className={`bg-white p-2 pt-3 pb-3 m-2 rounded border border-${order.statusColor} shadow-sm text-dark`}
                          style={{
                            fontWeight: "350",
                            fontSize: 18,
                            letterSpacing: 1,
                            fontStyle: "italic",
                          }}
                        >
                          {order.status}
                        </h6>
                        <h6
                          className="bg-light p-2 pt-3 pb-3 mx-2 mt-0 mb-0 rounded border border-muted shadow-sm text-dark"
                          style={{
                            fontWeight: "300",
                            fontSize: 15,
                            //letterSpacing: 1,
                            //fontStyle: "italic",
                          }}
                        >
                          {order.statusNotes}
                        </h6>
                      </>
                    }

                      <div className="bg-white p-2 pt-3 pb-4 m-2 rounded border border-muted shadow-sm h-100">
                        {isVendor() &&
                          <>
                            <h5
                              className="mb-3"
                              style={{
                                fontWeight: "350",
                                fontSize: 16,
                                // fontStyle: "italic",
                              }}
                            >
                              Vendor Information
                            </h5>
                            <hr />
                            <h6
                              className=""
                              style={{
                                fontWeight: "300",
                                fontSize: 14,
                                // fontStyle: "italic",
                              }}
                            >
                              <span style={{fontWeight: 'bold'}}>Pickup Location: </span>{vendorInfo?.pickupLocation}
                            </h6>
                            <h6
                              className=""
                              style={{
                                fontWeight: "300",
                                fontSize: 14,
                                // fontStyle: "italic",
                              }}
                            >
                              <span style={{fontWeight: 'bold'}}>How To Pack: </span>{vendorInfo?.howToPack}
                            </h6>
                            <h6
                              className=""
                              style={{
                                fontWeight: "300",
                                fontSize: 14,
                                // fontStyle: "italic",
                              }}
                            >
                              <span style={{fontWeight: 'bold'}}>Shipping To: </span>{vendorInfo?.shippingTo}
                            </h6>
                            <h6
                              className=""
                              style={{
                                fontWeight: "300",
                                fontSize: 14,
                                // fontStyle: "italic",
                              }}
                            >
                              <span style={{fontWeight: 'bold'}}>Need Insurance: </span>{vendorInfo?.needInsurance ? 'Yes' : 'No'}
                            </h6>
                            <h6
                              className=""
                              style={{
                                fontWeight: "300",
                                fontSize: 14,
                                // fontStyle: "italic",
                              }}
                            >
                              <span style={{fontWeight: 'bold'}}>Date To Receive: </span>{vendorInfo?.dateToReceive}
                            </h6>
                          </>
                        }
                        <h5
                          className="mb-3"
                          style={{
                            fontWeight: "350",
                            fontSize: 16,
                            marginTop: isVendor() && '30px'
                          }}
                        >
                          Inquiry Summary
                        </h5>
                        <hr />
                        <h6
                          className=""
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            // fontStyle: "italic",
                          }}
                        >
                          {order.postDescription}
                        </h6>
                        <h6
                          className="mb-3"
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            // fontStyle: "italic",
                          }}
                        >
                          Page {order.pageNumber}
                        </h6>
                        <h6
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            // fontStyle: "italic",
                          }}
                        >
                          Your Request Notes:
                          <span
                            className="ps-1"
                            style={{
                              fontStyle: "italic",
                            }}
                          >
                            {" "}
                            {order.orderText}
                          </span>
                        </h6>
                        {order.urgentChecked && (
                          <h6
                            className=""
                            style={{
                              fontWeight: "300",
                              fontSize: 14,
                              // fontStyle: "italic",
                            }}
                          >
                            Marked Urgent
                          </h6>
                        )}
                        <h6
                          className="pt-2"
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            // fontStyle: "italic",
                          }}
                        >
                          Submitted on {order?.createdOn?.split(" ")[0]}
                        </h6>
                        <h5
                          className="mb-3"
                          style={{
                            fontWeight: "350",
                            fontSize: 16,
                            marginTop: '30px'
                            // fontStyle: "italic",
                          }}
                        >
                          Art Details
                        </h5>
                        <hr />
                        <h6
                          className=""
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          {artDescription}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 mt-2 mt-sm-0 d-flex flex-column" style={{marginLeft: '10px'}}>
                    <img
                      className="mb-2 p-2 img-thumbnail w-100"
                      src={order?.imageURL}
                      alt="art"
                    />
                    {!isVendor() && <AcquisitionChat order={order}/>}
                  </div>
                </div>
              </div>
              {/* <Card.Text>
                        With supporting text below as a natural lead-in to additional
                        content.
                      </Card.Text>
                      <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
          </Card>
        )}
      </div>{" "}
    </DefaultLayout>
  );
}

export default OrderStatusSpage;
