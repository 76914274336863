import { forwardRef, useImperativeHandle, useRef } from "react";
import Select, { components } from "react-select";
import { CiSearch } from "react-icons/ci";

export const UsersSelect = forwardRef(({ userList, setSelected }, ref) => {
  const selectInputRef = useRef();

  const Control = ({ children, ...props }) => (
    <components.Control {...props}>
      <CiSearch size={18} className="ml-2" /> {children}
    </components.Control>
  );

  useImperativeHandle(ref, () => ({
    clearSelected,
  }));

  const clearSelected = () => {
    selectInputRef.current.clearValue();
  };

  return (
    <div>
      <Select
        ref={selectInputRef}
        id="users-select"
        options={userList}
        components={{
          Control,
          ClearIndicator: () => null,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        isMulti
        isClearable
        placeholder="Search for username"
        name="users"
        className="basic-multi-select"
        classNamePrefix="select"
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,
            primary: "#262626",
          },
        })}
        onChange={(selectedUsers) => setSelected([...selectedUsers])}
      />
    </div>
  );
});
