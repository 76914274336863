import React, { useState, useEffect, useRef } from "react";
import DefaultLayout from "../components/DefaultLayout";
import {
  query,
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  orderBy,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../firebaseConfig";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import moment from "moment";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

import * as formik from "formik";
import * as yup from "yup";
import CollectionClientItem from "../components/CollectionClientItem";
import { useAuth } from "../context/AuthContext";

import { CustomToggleButton } from "../components/atoms/CustomToggleButton";
import { CustomButton } from "../components/atoms/CustomButton";

function CollectionGridClient() {
  const { currentUser } = useAuth();

  const { Formik } = formik;
  const { loading } = useSelector((store) => store);
  const submitRef = useRef(null);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedScopeOption, setSelectedScopeOption] = useState("Private");

  const [collectionItems, setCollectionItems] = useState([]);

  const clickSubmit = () => {
    submitRef.current?.click();
    dispatch({ type: "showLoading" });
    setTimeout(() => {
      dispatch({ type: "hideLoading" });
    }, 1000);
  };

  const uploadData = {
    artistFirstName: "",
    artistLastName: "",
    artworkDate: "",
    artworkMedium: "",
    artworkDims: "",
    artworkTitle: "",
    inventoryNo: "",
    file: null,
  };

  const schema = yup.object().shape({
    artistFirstName: yup.string().nullable().notRequired(),
    artistLastName: yup.string().required("Required"),
    artworkTitle: yup.string().required("Required"),
    artworkDate: yup.string().required("Required"),
    artworkMedium: yup.string().nullable().notRequired(),
    artworkDims: yup.string().nullable().notRequired(),
    inventoryNo: yup.string().nullable().notRequired(),
    file: yup.mixed().required("Required"),
  });

  const getCollectionItems = async () => {
    let collectionTemp = [];
    const docRef = doc(db, "users", currentUser?.uid);
    const docSnap = await getDoc(docRef);
    const collectionItemsQuery = query(
      collection(docSnap.ref, "collectionItems"),
      orderBy("createdOn", "desc")
    );
    const collectionSnap = await getDocs(collectionItemsQuery);
    collectionSnap.forEach((item) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      collectionTemp.push({ ...item.data(), id: item.id });
    });
    setCollectionItems(collectionTemp);
  };

  const addArtwork = async (details) => {
    //const auth = getAuth();
    // let user = auth.currentUser;
    // upload the image to Storage and then get the imageURL for doc to add in collectionItems user sub-collection
    dispatch({ type: "showLoading" });

    let imageURL = "";
    const image = details.file;

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `/collections/${currentUser?.uid}/${image.name}`
    );
    // setCountOfProgess(10);
    uploadBytes(storageRef, image)
      .then((snapshot) => {
        getDownloadURL(
          ref(storage, `/collections/${currentUser?.uid}/${image.name}`)
        ).then((url) => {
          // setUrl(url);
          imageURL = url;
          // add artwork inventory item data to user collection, collectionItems
          addDoc(collection(db, "users", currentUser?.uid, "collectionItems"), {
            // collector / user uploading artwork item details
            userId: currentUser?.uid,
            userEmail: currentUser.email,
            userDisplayName: currentUser.displayName,
            // artwork details
            artistFirstName: details.artistFirstName,
            artistLastName: details.artistLastName,
            artworkTitle: details.artworkTitle,
            artworkDate: details.artworkDate,
            artworkMedium: details.artworkMedium,
            artworkDims: details.artworkDims,
            inventoryNo: details.inventoryNo,
            // below imageURL is value for file: "" after being uploaded to Firebase Storage
            imageURL: imageURL,
            createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
            // in the event the artwork being added to the user's collectionItems sub-collection is related to a romulus preview shared and sale requested
            postId: "",
            postDesc: "",
            postCreatedById: "",
          })
            .then(() => {
              toast.success("Artwork added successfully");
              dispatch({ type: "hideLoading" });
              getCollectionItems();
              handleClose();
            })
            .catch(() => {
              dispatch({ type: "hideLoading" });
              toast.error("Something went wrong");
            });
        });
      })
      .catch((error) => {
        // console.log(error);
        toast.error("Error uploading");
      });
  };

  useEffect(() => {
    getCollectionItems();
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {currentUser && (
        <>
          <span id="modal">
            <Modal
              show={show}
              onHide={handleClose}
              fullscreen="sm-down"
              size="lg" // backdrop={false}
              centered
              scrollable
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>+ artwork</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <Formik
                  validationSchema={schema}
                  onSubmit={(values, actions) => {
                    setTimeout(() => {
                      // alert(JSON.stringify(values, null, 2));
                      // updateProfile(values);
                      addArtwork(values);
                      actions.setSubmitting(false);
                    }, 1000);
                  }}
                  initialValues={uploadData}
                >
                  {({
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row className="mb-2 mb-sm-4">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik101"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Artist's first name"
                            name="artistFirstName"
                            value={values.artistFirstName}
                            onChange={handleChange}
                            isValid={
                              touched.artistFirstName && !errors.artistFirstName
                            }
                            isInvalid={
                              touched.artistFirstName &&
                              !!errors.artistFirstName
                            }
                          />
                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.artistFirstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik102"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Artist's last name"
                            name="artistLastName"
                            value={values.artistLastName}
                            onChange={handleChange}
                            isValid={
                              touched.artistLastName && !errors.artistLastName
                            }
                            isInvalid={
                              touched.artistLastName && !!errors.artistLastName
                            }
                          />

                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.artistLastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-2 mb-sm-4">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik104"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Artwork title"
                            name="artworkTitle"
                            value={values.artworkTitle}
                            onChange={handleChange}
                            isValid={
                              touched.artworkTitle && !errors.artworkTitle
                            }
                            isInvalid={
                              touched.artworkTitle && !!errors.artworkTitle
                            }
                          />
                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.artworkTitle}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik105"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Artwork date"
                            name="artworkDate"
                            value={values.artworkDate}
                            onChange={handleChange}
                            isValid={touched.artworkDate && !errors.artworkDate}
                            isInvalid={
                              touched.artworkDate && !!errors.artworkDate
                            }
                          />

                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.artworkDate}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-2 mb-sm-4">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik103"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Dimensions</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Artwork dimensions"
                            name="artworkDims"
                            value={values.artworkDims}
                            onChange={handleChange}
                            isValid={touched.artworkDims && !errors.artworkDims}
                            isInvalid={
                              touched.artworkDims && !!errors.artworkDims
                            }
                          />

                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.artworkDims}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik102"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Medium</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Artwork medium"
                            name="artworkMedium"
                            value={values.artworkMedium}
                            onChange={handleChange}
                            isValid={
                              touched.artworkMedium && !errors.artworkMedium
                            }
                            isInvalid={
                              touched.artworkMedium && !!errors.artworkMedium
                            }
                          />

                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.artworkMedium}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-2 mb-sm-4">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik102"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Inventory #</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Inventory number"
                            name="inventoryNo"
                            value={values.inventoryNo}
                            onChange={handleChange}
                            isValid={touched.inventoryNo && !errors.inventoryNo}
                            isInvalid={
                              touched.inventoryNo && !!errors.inventoryNo
                            }
                          />

                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.inventoryNo}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationFormik105"
                          className="position-relative mb-3 mb-sm-2"
                        >
                          <Form.Label>Artwork image</Form.Label>
                          <Form.Control
                            type="file"
                            required
                            name="file"
                            accept="image/*"
                            onChange={(e) => {
                              setFieldValue("file", e.target.files[0]);
                            }}
                            isValid={touched.file && !errors.file}
                            isInvalid={touched.file && !!errors.file}
                          />
                          <Form.Control.Feedback tooltip>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid" tooltip>
                            {errors.file}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Button
                        variant="outline-dark"
                        type="submit"
                        ref={submitRef}
                        className="d-none"
                      >
                        Add item
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="outline-dark"
                  type="button"
                  onClick={clickSubmit}
                >
                  {loading ? <Loader /> : "Add"}
                </Button>
              </Modal.Footer>
            </Modal>
          </span>
          <div>
            <span className="subheading">
              {currentUser.displayName}'s Collection
            </span>
          </div>
          <div className="mt-2">
            <div className="flex justify-between mt-4">
              <CustomToggleButton
                options={["Public", "Private"]}
                selectedOption={selectedScopeOption}
                setSelectedOption={setSelectedScopeOption}
                isDisabled={true}
              />
              <CustomButton onClick={handleShow}>Upload Artwork</CustomButton>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mt-5 gap-10">
            {collectionItems.map((item) => {
              return (
                <div key={item.id} className="col-span-1">
                  {" "}
                  <CollectionClientItem item={item} />
                </div>
              );
            })}
          </div>
        </>
      )}
    </DefaultLayout>
  );
}

export default CollectionGridClient;
