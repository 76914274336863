import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Highlight } from "react-instantsearch";

export const PendingSalesHit = ({ hit }) => {
  const navigate = useNavigate();

  return (
    <article
      style={{ display: "flex", cursor: "pointer", width: "100%" }}
      onClick={() => navigate(`/order-o-status/${hit.objectID}`)}
    >
      <Image
        fluid
        src={hit?.imageURL}
        style={{
          height: "100px",
          objectFit: "cover",
          width: "100px",
          minWidth: "100px",
        }}
      />
      <div
        style={{ marginLeft: "20px", display: "flex", flexDirection: "column" }}
      >
        <Highlight
          attribute="postDescription"
          hit={hit}
          style={{ fontWeight: "bold" }}
        />
        <span>
          <strong>Status: </strong>
          <Highlight attribute="status" hit={hit} />
        </span>
        <span>
          <strong>Requested by: </strong>
          <Highlight attribute="userDisplayName" hit={hit} />
        </span>
        <span>
          <strong>Page: </strong>
          <Highlight attribute="pageNumber" hit={hit} />
        </span>
      </div>
    </article>
  );
};
