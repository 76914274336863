// @ts-check

import { Tabs } from "components/molecules/Tabs";
import React from "react";
import { useMemo, useState } from "react";
import { ConnectionListItem } from "./ConnectionsListItem";
import SearchInput from "components/atoms/inputs/SearchInput";
import { useAuth } from "context/AuthContext";
import { CustomButton } from "components/atoms/CustomButton";

export const YourConnectionsSection = ({
  users,
  type,
  refreshList,
  onFocusInvite,
}) => {
  const tabItems = ["Network", "Request"];
  const { currentUser } = useAuth();

  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  const onTabChange = (tab) => {
    if (tab === "Network") {
      setSelectedTab(tabItems[0]);
    } else {
      setSelectedTab(tabItems[1]);
    }
  };

  return (
    <div className=" flex flex-col">
      <h3 className="!mb-5">Your Connections</h3>
      <Tabs
        className="!mb-5 max-w-96"
        onChange={onTabChange}
        selectedItem={selectedTab}
        items={tabItems}
      />
      {selectedTab == "Network" ? (
        <NetworkList
          users={users}
          onFocusInvite={onFocusInvite}
          type={type}
          refreshList={refreshList}
          currentUser={currentUser}
        ></NetworkList>
      ) : (
        <RequestList
          users={users}
          type={type}
          refreshList={refreshList}
          currentUser={currentUser}
        ></RequestList>
      )}
    </div>
  );
};

const NetworkList = ({
  users,
  onFocusInvite,
  type,
  refreshList,
  currentUser,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const getUserSortingValue = (connection) => {
    if (connection.isFav) return 0;
    if (connection.status === "accepted") return 1;
    return 2;
  };
  const networkUsers = useMemo(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return users
      .filter((user) => {
        if (
          searchQuery &&
          !user?.name?.toLowerCase().includes(lowerCaseQuery)
        ) {
          return false;
        }
        const invitationAccepted = user.status === "accepted";
        const invitationSentByMe = currentUser.uid === user.createdBy;
        return invitationAccepted || invitationSentByMe || user.nonUser;
      })
      .sort((a, b) => getUserSortingValue(a) - getUserSortingValue(b));
  }, [currentUser.uid, searchQuery, users]);

  return (
    <>
      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        className="!mb-10"
      ></SearchInput>
      {(!networkUsers || networkUsers.length === 0) && (
        <div className="d-flex align-items-center justify-content-center flex-col">
          <p className="!mb-5">
            You don’t have anyone on your network
            {searchQuery ? " matching your search" : ""}.{" "}
          </p>
          <CustomButton
            variant="secondary"
            className={"w-full max-w-96"}
            onClick={onFocusInvite}
          >
            Invite Friends
          </CustomButton>
        </div>
      )}
      <div className="max-h-80 overflow-y-scroll">
        {networkUsers?.map((item) => (
          <ConnectionListItem
            key={item.name}
            item={item}
            type={type}
            refreshList={refreshList}
          />
        ))}
      </div>
    </>
  );
};

const RequestList = ({ users, type, refreshList, currentUser }) => {
  const requestUsers = useMemo(() => {
    return users.filter((user) => {
      const invitationAccepted = user.status === "accepted";
      const invitationSentByMe = currentUser.uid === user.createdBy;
      return !invitationAccepted && !invitationSentByMe && !user.nonUser;
    });
  }, [users, currentUser.uid]);
  return (
    <>
      {(!requestUsers || requestUsers.length === 0) && (
        <div className="!mt-10 d-flex align-items-center justify-content-center flex-col">
          <p>You don’t have any requests.</p>
        </div>
      )}
      <div className="max-h-80 overflow-y-scroll">
        {requestUsers?.map((item) => (
          <ConnectionListItem
            key={item.name}
            item={item}
            type={type}
            refreshList={refreshList}
          />
        ))}
      </div>
    </>
  );
};
