import { IconElement } from "components/atoms/IconElement";
import { Badge } from "react-bootstrap";

/**
 * @param {object} props
 * @param {"heart"|"money"|"hand"|"dialog"|"x"|"star"|"check"} props.type
 * @param {number} [props.count]
 * @param {string} [props.className]
 * @param {()=>void} [props.onClick]
 * @param {string} [props.strokeWidth]
 * @param {number} [props.size]
 * @param {boolean} [props.showCount]
 */

export const CircularIcon = ({
  type,
  count,
  className,
  onClick,
  size = 16,
  strokeWidth = "1.5",
}) => {
  return (
    <div
      className={`relative flex h-9 w-9  !mx-auto border rounded-full ${className}`}
      onClick={onClick}
    >
      <IconElement
        type={type}
        size={size}
        strokeWidth={strokeWidth}
        className="w-5 m-auto  cursor-pointer"
      ></IconElement>
      {count > 0 && (
        <Badge
          pill
          bg="red-accent"
          className="absolute -top-2 -right-2 bg-[#A40505] font-normal"
        >
          {count}
        </Badge>
      )}
    </div>
  );
};
