import { useNavigate } from "react-router-dom";
import { Highlight } from "react-instantsearch";
import { FaRegUserCircle } from "react-icons/fa";

export const UserHit = ({ hit }) => {
  const navigate = useNavigate();

  return (
    <article
      style={{
        display: "flex",
        cursor: "pointer",
        width: "100%",
        alignItems: "center",
      }}
      onClick={() => navigate(`/profile/${hit.username}`)}
    >
      <FaRegUserCircle size={50} color="lightGrey" />
      <div
        style={{ marginLeft: "20px", display: "flex", flexDirection: "column" }}
      >
        <Highlight
          attribute="displayName"
          hit={hit}
          style={{ fontWeight: "bold" }}
        />
        <span>
          <strong>username: </strong>
          <Highlight attribute="username" hit={hit} />
        </span>
      </div>
    </article>
  );
};
