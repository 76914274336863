import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { MDBBadge } from "mdb-react-ui-kit";
import Popover from "react-bootstrap/Popover";
import {
  AiFillThunderbolt,
} from "react-icons/ai";
import { BiCommentAdd } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { useAuth } from "../context/AuthContext";
import { sendNotification } from "../utils/notification";


const InquirePopover = ({ post, page, alreadyLiked, likePage, alreadyBought, getData }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loading } = useSelector((store) => store)

    const { currentUser } = useAuth()

    const [events, setEvents] = useState([])
    const [orderText, setOrderText] = useState("")
    const [urgentChecked, setUrgentChecked] = useState(false)

    const [alreadyOrdered, setAlreadyOrdered] = useState(false)
    const [alreadyUrgent, setAlreadyUrgent] = useState(false)

    const buyOrUnbuyPage = () => {
        let user = currentUser;
        let updatedBuys = post.buys;
        let filteredBuys = [];
        const pageNumber = parseInt(page.page);

        if (alreadyBought) {
            for (let i = 0; i < updatedBuys.length; i++) {
            if (updatedBuys[i].id !== user.uid) {
                filteredBuys.push(updatedBuys[i]);
            }
            }
            for (let i = 0; i < updatedBuys.length; i++) {
            if (
                updatedBuys[i].id === user.uid &&
                updatedBuys[i].page !== pageNumber
            ) {
                filteredBuys.push(updatedBuys[i]);
            }
            }
            updatedBuys = filteredBuys;
        } else {
            const mapKey = uuidv4();
            updatedBuys.push({
            mapKey: mapKey,
            id: user.uid,
            email: user.email,
            displayName: user.displayName,
            page: pageNumber,
            createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
            });
        }

        setDoc(doc(db, "posts", post.id), {
            ...post,
            buys: updatedBuys,
        })
            .then(() => {
            getData();
            })
            .catch(() => {
            toast.error("An error occurred");
            });
    }

    const urgentOrUnurgentPage = () => {
        let user = currentUser;
        let updatedUrgents = post.urgents;
        let filteredUrgents = [];
        const pageNumber = parseInt(page.page);
    
        if (alreadyUrgent) {
          for (let i = 0; i < updatedUrgents.length; i++) {
            if (updatedUrgents[i].id !== user.uid) {
              filteredUrgents.push(updatedUrgents[i]);
            }
          }
          for (let i = 0; i < updatedUrgents.length; i++) {
            if (
              updatedUrgents[i].id === user.uid &&
              updatedUrgents[i].page !== pageNumber
            ) {
              filteredUrgents.push(updatedUrgents[i]);
            }
          }
          updatedUrgents = filteredUrgents;
        } else {
          const mapKey = uuidv4();
          updatedUrgents.push({
            mapKey: mapKey,
            id: user.uid,
            email: user.email,
            displayName: user.displayName,
            page: pageNumber,
            createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
          });
        }
    
        setDoc(doc(db, "posts", post.id), {
          ...post,
          urgents: updatedUrgents,
        })
          .then(() => {
            getData();
          })
          .catch(() => {
            toast.error("An error occurred");
          });
    }

    const addEvent = async (orderId, imageURL, shareInfo) => {
        buyOrUnbuyPage();
        if (urgentChecked) {
          urgentOrUnurgentPage();
        }
        const user = currentUser;
        const tempEvents = post.events ?? [];

        dispatch({ type: "showLoading" });
        const mapKey = uuidv4();
        tempEvents.push({
          mapKey: mapKey,
          purpose: "Acquisition Inquiry",
          purposeNotes:
            "Your sales inquiry has been received. Stay tuned for status updates.",
          purposeColor: "info",
          postId: post.id,
          orderId: orderId,
          orderedById: user.uid,
          orderedByEmail: user.email,
          orderedByDisplayName: user.displayName,
          orderText: orderText,
          urgentChecked: urgentChecked,
          pageNumber: parseInt(page.page),
          imageURL: imageURL,
          shareInfo: shareInfo,
          createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
        });
    
        setDoc(doc(db, "posts", post.id), {
          ...post,
          events: tempEvents,
        }).then(() => {
          dispatch({ type: "hideLoading" });
          setOrderText("");
          setUrgentChecked(false);
        });
      }

    const addOrder = async () => {
        let postId = post?.id
        const user = currentUser;
    
        const sharedInfo = {
          mapKey: uuidv4(),
          sharedByDisplayName: post?.userDisplayName,
          sharedByEmail: post?.userEmail,
          sharedById: post?.userId,
          sharedOn: post?.createdAt || post?.createdOn,
          sharedPostId: post?.id,
        }
    
    
        dispatch({ type: "showLoading" });
        const mapKey = uuidv4();
        addDoc(collection(db, "orders"), {    
          status: "Acquisition Inquiry Pending",
          statusNotes:
            "Your sales inquiry has been received. Stay tuned for status updates.",
          statusPrev: "",
          statusNotesPrev: "",
          statusColor: "info",
          statusPrevColor: "",
          postDescription: post.description,
          userId: user.uid,
          userEmail: user.email,
          userDisplayName: user.displayName,
          orderText: orderText,
          urgentChecked: urgentChecked,
          pageNumber: parseInt(page.page),
          postId: postId,
          imageURL: page.imageURL,
          events: [
            {
              mapKey: mapKey,
              purpose: "Acquisition Inquiry",
              purposeNotes:
                "Your sales inquiry has been received. Stay tuned for status updates.",
              purposeColor: "info",
              postId: post.id,
              orderedById: user.uid,
              orderedByEmail: user.email,
              orderedByDisplayName: user.displayName,
              orderText: orderText,
              urgentChecked: urgentChecked,
              pageNumber: parseInt(page.page),
              imageURL: page.imageURL,
              shareInfo: sharedInfo,
              createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
            },
          ],
          shareInfo: sharedInfo,
          inventory: [],
          createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
          updatedOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
        })
          .then(async (docRef) => {
            dispatch({ type: "hideLoading" });
            addEvent(docRef.id, page.imageURL, sharedInfo);
            await sendNotification(user?.uid, sharedInfo?.sharedById, 'orderCreated', postId, docRef?.id, page.page)
            if(!alreadyLiked) likePage()
          })
          .catch(() => {
            dispatch({ type: "hideLoading" });
            toast.error("Something went wrong");
          });
      };

    const getAlreadyOrdered = () => {
        if (post.events) {
            const filteredEvents = [];
    
            for (let i = 0; i < post.events.length; i++) {
              if (
                post.events[i].pageNumber === parseInt(page.page) &&
                post.events[i].orderedById === currentUser.uid
              ) {
                filteredEvents.push(post.events[i]);
              }
            }
            setEvents(filteredEvents);
            if (filteredEvents.find((user) => user.orderedById === currentUser.uid)) {
              setAlreadyOrdered(true);
            } else {
              setAlreadyOrdered(false);
            }
          }
    }

    const getAlreadyUrgent = () => {
        if (post.urgents) {    
            let updatedUrgents = post.urgents;
            let filteredUrgents = [];
            let finalFilteredUrgents = [];
            const pageNumber = page.page;
    
            for (let i = 0; i < updatedUrgents.length; i++) {
              if (updatedUrgents[i].page === pageNumber) {
                filteredUrgents.push(updatedUrgents[i]);
              }
            }
            for (let i = 0; i < filteredUrgents.length; i++) {
              if (filteredUrgents[i].id !== currentUser.uid) {
                finalFilteredUrgents.push(filteredUrgents[i]);
              }
            }
    
            if (filteredUrgents.find((user) => user.id === currentUser.uid)) {
              setAlreadyUrgent(true);
            } else {
              setAlreadyUrgent(false);
            }
        }
    }

    useEffect(() => {
        getAlreadyOrdered()
        getAlreadyUrgent()
    }, [post])

    return(
        <Popover id={"popover-basic"}>
        <Popover.Header
          as="h3"
          className="bg-dark text-light text-center"
          style={{
            fontWeight: "normal",
            fontSize: 18,
            letterSpacing: 1,
          }}
        >
          {alreadyOrdered ? "Inquired" : "Inquire"}
        </Popover.Header>
        <Popover.Body>
          <ul className="p-0">
            {events.map((event) => {
              return (
                <li
                  key={event.mapKey}
                  className="lh-lg"
                  style={{
                    listStyle: "none",
                    fontWeight: "normal",
                    fontSize: 16,
                  }}
                >
                  <h6
                    className="pb-2"
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    {/* {event.orderedByDisplayName}  */}
                    Inquiry Sent
                  </h6>
                  <h6
                    style={{
                      fontWeight: "normal",
                      fontSize: 13,
                    }}
                  >
                    {/* {event.orderedByDisplayName}  */}
                    Inquired for potential acquisition
                  </h6>
                  <h6
                    className="text-primary pt-1"
                    style={{
                      fontWeight: "normal",
                      fontSize: 13,
                    }}
                  >
                    {/* {event.orderedByDisplayName}  */}
                    Pending seller response
                  </h6>

                  {event.urgentChecked ? (
                    <h6 className="mt-1">
                      <AiFillThunderbolt
                        size={18}
                        //onClick={urgentOrUnurgentPage}
                        color="orange"
                        // style={{ cursor: "pointer" }}
                      />{" "}
                      <span
                        className="mx-2 text-danger"
                        style={{
                          fontWeight: "normal",
                          fontSize: 13,
                        }}
                      >
                        {/* {event.orderedByDisplayName}  */}
                        Urgent request
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}

                  {event.orderText ? (
                    <h6 className="mt-1">
                      <BiCommentAdd
                        size={18}
                        //onClick={urgentOrUnurgentPage}
                        color="gray"
                        // style={{ cursor: "pointer" }}
                      />{" "}
                      <span
                        className="mx-2 text-dark"
                        style={{
                          fontWeight: "normal",
                          fontSize: 13,
                        }}
                      >
                        "{event.orderText}"
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}

                  <div
                    className=""
                    style={{
                      fontWeight: "light",
                      fontSize: 13,
                      letterSpacing: 1,
                    }}
                  >
                    {event.createdOn.split(" ")[0]}
                  </div>

                  <MDBBadge
                    // color={alreadyOrdered ? "secondary" : "dark"}
                    color="dark"
                    notification
                    className="d-grid w-100 mt-3 rounded text-light text-center shadow-sm"
                    style={{
                      fontWeight: "normal",
                      fontSize: 16,
                      cursor: "pointer",
                      border: "1px solid gray",
                    }}
                    onClick={() =>
                      navigate(
                        `/order-s-status/${event.orderId}`
                      )
                    }
                  >
                    <div
                    // className={
                    //   alreadyOrdered ? "danger" : "secondary"
                    // }
                    >
                      {/* {buys.length} */}
                      {/* {alreadyOrdered ? inquired : inquire} */}
                      Check Status
                    </div>
                  </MDBBadge>
                </li>
              );
            })}
            <li
              className="lh-lg"
              style={{
                listStyle: "none",
                fontWeight: "normal",
                fontSize: 14,
              }}
            >
              {alreadyOrdered ? (
                ""
              ) : (
                <div>
                  <h6
                    className=""
                    style={{
                      fontWeight: "bold",
                      fontSize: 15,
                    }}
                  >
                    Acquisition Request
                  </h6>
                  <h6
                    className="pt-2 pb-2"
                    style={{
                      fontWeight: "normal",
                      fontSize: 14,
                    }}
                  >
                    Notes:
                  </h6>
                  <div className="">
                    <textarea
                      className=""
                      rows="2"
                      value={orderText}
                      onChange={(e) =>
                        setOrderText(e.target.value)
                      }
                    ></textarea>
                  </div>

                  <div className="form-check form-switch ">
                    <span
                      className="align-middle"
                      style={{
                        display: "inline-block",
                      }}
                    >
                      {" "}
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        checked={urgentChecked}
                        onChange={() =>
                          setUrgentChecked(!urgentChecked)
                        }
                      />
                    </span>
                    <span
                      className="align-middle pt-1"
                      style={{
                        display: "inline-block",
                      }}
                    >
                      {" "}
                      <label
                        className="form-check-label mx-2"
                        for="flexSwitchCheckDefault"
                      >
                        <h6
                          style={{
                            fontWeight: "normal",
                            fontSize: 14,
                          }}
                        >
                          {urgentChecked
                            ? "Noted urgent"
                            : "Note as urgent"}
                        </h6>
                      </label>
                    </span>
                  </div>

                  <MDBBadge
                    color={
                      alreadyOrdered ? "secondary" : "dark"
                    }
                    notification
                    className="d-grid w-100 mt-3 rounded text-light text-center shadow-sm"
                    style={{
                      fontWeight: "normal",
                      fontSize: 16,
                      cursor: "pointer",
                      border: "1px solid gray",
                    }}
                    onClick={addOrder}
                  >
                    <div
                      className={
                        alreadyOrdered
                          ? "danger"
                          : "secondary"
                      }
                    >
                      {loading ? (
                        <Loader />
                      ) : alreadyOrdered ? (
                        "Inquired"
                      ) : (
                        "Submit"
                      )}
                    </div>
                  </MDBBadge>
                </div>
              )}
            </li>
          </ul>
        </Popover.Body>
      </Popover>
    )
}

export default InquirePopover;