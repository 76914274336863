export const CustomToggleButton = ({
  options,
  selectedOption,
  setSelectedOption,
  isDisabled = false,
  className = "",
}) => {
  return (
    <div
      className={`bg-light-gray flex rounded-md p-1 w-fit text-sm ${className}`}
    >
      {options.map((option, index) => (
        <button
          disabled={isDisabled}
          key={index}
          className={`flex-2 py-2 border-none rounded-md px-2 mx-2 ${
            selectedOption === option ? "bg-white" : "bg-transparent"
          } ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"} `}
          onClick={() => setSelectedOption(option)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};
