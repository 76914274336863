import { CustomButton } from "components/atoms/CustomButton";
import Loader from "components/Loader";
import { CustomCard } from "components/organisms/CustomCard";
import { UsersSelect } from "components/organisms/UsersSelect";
import { User } from "domainModels/User";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
export const InviteUserCard = ({ invitingUserId, className }) => {
  const selectShareRef = useRef();

  const [userListForSelect, setUserListForSelect] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onInvite = async () => {
    if (selectedUsers.length === 0) {
      toast.error("Please select at least one user to invite");
      return;
    }
    setIsLoading(true);
    const userIds = selectedUsers.map((user) => user.value);
    try {
      const user = await User.getById(invitingUserId);
      for (const userId of userIds) {
        const userToInvite = await User.getById(userId);
        await user.inviteUserToNetwork(userToInvite);
        disableUser(userId);
      }
      if (selectShareRef?.current) {
        // @ts-ignore
        selectShareRef.current?.clearSelected();
      }
      setSelectedUsers([]);
      toast.success("Invitations sent successfully");
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  const disableUser = (userId) => {
    const updatedUserList = userListForSelect.map((user) => {
      if (user.value === userId) {
        return { ...user, isDisabled: true };
      }
      return user;
    });
    setUserListForSelect(updatedUserList);
  };

  const getOptions = async () => {
    const users = await User.getAllPublic();
    const network = await User.getUserNetwork(invitingUserId);
    const networkIds = network.map((user) => user.id);

    const isInNetwork = (userId) => networkIds.includes(userId);

    const isCurrentUser = (userId) => userId === invitingUserId;

    const getLabelExtra = (userId) => {
      if (isInNetwork(userId)) return "(Already in Network)";
      if (isCurrentUser(userId)) return "(You)";
      return "";
    };

    const options = users.map((user) => ({
      value: user.id,
      label: `@${user.username} ${getLabelExtra(user.id)}`,
      isDisabled: isInNetwork(user.id) || isCurrentUser(user.id),
    }));
    setUserListForSelect(options);
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <CustomCard className={`${className}`}>
      <div className={`grid grid-cols-2 gap-3  min-h-[200px] `}>
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center">
          <h1 className="text-xl !mb-4">Grow your network</h1>
          <p>You can search by username to add them to your network.</p>
        </div>
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center">
          <UsersSelect
            userList={userListForSelect}
            setSelected={setSelectedUsers}
            ref={selectShareRef}
          />
          <CustomButton className="!mt-3" onClick={onInvite}>
            {isLoading ? <Loader /> : <span>Add Connections</span>}
          </CustomButton>
        </div>
      </div>
    </CustomCard>
  );
};
