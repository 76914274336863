import { CustomButton } from "components/atoms/CustomButton";
import { CustomCard } from "components/organisms/CustomCard";
import { EmailInput } from "components/organisms/EmailInput";
import { User } from "domainModels/User";
import { useState } from "react";
import { toast } from "react-toastify";

export const InviteEmailCard = ({ invitingUserId, className = "" }) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const onInvite = async () => {
    if (!invitingUserId) {
      toast.error("There was an unexpected error, please try again later.");
      return;
    }

    if (!email) {
      toast.error("Please enter an email address.");
      return;
    }

    if (!isEmailValid) {
      setIsEmailValid(false);
      toast.error("Invalid email, please check the email address.");
      return;
    }

    try {
      const user = await User.getById(invitingUserId);
      await user.inviteNewUser(email, "buyer");
      toast.success("Invitation sent to " + email);
      setEmail("");
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <CustomCard className={`${className}`}>
      <div className={`grid grid-cols-2 gap-3 min-h-[200px] `}>
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center">
          <h1 className="text-xl !mb-4">Invite your friends to romulus.art</h1>
          <p>
            Also, you can invite your friends, clients, co-workers or anyone
            that would like to be part of your romulus.art network.
          </p>
        </div>
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center">
          <EmailInput
            className={"!mb-3 "}
            value={email}
            onChange={setEmail}
            isEmailValid={isEmailValid}
            setIsEmailValid={setIsEmailValid}
            placeholder={"john.doe@gmail.com"}
          />
          <CustomButton onClick={onInvite}>Send Invite</CustomButton>
        </div>
      </div>
    </CustomCard>
  );
};
