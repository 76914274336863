// @ts-check

import React from "react";

/**
 *
 * @param {object} props
 * @param {string} props.src
 * @param {string} props.name
 * @param {()=>void} [props.onClick]
 * @returns
 */
export const Avatar = ({ src, name , onClick}) => {
  const baseClasses =
    `h-10 w-10 !border-[1.5px] !border-main rounded-full shrink-0  ${onClick?"hover:cursor-pointer":""}`;
  return src ? (
    <img
      onClick={onClick}
      className={`${baseClasses}`}
      alt={"Avatar of " + name}
      src={src}
    />
  ) : (
    <EmptyAvatar onClick={onClick}  name={name} className={baseClasses} />
  );
};

/**
 *
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.className
 * @param {()=>void} [props.onClick]
 * @returns
 */
const EmptyAvatar = ({ name, className,onClick }) => {
  if (!name) return null;
  const initial = name.charAt(0).toUpperCase();
  return (
    <span
      onClick={onClick}
      className={`flex justify-center items-center font-bold text-lg bg-[#D9D9D9]  ${className}`}
    >
      {initial}
    </span>
  );
};
