// @ts-check
import React, { useRef, useState } from "react";
import Loader from "./Loader";
import CreatableSelect from "react-select/creatable";
import { Link } from "react-router-dom";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from "../context/AuthContext";
import moment from "moment";
import { toast } from "react-toastify";
import { sendNotification } from "../utils/notification";
import AdminInviteModal from "./AdminInviteModal";
import { Invitation } from "domainModels/Invitation";
import { User } from "domainModels/User";



function ConnectionList({ users, options, type, refreshList }) {
const { currentUser, isConnoisseur, isVendor } = useAuth();
  const selectRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState([]);

  const [searchOption, setSearchOption] = useState(null);

  const filterOptions = options.filter((option) =>
    option?.label.includes(searchOption)
  );

  const styles = {
    control: (styles) => ({ ...styles, borderRadius: "10px" }),
    container: (styles) => ({ ...styles, width: "100%", marginRight: "10px" }),
  };

  const isEmail = (string) => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      string
    );
  };

  const userAlreadyInvited = async (email, type) => {
    const notMemberDoc = await getDoc(
      doc(db, "users", currentUser?.uid, type, email)
    );
    return notMemberDoc.exists();
  };

  const handleInvite = async (users) => {
    if (selected.length === 0) return;

    setLoading(true);

    const newUsers = users
      ?.filter((user) => user?.__isNew__)
      .map((user) => ({ ...user, email: user?.value.toLowerCase() }));

    await Promise.all(
      newUsers.map(async (user) => {
        await inviteNewUser(user);
      })
    );

    const alreadyUsers = users.filter((user) => !user?.__isNew__);

    await Promise.all(
      alreadyUsers.map(async (user) => {
        const alreadyUserDoc = await getDoc(doc(db, "users", user?.value));
        const alreadyUser = { ...alreadyUserDoc.data(), id: alreadyUserDoc.id };

        const alredyInvited = await userAlreadyInvited(
          alreadyUser?.email,
          type
        );

        if (alredyInvited) {
          toast.error(`Invite alredy sent to @${alreadyUser?.username}`);
        } else {
          await inviteAlreadyUser(alreadyUser);
        }
      })
    );

    refreshList();

    setLoading(false);
  };

  const inviteAlreadyUser = async (user) => {
    const invitedUser = {
      sub: user?.role,
      name: user?.displayName,
      email: user?.email,
      username: user?.username,
      isFav: false,
      createdAt: moment().format(),
      createdBy: currentUser?.uid,
      status: "pending",
    };

    const requesterUser = {
      sub: isConnoisseur() ? "Seller" : "Buyer",
      name: currentUser?.displayName,
      email: currentUser?.email,
      username: currentUser?.username,
      isFav: false,
      createdAt: moment().format(),
      createdBy: currentUser?.uid,
      status: "pending",
    };

    setDoc(doc(db, "users", currentUser?.uid, type, user?.id), invitedUser)
      .then((response) => {
        setDoc(
          doc(db, "users", user?.id, type, currentUser?.uid),
          requesterUser
        )
          .then(async (response) => {
            toast.success(`Invite sent to @${user?.username}`);
            if (selectRef.current) selectRef.current.clearValue();
            await sendNotification(
              currentUser?.uid,
              user?.id,
              "connectionInvite"
            );
          })
          .catch((err) => {
            console.log(err);
            toast.error(`Failed to send invite to @${user?.username}`);
            deleteDoc(doc(db, "users", currentUser?.uid, type, user?.id)).catch(
              (err) => console.log(err)
            );
          });
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to send invite to @${user?.username}`);
      });
  };

  const inviteNewUser = async (user) => {

    const isEmailValid = isEmail(user?.email);

    if (!isEmailValid) {
      toast.error(`${user?.email} is not a valid email`);
      return;
    }

    try {
      const myUser = await User.getById(currentUser?.uid)
      await myUser.inviteNewUser(user?.email, type);
    } catch (err) {
      toast.error(err.message);
    }

    toast.success(`Invite sent to ${user?.email}`);
    
  };

  const [showAdminModal, setShowAdminModal] = useState(false);

  const checkUserRole = () => {
    if (selected.length === 0) return;

    setLoading(true);

    const newUsers = selected?.filter((user) => user?.__isNew__);

    if (type === "vendors") {
      handleInvite(selected.map((obj) => ({ ...obj, role: "vendor" })));
    } else if (newUsers.length !== 0) {
      setShowAdminModal(true);
    } else {
      handleInvite(selected);
    }
  };

  const formatCreateLabel = (inputValue) => `Invite "${inputValue}"`;

  

  return (
    <>
      {!isVendor() && (
        <div className="d-flex mt-1 w-full align-items-center mb-3">
          <CreatableSelect
            ref={selectRef}
            isMulti
            closeMenuOnSelect={false}
            options={filterOptions}
            onChange={(obj) => setSelected([...obj])}
            styles={styles}
            isClearable
            formatCreateLabel={formatCreateLabel}
            onInputChange={(value) =>
              value === "" ? setSearchOption(null) : setSearchOption(value)
            }
          />
          {loading ? (
            <Loader />
          ) : (
            <Link
              onClick={checkUserRole}
              style={{
                textDecoration: "none",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {type === "vendors" ? "Add Vendors" : "Add To Network"}
            </Link>
          )}
        </div>
      )}
     

      <AdminInviteModal
        invites={selected}
        show={showAdminModal}
        setShow={setShowAdminModal}
        handleInvite={handleInvite}
        setLoading={setLoading}
      />
    </>
  );
}

export default ConnectionList;
