import { BellIcon } from "lucide-react";
import moment from "moment";
import React, { useMemo } from "react";
import { Badge } from "react-bootstrap";
import { NavBarDropDownItem } from "./NavBarDropDownItem";
import Notification from "../../Notificaton";
/**
 * @param {object} props
 * @param {number} props.notificationCounter
 * @param {string} props.tabName
 * @param {object} props.notifications
 * @param {boolean} props.isOpen
 * @param {function(string):void} [props.onClick]
 */
export function NotificationsDropdownMenu({
  notifications,
  notificationCounter,
  onClick,
  tabName,
  isOpen,
}) {
  const filteredNotifications = useMemo(() => {
    return notifications
      .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
      .slice(0, 3);
  }, [notifications]);
  return (
    <div onClick={() => onClick?.(tabName)}>
      <BellIcon color="black" className="w-5 m-auto mt-1 cursor-pointer" />
      {notificationCounter > 0 && (
        <Badge pill bg="secondary" className="absolute -top-2 -right-2">
          {notificationCounter}
        </Badge>
      )}

      {isOpen && (
        <ul className="lg:absolute lg:top-[3.4rem] text-black lg:right-0   w-72 bg-white  divide-y divide-gray-100 rounded-lg lg:shadow cursor-pointer">
          {filteredNotifications.map((notification) => (
            <li>
              <Notification notification={notification} />
            </li>
          ))}
          <div className="flex justify-center text-sm py-2.5 px-5 hover:bg-gray-100">
            <NavBarDropDownItem
              item={{
                title: "See All Notifications",
                path: "/notifications",
              }}
            ></NavBarDropDownItem>
          </div>
        </ul>
      )}
    </div>
  );
}
