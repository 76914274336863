import React from "react";
import Container from "react-bootstrap/Container";

function DefaultLayout(props) {
  return (
    <Container
      fluid
      className="px-3 md:px-6 pt-20 md:pt-28"
      style={{ flex: 1, maxWidth: "1440px" }}
    >
      {props.children}
    </Container>
  );
}

export default DefaultLayout;
