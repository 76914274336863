import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useAuth } from "../context/AuthContext";
import { MDBBadge } from "mdb-react-ui-kit";
import { AiFillDollarCircle, AiFillHeart } from "react-icons/ai";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { FaComment, FaHandPaper } from "react-icons/fa";
import { GrStatusGoodSmall } from  "react-icons/gr";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";

function TableFilterModal({show, setShow, post, userSelected, setUserSelected, filters, setFilters, showAllPages, setShowAllPages}) {

  const { currentUser } = useAuth()

  const [usersSharedWith, setUsersSharedWith] = useState([])

  let uniqueUsers = {}

  const getUsersSharedWith = (post) => {
    getDocs(
      query(
        collection(db, 'sharedPosts'),
        where('post', '==', post.id),
        where('sharedBy', '==', currentUser?.uid)
      )
    ).then(async response => {

      const sharedPosts = response.docs.map(docu => ({
        ...docu.data(),
        id: docu.id
      }))

      const sharedPostsWithUser = await Promise.all(
        sharedPosts.map(async sp => {

          const sharedWith = await getDoc(doc(db, 'users', sp.sharedWith))

          return {
            ...sp,
            sharedWith: {...sharedWith.data(), id: sharedWith.id}
          } 
        })
      )
      
      setUsersSharedWith(sharedPostsWithUser.map(sp => sp.sharedWith))

    })
  }

  useEffect(() => {
    if(post) getUsersSharedWith(post)
  }, [post])

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Filter Input</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="p-1">
          <Form.Group className="mt-1 mb-4">
            <Form.Select
              className="font-italic w-75"
              value={userSelected.id}
              onChange={(e) => {
                setUserSelected({
                  id: e.target.value,
                  displayName: e.target.selectedOptions[0].textContent
                });
              }}
            >
             {usersSharedWith.filter(obj => {
                if(obj.id === undefined) return false
                if(uniqueUsers[obj.id]) return false
                else{
                  uniqueUsers[obj.id] = true
                  return true
                }
              }).map((user) => {
                return (
                  <option
                    key={user.id}
                    value={user.id}
                  >
                    {user.displayName}
                  </option>
                );
              })}
              <option value={'all'}>All</option>
            </Form.Select>
            </Form.Group>
          <Form.Group className="d-inline-flex">
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={!showAllPages}
              onChange={() => setShowAllPages(!showAllPages)}
            />
            <Form.Check.Label>
              <MDBBadge
                color="white"
                className={`d-inline-flex align-items-center p-1 px-2   ${
                  !showAllPages ? "shadow-sm" : ""
                }`}
              >
                <GrStatusGoodSmall
                  size={18}
                  color={
                    !showAllPages ? "black" : "lightGray"
                  }
                />
                <span
                  className={`ps-2 fs-6 fw-lighter ${
                    !showAllPages
                      ? "text-dark"
                      : "text-secondary"
                  }`}
                >
                  {!showAllPages
                    ? "Only feedback"
                    : "Showing all pages"}
                </span>
              </MDBBadge>
            </Form.Check.Label>
          </Form.Group>

          <Form.Group className="my-3 row">
            <div className="row px-3 mb-3">
              {" "}
              <div className="col-6">
                {" "}
                <Form.Check type="checkbox" inline={true}>
                  <Form.Check.Input
                    type={"checkbox"}
                    defaultChecked={filters.buys}
                    onClick={(e) => {
                      setFilters(prev => ({...prev, buys: e.target.checked}))
                    }}
                  />
                  <Form.Check.Label>
                    <MDBBadge
                      color="white"
                      className={`d-inline-flex align-items-center p-1 px-2  ${
                        filters.buys ? "shadow-sm" : ""
                      }`}
                    >
                      <AiFillDollarCircle
                        size={18}
                        color={
                          filters.buys
                            ? "blue"
                            : "lightGray"
                        }
                      />
                      <span
                        className={`ps-2 fs-6 fw-lighter ${
                          filters.buys
                            ? "text-dark"
                            : "text-secondary"
                        }`}
                      >
                        Buys
                      </span>
                    </MDBBadge>
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="col-6">
                {" "}
                <Form.Check
                  type="checkbox"
                  inline={true}
                  // value={likesFilter}
                  // onChange={(e) => {
                  //   setLikesFilter(true);
                  // }}
                >
                  <Form.Check.Input
                    type={"checkbox"}
                    defaultChecked={filters.urgents}
                    onClick={(e) => {
                      setFilters(prev => ({...prev, urgents: e.target.checked}))
                    }}
                  />
                  <Form.Check.Label>
                    <MDBBadge
                      color="white"
                      className={`d-inline-flex align-items-center p-1 px-2  ${
                        filters.urgents ? "shadow-sm" : ""
                      }`}
                    >
                      <BsFillLightningChargeFill
                        size={18}
                        color={
                          filters.urgents
                            ? "orange"
                            : "lightGray"
                        }
                      />
                      <span
                        className={`ps-2 fs-6 fw-lighter ${
                          filters.urgents
                            ? "text-dark"
                            : "text-secondary"
                        }`}
                      >
                        Urgents
                      </span>
                    </MDBBadge>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </div>
            <div className="row px-3 mb-3">
              {" "}
              <div className="col-6">
                {" "}
                <Form.Check
                  type="checkbox"
                  inline={true}
                >
                  <Form.Check.Input
                    type={"checkbox"}
                    defaultChecked={filters.likes}
                    onClick={(e) => {
                      setFilters(prev => ({...prev, likes: e.target.checked}))
                    }}
                  />

                  <Form.Check.Label>
                    <MDBBadge
                      color="white"
                      className={`d-inline-flex align-items-center p-1 px-2  ${
                        filters.likes ? "shadow-sm" : ""
                      }`}
                    >
                      <AiFillHeart
                        size={18}
                        color={
                          filters.likes
                            ? "red"
                            : "lightGray"
                        }
                      />
                      <span
                        className={`ps-2 fs-6 fw-lighter ${
                          filters.likes
                            ? "text-dark"
                            : "text-secondary"
                        }`}
                      >
                        Likes
                      </span>
                    </MDBBadge>
                  </Form.Check.Label>
                </Form.Check>
              </div>
              <div className="col-6">
                {" "}
                <Form.Check type="checkbox" inline={true}>
                  <Form.Check.Input
                    type={"checkbox"}
                    defaultChecked={filters.comments}
                    onClick={(e) => {
                      setFilters(prev => ({...prev, comments: e.target.checked}))
                    }}
                  />
                  <Form.Check.Label>
                    <MDBBadge
                      color="white"
                      className={`d-inline-flex align-items-center p-1 px-2  ${
                        filters.comments ? "shadow-sm" : ""
                      }`}
                    >
                      <FaComment
                        size={18}
                        color={
                          filters.comments
                            ? "pink"
                            : "lightGray"
                        }
                      />
                      <span
                        className={`ps-2 fs-6 fw-lighter ${
                          filters.comments
                            ? "text-dark"
                            : "text-secondary"
                        }`}
                      >
                        Comments
                      </span>
                    </MDBBadge>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </div>
            <div className="row px-3">
              <div className="col-6">
                {" "}
                <Form.Check
                  type="checkbox"
                  inline={true}
                  // value={likesFilter}
                  // onChange={(e) => {
                  //   setLikesFilter(true);
                  // }}
                >
                  <Form.Check.Input
                    type={"checkbox"}
                    defaultChecked={filters.onHolds}
                    onClick={(e) => {
                      setFilters(prev => ({...prev, onHolds: e.target.checked}))
                    }}
                  />
                  <Form.Check.Label>
                    <MDBBadge
                      color="white"
                      className={`d-inline-flex align-items-center p-1 px-2  ${
                        filters.onHolds ? "shadow-sm" : ""
                      }`}
                    >
                      <FaHandPaper
                        size={18}
                        color={
                          filters.onHolds
                            ? "orange"
                            : "lightGray"
                        }
                      />
                      <span
                        className={`ps-2 fs-6 fw-lighter ${
                          filters.onHolds
                            ? "text-dark"
                            : "text-secondary"
                        }`}
                      >
                        On Hold
                      </span>
                    </MDBBadge>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </div>
            </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setShow(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TableFilterModal;
