import React, { useEffect, useState } from "react";
import ConnectionList from "components/ConnectionList";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "firebaseConfig";
import { useAuth } from "context/AuthContext";
import { useMemo } from "react";
import { YourConnectionsSection } from "./YourConnectionsSection";
import { InviteEmailCard } from "components/templates/InviteEmailCard";
import { InviteUserCard } from "components/templates/InviteUserCard";

const Connections = () => {
  const { currentUserRealTime: currentUser } = useAuth();

  const [network, setNetwork] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [nonUsers, setNonUsers] = useState([]);

  const [networkOptions, setNetworkOptions] = useState([]);
  const [vendorsOptions, setVendorsOptions] = useState([]);

  const getUsernames = (type, setOptions) => {
    if (currentUser) {
      getDocs(collection(db, "users", currentUser?.uid, type)).then(
        (response) => {
          const usedIds = response.docs.map((doc) => doc.id);
          const documents = query(
            collection(db, "users"),
            where("role", type === "vendors" ? "==" : "!=", "vendor")
          );
          getDocs(documents).then((response) => {
            setOptions(
              response.docs
                .filter(
                  (doc) => ![...usedIds, currentUser?.uid].includes(doc.id)
                )
                .filter((doc) => doc.data().hasOwnProperty("role"))
                .map((doc) => ({
                  label: `@${doc.data().username}`,
                  value: doc.id,
                }))
            );
          });
        }
      );
    }
  };

  const getNetwork = () => {
    getDocs(collection(db, "users", currentUser?.uid, "network"))
      .then((response) => {
        setNetwork(
          response.docs
            .map((doc) => ({ ...doc.data(), id: doc.id }))
            .sort(sortObjectByName)
        );
      })
      .catch((err) => console.log(err));
  };

  const getVendors = () => {
    getDocs(collection(db, "users", currentUser?.uid, "vendors"))
      .then((response) => {
        setVendors(
          response.docs
            .map((doc) => ({ ...doc.data(), id: doc.id }))
            .sort(sortObjectByName)
        );
      })
      .catch((err) => console.log(err));
  };

  const getNotMembersInvited = () => {
    getDocs(collection(db, "users", currentUser?.uid, "notMembersInvited"))
      .then((response) => {
        setNonUsers(
          response.docs
            .map((doc) => ({ ...doc.data(), name: doc.id, nonUser: true }))
            .sort(sortObjectByName)
            .filter((user) => user?.type === "network")
        );
      })
      .catch((err) => console.log(err));
  };

  const sortObjectByName = (a, b) => {
    if (a.displayName < b.displayName) return -1;
    else if (a.displayName > b.displayName) return 1;
    else return 0;
  };

  const getLists = () => {
    getNetwork();
    getNotMembersInvited();
    getVendors();
    getUsernames("network", setNetworkOptions);
    getUsernames("vendors", setVendorsOptions);
  };

  const allUsers = useMemo(
    () => [...network, ...nonUsers],
    [network, nonUsers]
  );
  useEffect(() => {
    if (currentUser) {
      getLists();
    }
  }, [currentUser]);

  return (
    <div className=" w-full grid grid-cols-1 gap-10 lg:grid-cols-2  p-4 md:p-10">
      <div>
        <InviteUserCard className="!mb-5" invitingUserId={currentUser?.uid} />
        <InviteEmailCard className="!mb-5" invitingUserId={currentUser?.uid} />
      </div>
      <div>
        <YourConnectionsSection
          users={allUsers}
          type="network"
          refreshList={getLists}
        ></YourConnectionsSection>
      </div>
    </div>
  );
};

export default Connections;
