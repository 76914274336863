import algoliasearch from "algoliasearch";

const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
)

const searchClient = {
    ...algoliaClient,
    search(requests) {
        if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
                results: requests.map(() => ({
                  hits: [],
                  nbHits: 0,
                  nbPages: 0,
                  page: 0,
                  processingTimeMS: 0,
                  hitsPerPage: 0,
                  exhaustiveNbHits: false,
                  query: '',
                  params: '',
                })),
              });
            }
    
        return algoliaClient.search(requests);
    },
}

export { searchClient };
