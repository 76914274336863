import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useAuth } from "../context/AuthContext";
import { addDoc, collection, doc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import moment from "moment";
import { toast } from "react-toastify";
import { sendNotification } from "../utils/notification";
import Loader from "./Loader";
import Select from 'react-select';
import { FaStar } from "react-icons/fa";

const VendorShareModal = ({ orderId, show, setShow, getOrder }) => {

  const { currentUser } = useAuth()

  const [vendors, setVendors] = useState([])
  const [vendor, setVendor] = useState(null)
  const [alreadySharedWithVendors, setAlreadySharedWithVendors] = useState([])
  const [pickupLocation, setPickupLocation] = useState(null)
  const [howToPack, setHowToPack] = useState(null)
  const [shippingTo, setShippingTo] = useState(null)
  const [needInsurance, setNeedInsurance] = useState(null)
  const [dateToReceive, setDateToReceive] = useState(null)

  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setLoading(false)
    setShow(false)
  }

  const getVendors = () => {
    getDocs(collection(db, 'users', currentUser?.uid, 'vendors')).then(response => {
      setVendors(
        response.docs
          .map(doc => ({...doc.data(), id: doc.id}))
          ?.sort((a,b) => {
            if(a.isFav !== b.isFav) return b.isFav - a.isFav
            return a.name.localeCompare(b.name)
          })
      )
    }).catch(err => console.log(err))
  }

  const getAlreadySharedWithVendors = () => {
    getDocs(collection(db, 'orders', orderId, 'vendorInfo')).then(response => {
      setAlreadySharedWithVendors(response.docs.map(doc => doc.id))
    }).catch(err => {
      console.log(err)
    })
  }

  const shareAcquisition = () => {
    if(vendor === null){
      toast.error(`Must select vendor. Please try again!`)
      return
    }
    setLoading(true)

    setDoc(doc(db, 'orders', orderId, 'vendorInfo', vendors.find(v => v.id === vendor).id), {
      vendorDisplayName: vendors.find(v => v.id === vendor).name,
      pickupLocation: pickupLocation,
      howToPack: howToPack,
      shippingTo: shippingTo,
      needInsurance: needInsurance,
      dateToReceive: dateToReceive
    }).then(() => {
      addDoc(collection(db, 'sharedOrders'), {
        order: orderId,
        sharedBy: currentUser?.uid,
        sharedWith: vendor,
        createdAt: moment().format("YYYY-MM-DD hh:mm:ss a")
      }).then(async () => {
        await sendNotification(currentUser?.uid, vendor, 'shareOrder', null, orderId)
        toast.success('Order shared with vendor')
        getOrder()
        handleClose()
      }).catch(err => {
        console.log(err)
        toast.error(`Error sharing order with vendor. Please try again!`)
        setLoading(false)
      })
    }).catch(err => {
      console.log(err)
      toast.error(`Error sharing order with vendor. Please try again!`)
      setLoading(false)
    })
  }

  useEffect(() => {
    getVendors()
    getAlreadySharedWithVendors()
  }, [currentUser])

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Vendor Share</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={shareAcquisition}>
          <Form.Group className="mb-3">
            <Form.Label>Vendor</Form.Label>
            <Select             
              closeMenuOnSelect
              onChange={(obj) => setVendor(obj.value)}
              options={vendors.map(user => {
                const alreadySharedWith = alreadySharedWithVendors.find(
                  (obj) => obj === user.id
                )

                return {
                  label: <div style={{display: 'flex', alignItems: 'center'}}>
                    <span>{user?.name} {!!alreadySharedWith ? `(already shared)` : user?.id === currentUser?.uid ? '(You)' : ''}</span>
                    {user?.isFav && <FaStar size={14} color="#FFC400" style={{marginLeft: '5px'}}/>}
                  </div>,
                  value: user?.id,
                  isDisabled: !!alreadySharedWith,
                  icon: user?.isFav ? <FaStar size={14} color="#FFC400"/> : null
                }

              })}
              styles={{
                control: (styles) => ({...styles, borderRadius: '10px', height: '50px'}),
                container: (styles) => ({...styles, width: '100%', marginBottom: '20px'}),
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Pickcup Location</Form.Label>
            <Form.Control
              value={pickupLocation}
              onChange={(e) => setPickupLocation(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>How to pack</Form.Label>
            <Form.Control
              type="text"
              as='textarea'
              value={howToPack}
              onChange={(e) => setHowToPack(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Shipping to</Form.Label>
            <Form.Control
              type="text"
              as='textarea'
              value={shippingTo}
              onChange={(e) => setShippingTo(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="me-3">Need Insurance:</Form.Label>
            <Form.Check
              inline
              type="radio"
              label='Yes'
              name="insurance"
              value={needInsurance}
              onChange={(e) => setNeedInsurance(true)}
            />
            <Form.Check
              inline
              type="radio"
              label='No'
              name="insurance"
              value={needInsurance}
              onChange={(e) => setNeedInsurance(false)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Date To Receive</Form.Label>
            <Form.Control
              type="text"
              value={dateToReceive}
              onChange={(e) => setDateToReceive(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {loading
          ? <Loader/>
          : <Button onClick={shareAcquisition} variant="outline-dark">Share</Button>
        }
      </Modal.Footer>
    </Modal>
  )
  
  
};

export default VendorShareModal;
