import React from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

/**
 * @param {object} props
 * @param {boolean} props.isOpen
 * @param {function():void} props.onClick
 */
export function SmallNabBarIcon({ isOpen, onClick }) {
  return (
    <div onClick={onClick} className="block lg:hidden">
      {isOpen ? (
        <div className="p-1.5 m-1 h-9 w-9 border rounded-full">
          <AiOutlineClose className="m-auto" color="black" size={20} />
        </div>
      ) : (
        <div className="p-1.5 m-1 h-9 w-9 border rounded-full">
          <AiOutlineMenu className="m-auto" color="black" size={20} />
        </div>
      )}
    </div>
  );
}
