// Author: Ciaran O'Keeffe (Ciaran OKeeffe)
// Copyright © 2023 Ciaran OKeeffe All Rights Reserved
// console.log("Code author: Ciaran O'Keeffe, Copyright © 2023 Ciaran OKeeffe All Rights Reserved");

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { enableNetwork, getFirestore } from "firebase/firestore";

/* global firebase, firebaseui, Stripe */

/**
 * Replace with your publishable key from the Stripe Dashboard
 * https://dashboard.stripe.com/apikeys
 */
const STRIPE_PUBLISHABLE_KEY =
  "pk_test_51N4QedCXLfxGXqnfROFfJCepqSNf0RyffuWSjgizMsCJWIV9U4k3vnWofY3JtYh6TeZlw6ls7MIuV0afvplaqtnr00urQalpNq";

/**
 * Your Firebase config from the Firebase console
 * https://firebase.google.com/docs/web/setup#config-object
 */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

/**
 * Initialize Firebase
 */
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
enableNetwork(db);
// let currentUser;
const analytics = getAnalytics(app);

export { app, db, analytics };
