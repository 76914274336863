import React from "react";
import { Modal } from "react-bootstrap";

function UserProfileModal({profile, show, setShow}) {

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      centered
      scrollable
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Collector Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container px-4">
          <div className="row mt-2 mb-3">
            Name: {profile?.name}
          </div>
          <div className="row my-3">
            Age: {profile?.age}
          </div>
          <div className="row my-3">
            Occupation: {profile?.occupation}
          </div>
          <div className="row my-3">
            Location: {profile?.locationCity},{" "}
            {profile?.locationCountry}
          </div>
          <div className="row my-3">
            Art advisor: {profile?.primaryAdvisor}
          </div>
          <div className="row my-3">
            Museum Affiliations:
            {profile?.museumAffils?.map(
              (museum) => {
                return (
                  <div key={museum.name} className="ms-2">
                    {museum.name}
                  </div>
                );
              }
            )}
          </div>
          <div className="row my-3">
            Wishlist:{" "}
            {profile?.wishlist?.map((wish) => {
              return (
                <div key={wish.name} className="ms-2">
                  {wish.name}
                </div>
              );
            })}
          </div>
          <div className="row my-3">
            Artists in Collection:
            {profile?.collectionArtists?.map(
              (collArtist) => {
                return (
                  <div key={collArtist.name} className="ms-2">
                    {collArtist.name}
                  </div>
                );
              }
            )}
          </div>
          <div className="row my-3">
            Galleries Worked With:{" "}
            {profile?.galleries?.map((gallery) => {
              return (
                <div key={gallery.name} className="ms-2">
                  {gallery.name}
                </div>
              );
            })}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserProfileModal;
