// Author: Ciaran O'Keeffe (Ciaran OKeeffe)
// Copyright © 2023 Ciaran OKeeffe All Rights Reserved

import React, { useState, useCallback, useRef } from "react";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

// import { FaComment } from "react-icons/fa";

// import {
//   AiFillHeart,
//   AiFillThunderbolt,
//   AiFillDollarCircle,
//   AiOutlineShareAlt,
//   AiFillBackward,
//   AiFillFilter,
//   AiFillEye,
//   AiFillPhone,
//   AiOutlinePhone,
//   AiOutlineUnorderedList,
//   AiOutlineAlert,
// } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
// import { MDBBadge } from "mdb-react-ui-kit";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Popover from "react-bootstrap/Popover";
// import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

function WishlistClientGrid({ like }) {
  // console.log(
  //   "Code author: Ciaran O'Keeffe, Copyright © 2023 Ciaran OKeeffe All Rights Reserved"
  // );

  const navigate = useNavigate();
  // const [show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const imgRef = useRef();

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    // check if image exists
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  //const [usersSharedWith, setUsersSharedWith] = useState([]);

  // const [alreadyBuy, setAlreadyBuy] = useState(false);
  // const [alreadyUrgent, setAlreadyUrgent] = useState(false);
  // const [alreadyLiked, setAlreadyLiked] = useState(false);
  // const [alreadyComment, setAlreadyComment] = useState(false);
  // const [showFeedbackBar, setShowFeedbackBar] = useState(false);
  // const [alreadyPhoned, setAlreadyPhoned] = useState(false);
  // const [alreadyShareRequested, setAlreadyShareRequested] = useState(false);
  // const [phoneData, setPhoneData] = useState();
  // const [shareRequestData, setShareRequestData] = useState();

  // const [userID, setUserID] = useState();
  // const auth = getAuth();

  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     // User is signed in, see docs for a list of available properties
  //     // https://firebase.google.com/docs/reference/js/firebase.User
  //     const uid = user.uid;
  //     setUserID(uid);
  //     //setUserID(uid);
  //     //console.log("user.uid: ", uid);
  //     //console.log("auth.currentuser: ", auth.currentUser);
  //     // ...
  //   } else {
  //     // User is signed out
  //     // ...
  //     // console.log(
  //     //   "inside of else block to auth onAuthStateChanged function, next lines logout() annd navigate to /login"
  //     // );
  //     // logout();
  //   }
  // });

  // const logout = async () => {
  //   // setState({ user: {}, token: "" });
  //   // localStorage.removeItem("auth");
  //   await signOut(auth)
  //     .then(() => {
  //       console.log("Signed Out");
  //       localStorage.removeItem("romulus_0-user");
  //       setUserID("");
  //       // navigate("/login");
  //     })
  //     .catch((e) => {
  //       console.error("Sign Out Error", e);
  //       // navigate("/login");
  //     });
  //   // navigate("/login");
  // };

  return (
    <>
      <div className="card border-muted text-center shadow-sm" style={{width: '100%'}}>
        {like && (
          <>
            <div
              className="p-sm-1"
              // onClick={() => navigate(`/order-s-status/${order.id}`)}
            >
              <div className="pt-1 text-center">
                {/* {" "}
                <MDBBadge
                  color="secondary"
                  className="p-2 pt-3 bg-secondary border shadow-sm"
                > */}

                <h6
                  className="bg-light p-2 rounded border-muted shadow-sm"
                  style={{whiteSpace: 'nowrap', maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                >
                  {like.postDesc}
                </h6>
                {/* </MDBBadge> */}
              </div>
              <Image
                fluid
                src={like?.imageURL}
                style={{height: '300px', objectFit: 'cover', width: '100%', cursor: 'pointer'}}
                onClick={() => setLgShow(true)}
              />
              
              {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}

              <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    <span className="text-muted h5">{like.postDesc} </span>
                    <span className="text-secondary h5">
                      - page {like.page}
                    </span>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {" "}
                  <div className="d-flex justify-content-center align-items-center">
                    {" "}
                    <QuickPinchZoom
                      onUpdate={onUpdate}
                      doubleTapToggleZoom={true}
                      doubleTapZoomOutOnMaxScale={true}
                      draggableUnZoomed
                    >
                      <img
                        ref={imgRef}
                        alt="img"
                        src={like.imageURL}
                        className="img-fluid rounded"
                        style={{
                          cursor: "pointer",
                          maxHeight: "80vh",
                          width: "100",
                        }}
                      />
                    </QuickPinchZoom>
                  </div>
                </Modal.Body>
              </Modal>

              <h6 className="bg-light p-2 pb-2 pt-2 mt-2 rounded border-muted shadow-sm">
                <span
                  style={{
                    fontWeight: "300",
                    fontSize: 15,
                    // fontStyle: "italic",
                  }}
                >
                  {like.createdOn.split(" ")[0]}
                </span>
              </h6>
              {/* <h6
              className="bg-light p-2 pt-3 pb-3 rounded border-muted shadow-sm"
              style={{
                fontWeight: "300",
                fontSize: 16,
                // fontStyle: "italic",
              }}
            >
              Inquired on {order.createdOn.split(" ")[0]}
            </h6> */}
              {/* <h6 className="bg-light p-2 pb-2 pt-2 mt-2 rounded border-muted shadow-sm">
              <span>
                Page <span className="font-weight-bold">{like.page}</span>
              </span>
            </h6> */}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default WishlistClientGrid;
