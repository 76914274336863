import { Search } from "lucide-react";
import React from "react";

/**
 *
 * @param {object} props
 * @param {function():void} [props.onClick]
 * @param {function(string):void} [props.onChange]
 * @param {string} [props.value]
 * @param {string} [props.className]
 * @returns
 */

const SearchInput = ({ onChange, onClick, value, className }) => {
  return (
    <div
      onClick={() => {
        onClick?.();
      }}
      className={`flex flex-row items-center h-10 text-white-900 border !border-aux-gray bg-white my-2 ${className}`}
    >
      <Search color="black" className="w-4 m-2" />
      <input
        type="text"
        className="p-2 bg-transparent text-black placeholder:text-gray-400 focus:outline-none "
        placeholder="Search"
        value={value}
        onChange={(e) => {
          onChange?.(e.target.value);
        }}
      ></input>
    </div>
  );
};

export default SearchInput;
