// Author: Ciaran O'Keeffe (Ciaran OKeeffe)
// Copyright © 2023 Ciaran OKeeffe All Rights Reserved

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { UserContext } from "../../context";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const PriceCard = ({ price, handleSubscription, subscribed, subscription }) => {
  // console.log(
  //   "Code author: Ciaran O'Keeffe, Copyright © 2023 Ciaran OKeeffe All Rights Reserved"
  // );

  // const [state] = useContext(UserContext);
  const navigate = useNavigate();
  const [userID, setUserID] = useState();
  const auth = getAuth();
  //console.log("subscription: ", subscription.items[0].price.nickname);
  // console.log("price: ", price);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      setUserID(uid);
      //console.log("user.uid: ", uid);
      //console.log("auth.currentuser: ", auth.currentUser);
      // ...
    } else {
      // User is signed out
      // ...
      // logout();
      setUserID("");
    }
  });

  const logout = async () => {
    // setState({ user: {}, token: "" });
    // localStorage.removeItem("auth");
    await signOut(auth)
      .then(() => {
        // console.log("Signed Out");
        localStorage.removeItem("romulus_0-user");
        setUserID("");
        navigate("/login");
      })
      .catch((e) => {
        console.error("Sign Out Error", e);
        navigate("/login");
      });
  };

  const dynamicDescription = () => {
    if (price.description === "curious") {
      return "Standard Plan";
    } else if (price.description === "connoisseur") {
      return "Premium Plan";
    }
  };

  const buttonStyle = () => {
    return price.description === "curious" ? "btn-outline-dark" : "btn-dark";
  };

  const headerStyle = () => {
    return price.description === "connoisseur" ? "bg-dark text-light" : "";
  };

  const borderStyle = () => {
    return price.description === "connoisseur" ? "border-dark" : "";
  };

  const buttonTextPre = () => {
    //console.log("state log:", state);
    //console.log("state.token log:", state.token);
    return userID && localStorage.getItem("romulus_0-user")
      ? "Purchase plan"
      : "Sign up";
  };

  const buttonTextPost = () => {
    //console.log("state log:", state);
    //console.log("state.token log:", state.token);
    if (subscribed && subscription) {
      return subscription.items[0].price.nickname === price.description
        ? "Access Plan"
        : "Add Plan";
    }
  };

  const dynamicPriceName = () => {
    let displayName = "";
    if (price.description === "curious") {
      displayName = "Art Buyer";
    } else if (price.description === "connoisseur") {
      displayName = "Art Seller";
    }
    return displayName;
  };

  return (
    <div className="col-12 col-sm-6">
      <div className={`card mb-4 rounded-3 shadow ${borderStyle()}`}>
        <div className={`card-header py-3 ${headerStyle()}`}>
          <h4 className="my-0 fw-normal">{dynamicPriceName()}</h4>
        </div>

        <div className="card-body">
          <h1 className="card-title pricing-card-title">
            {(price.unit_amount / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
            <small className="text-muted fw-light">/mo</small>
          </h1>
          <ul className="list-unstyled mt-3 mb-4">
            <li className="fw-bold">{dynamicDescription()}</li>
            {/* <li>- access to content and events</li> */}
            {/* <li></li>
            <li></li> */}
          </ul>

          {/* <pre>{JSON.stringify(price, null, 4)}</pre> */}

          {/* <Link to="/register"> */}
          <button
            onClick={(e) => handleSubscription(e, price)}
            className={`w-100 btn btn-lg ${buttonStyle()}`}
          >
            {subscribed && subscription ? buttonTextPost() : buttonTextPre()}
            {/* {buttonText()} */}
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
