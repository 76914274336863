import { Card } from "react-bootstrap";
import DefaultLayout from "../components/DefaultLayout";
import Notification from "../components/Notificaton";
import { useAuth } from "../context/AuthContext";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { markAllAsRead } from "../utils/notification";
import { toast } from "react-toastify";


function Notifications() {

    const { currentUser, notifications } = useAuth()

    const handleMarkAllAsRead = async() => {
        try {
            await markAllAsRead(currentUser)
        } catch (error) {
            console.log(error)
            toast.error("Failed to mark all as read")
        }
        
    }

    return (
        <DefaultLayout>
            <Card>
                <span className="text-center">
                    <Card.Header as="h5">Notifications</Card.Header>
                </span>
                <Card.Body className="d-flex flex-column min-vh-100 align-items-center pb-5 mb-5">
                    {notifications.length === 0 ? (
                        <div className="d-flex align-items-center col-12 col-sm-10 col-md-7 col-lg-6 col-xl-5 p-5 mb-5 border rounded justify-content-center">
                            No notifications to show
                        </div>
                    ) : (
                        <div className="col-12 col-sm-10 col-md-7 col-lg-6 col-xl-5 pb-2 mb-5 border rounded">
                            {notifications.map(notification => (
                                <Link style={{textDecoration: 'none', color: 'black'}}>
                                    <div className="m-2 d-flex">
                                        <Notification notification={notification}/>
                                    </div>
                                    <Divider style={{borderTop: '1px solid var(--bs-dropdown-divider-bg)', opacity: 1, margin: '8px 0'}}/>
                                </Link>
                            ))}
                            <Link
                                as={Link}
                                onClick={handleMarkAllAsRead}
                                style={{
                                    margin: '16px 8px',
                                    color: 'black',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                    textDecoration: 'none'
                                }}>
                                Mark All As Read
                            </Link>
                        </div>
                    )}  
                </Card.Body>
            </Card>
        </DefaultLayout>
    )
}

export default Notifications;