import { useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import { Snippet } from "react-instantsearch";

export const PageHit = ({ hit }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (hit.userId === currentUser?.uid) {
      navigate(`/post-o-page/${hit.postId}#${hit.page}`);
    } else {
      navigate(`/post-s-grid/${hit.postId}`, { state: { page: hit.page } });
    }
  };

  return (
    <article
      className="grid grid-cols-1 md:grid-cols-3 cursor-pointer w-full"
      onClick={handleOnClick}
    >
      <div className="col-span-1 md:col-span-1 md:h-[200px] overflow-hidden">
        <Image
          fluid
          src={hit?.imageURL}
          className="w-full h-full object-contain"
        />
      </div>

      <div className="col-span-1 md:col-span-2">
        <Snippet attribute="text" hit={hit} />
      </div>
    </article>
  );
};
