// @ts-check
import Col from "react-bootstrap/Col";
import {  Image  } from "react-bootstrap";
import React from "react";
import { FaCheck } from "react-icons/fa";
/**
 * @param {object} props
 * @param {(number)=>void} props.onPageSelected
 * @param {boolean} props.isSelected
 * @param {string} props.imageURL
 */

const ImageSelector = ({ onPageSelected, imageURL ,isSelected}) => {
  return (
    <div
      className={`${
        isSelected
          ? "border-brand-blue-secondary border-[5px] transition-all"
          : ""
      } transition-all rounded-xl shadow-md`}
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        cursor: "pointer",
        minHeight: "200px",
      }}
      onClick={onPageSelected}
    >
      <Image
        fluid
        src={imageURL}
        style={{
          height: "300px",
          objectFit: "cover",
          width: "100%",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default ImageSelector;
