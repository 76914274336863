import React, { useEffect, useState, useCallback, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import Loader from "../components/Loader";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Carousel from "react-bootstrap/Carousel";
import { MDBBadge, MDBBtn } from "mdb-react-ui-kit";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Modal from "react-bootstrap/Modal";
import Popover from "react-bootstrap/Popover";
import Card from "react-bootstrap/Card";
import {
  AiFillHeart,
  AiFillThunderbolt,
  AiFillDollarCircle,
} from "react-icons/ai";
import { FaReply, FaHandPaper } from "react-icons/fa"
import { IoCaretBackSharp } from "react-icons/io5";
import { BiCommentAdd } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { v4 as uuidv4 } from "uuid";

import { useAuth } from "../context/AuthContext";
import { sendNotification } from "../utils/notification";

function PostDescSpage() {

  const imgRef = useRef();
  const { loading } = useSelector((store) => store);
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    // check if image exists
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);
  const [lgShow, setLgShow] = useState(false);
  const inquired = "Inquired";
  const inquire = "Inquire";
  const submit = "Submit";
  const [post, setPost] = useState(null);
  const [alreadyLiked, setAlreadyLiked] = useState(false);
  const [alreadyBuy, setAlreadyBuy] = useState(false);
  const [alreadyUrgent, setAlreadyUrgent] = useState(false);
  const [alreadyFlag, setAlreadyFlag] = useState(false);
  const [handsUp, setHandsUp] = useState(false)

  const [sharedBy, setSharedBy] = useState(null);

  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [alreadyComment, setAlreadyComment] = useState(false);
  const [replyTo, setReplyTo] = useState()
  const [replyText, setReplyText] = useState("")
  const [loadingReply, setLoadingReply] = useState(false)

  const [events, setEvents] = useState([]);
  const [alreadyOrdered, setAlreadyOrdered] = useState(false);

  const [urgentChecked, setUrgentChecked] = useState(false);

  const [orderText, setOrderText] = useState("");

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()

  const [index, setIndex] = useState(0);

  const [urlSet, setUrlSet] = useState([]);

  const { currentUser } = useAuth()

  const getPost = () => {
    if (post) {
      const page = index + 1;
      // likes check
      if (post.likes) {
        //console.log("temp likes: ", temp.likes);
        //console.log("Page in getData(): ", page);

        for (let i = 0; i < post.likes?.length; i++) {
          if (post.likes[i].page === page && post.likes[i].id === currentUser.uid) {
            //console.log("temp.likes[i].page = ", temp.likes[i].page);
            // console.log(
            //   `Page number matched in likes (temp.likes[${i}].page = ${temp.likes[i].page})`
            // );
            // console.log(
            //   `User ID matched in matched like pages (temp.likes[${i}].id = ${temp.likes[i].id})`
            // );
          }
        }

        let updatedLikes = post.likes;
        let filteredLikes = [];
        let finalFilteredLikes = [];
        const pageNumber = index + 1;

        for (let i = 0; i < updatedLikes.length; i++) {
          if (updatedLikes[i].page === pageNumber) {
            filteredLikes.push(updatedLikes[i]);
          }
        }
        for (let i = 0; i < filteredLikes.length; i++) {
          if (filteredLikes[i].id !== currentUser.uid) {
            finalFilteredLikes.push(filteredLikes[i]);
          }
        }

        //setAlreadyLiked(false);
        if (filteredLikes.find((user) => user.id === currentUser.uid)) {
          setAlreadyLiked(true);
        } else {
          setAlreadyLiked(false);
        }
      }

      // buys check
      if (post.buys) {
        //console.log("temp buy: ", temp.buys);
        //console.log("Page in getData(): ", page);

        for (let i = 0; i < post.buys?.length; i++) {
          if (post.buys[i].page === page && post.buys[i].id === currentUser.uid) {
            //console.log("temp.buys[i].page = ", temp.buys[i].page);
            //console.log(
            //   `Page number matched in buys (temp.buys[${i}].page = ${temp.buys[i].page})`
            // );
            //console.log(
            //   `User ID matched in matched buys pages (temp.buys[${i}].id = ${temp.buys[i].id})`
            // );
          }
        }

        let updatedBuys = post.buys;
        let filteredBuys = [];
        let finalFilteredBuys = [];
        const pageNumber = index + 1;

        for (let i = 0; i < updatedBuys.length; i++) {
          if (updatedBuys[i].page === pageNumber) {
            filteredBuys.push(updatedBuys[i]);
          }
        }
        for (let i = 0; i < filteredBuys.length; i++) {
          if (filteredBuys[i].id !== currentUser.uid) {
            finalFilteredBuys.push(filteredBuys[i]);
          }
        }

        //setAlreadyLiked(false);
        if (filteredBuys.find((user) => user.id === currentUser.uid)) {
          setAlreadyBuy(true);
        } else {
          setAlreadyBuy(false);
        }
      }

      // urgents check
      if (post.urgents) {
        //console.log("temp urgents: ", temp.urgents);
        //console.log("Page in getData(): ", page);

        for (let i = 0; i < post.urgents.length; i++) {
          if (
            post.urgents[i].page === page &&
            post.urgents[i].id === currentUser.uid
          ) {
            //console.log("temp.buys[i].page = ", temp.urgents[i].page);
            // console.log(
            //   `Page number matched in buys (temp.urgents[${i}].page = ${temp.urgents[i].page})`
            // );
            // console.log(
            //   `User ID matched in matched buys pages (temp.urgents[${i}].id = ${temp.urgents[i].id})`
            // );
          }
        }

        let updatedUrgents = post.urgents;
        let filteredUrgents = [];
        let finalFilteredUrgents = [];
        const pageNumber = index + 1;

        for (let i = 0; i < updatedUrgents.length; i++) {
          if (updatedUrgents[i].page === pageNumber) {
            filteredUrgents.push(updatedUrgents[i]);
          }
        }
        for (let i = 0; i < filteredUrgents.length; i++) {
          if (filteredUrgents[i].id !== currentUser.uid) {
            finalFilteredUrgents.push(filteredUrgents[i]);
          }
        }

        if (filteredUrgents.find((user) => user.id === currentUser.uid)) {
          setAlreadyUrgent(true);
        } else {
          setAlreadyUrgent(false);
        }
      }

      // flags check
      if (post.flags) {
        //console.log("temp flags: ", temp.flags);
        //console.log("Page in getData(): ", page);

        const filteredFlags = [];
        //const pageNumber = index + 1;

        for (let i = 0; i < post.flags.length; i++) {
          if (post.flags[i].page === page) {
            //console.log("temp.buys[i].page = ", temp.flags[i].page);
            //console.log(
            //   `Page number matched in buys (temp.flags[${i}].page = ${temp.flags[i].page})`
            // );
            filteredFlags.push(post.flags[i]);
          }
        }

        if (filteredFlags.length > 0) {
          setAlreadyFlag(true);
        } else {
          setAlreadyFlag(false);
        }
      }

      // comments check
      if (post.comments) {
        //console.log("temp comments: ", temp.comments);
        //console.log("Page in getData(): ", page);

        const filteredComments = [];
        //const pageNumber = index + 1;

        for (let i = 0; i < post.comments?.length; i++) {
          if (
            post.comments[i].page === page &&
            post.comments[i].id === currentUser.uid ||
            post.comments[i].parentId !== null
          ) {
            filteredComments.push(post.comments[i]);
          }
        }
        setComments(filteredComments);
        //console.log("filteredComments.length= ", filteredComments.length);
        if (filteredComments.find((user) => user.id === currentUser.uid)) {
          setAlreadyComment(true);
        } else {
          setAlreadyComment(false);
        }
      }

      // order events check
      if (post.events) {
        //console.log("temp comments: ", temp.comments);
        //console.log("Page in getData(): ", page);

        const filteredEvents = [];
        //const pageNumber = index + 1;

        for (let i = 0; i < post.events.length; i++) {
          if (
            post.events[i].pageNumber === page &&
            post.events[i].orderedById === currentUser.uid
          ) {
            filteredEvents.push(post.events[i]);
          }
        }
        setEvents(filteredEvents);
        //console.log("filteredComments.length= ", filteredComments.length);
        if (filteredEvents.find((user) => user.orderedById === currentUser.uid)) {
          setAlreadyOrdered(true);
        } else {
          setAlreadyOrdered(false);
        }
      }

      getDocs(query(collection(db, 'users',  currentUser.uid, 'onHolds'), where('postId', '==', post.id))).then(response => {
        if(response.docs.map(doc => doc.data()).find(obj => obj.page === page)){
          setHandsUp(true)
        }else{
          setHandsUp(false)
        }
      })

    }
  };

  const getData = () => {
    // console.log("running getData()");

    //const auth = getAuth();
    //dispatch({ type: "showLoading" });

    // need to remove the #page_index from the params.id before passing it to getDoc()
    let postId = params.id.split("#")[0];
    //console.log("postId from params.split= ", postId);
    getDoc(doc(db, "posts", postId))
      .then((response) => {
        setPost({ ...response.data(), id: response.id });

        //console.log("response.data(): ", response.data());
        // const temp = response.data();

        // add an orders check in post (order id retrieved from firebase on upload and written to post with then)
      })
      .catch(() => {
        // dispatch({ type: "hideLoading" });
      });
  };

  const addEvent = async (orderId, imageURL, shareInfo) => {
    buyOrUnbuyPage();
    if (urgentChecked) {
      urgentOrUnurgentPage();
    }
    const user = currentUser;
    const page = index + 1;
    const tempEvents = post.events ?? [];
    // console.log("running addEvent()");
    dispatch({ type: "showLoading" });
    const mapKey = uuidv4();
    tempEvents.push({
      mapKey: mapKey,
      purpose: "Acquisition Inquiry",
      purposeNotes:
        "Your sales inquiry has been received. Stay tuned for status updates.",
      purposeColor: "info",
      postId: post.id,
      orderId: orderId,
      orderedById: user.uid,
      orderedByEmail: user.email,
      orderedByDisplayName: user.displayName,
      orderText: orderText,
      urgentChecked: urgentChecked,
      pageNumber: page,
      imageURL: imageURL,
      shareInfo: shareInfo,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    });

    setDoc(doc(db, "posts", post.id), {
      ...post,
      events: tempEvents,
    }).then(() => {
      // getData();
      // toast.success("Event added to post successfully");
      dispatch({ type: "hideLoading" });
      setOrderText("");
      setUrgentChecked(false);
    });

    // navigate(`/profile-grid/${auth.currentUser.uid}`);
  };

  const addOrder = async () => {
    const page = index + 1;
    let postId = params.id.split("#")[0];
    // get imageURL from post by matching page with index
    const user = currentUser;

    const sharedInfo = {
      mapKey: uuidv4(),
      sharedByDisplayName: post?.userDisplayName,
      sharedByEmail: post?.userEmail,
      sharedById: post?.userId,
      sharedOn: post?.createdAt||post?.createdOn,
      sharedPostId: post?.id,
    }


    dispatch({ type: "showLoading" });
    const mapKey = uuidv4();
    addDoc(collection(db, "orders"), {
      // gallery: "",
      // artists: [],
      // user: JSON.parse(localStorage.getItem("romulus_0-user")),

      status: "Acquisition Inquiry Pending",
      statusNotes:
        "Your sales inquiry has been received. Stay tuned for status updates.",
      statusPrev: "",
      statusNotesPrev: "",
      statusColor: "info",
      statusPrevColor: "",
      postDescription: post.description,
      userId: user.uid,
      userEmail: user.email,
      userDisplayName: user.displayName,
      orderText: orderText,
      urgentChecked: urgentChecked,
      pageNumber: page,
      postId: postId,
      imageURL: post.pages[index].imageURL, // imageURL of page from pdf for buy request
      events: [
        {
          mapKey: mapKey,
          purpose: "Acquisition Inquiry",
          purposeNotes:
            "Your sales inquiry has been received. Stay tuned for status updates.",
          purposeColor: "info",
          postId: post.id,
          orderedById: user.uid,
          orderedByEmail: user.email,
          orderedByDisplayName: user.displayName,
          orderText: orderText,
          urgentChecked: urgentChecked,
          pageNumber: page,
          imageURL: post.pages[index].imageURL,
          shareInfo: sharedInfo,
          createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
        },
      ], // add buy request to events property - for timeline display / navigation
      shareInfo: sharedInfo,
      // phones: [],
      // share_requests: [],
      // likes: [],
      // buys: [],
      // urgents: [],
      // comments: [],
      // flags: [],
      inventory: [],
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      updatedOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    })
      .then(async (docRef) => {
        // toast.success("Acquisition request sent");
        // let user = JSON.parse(localStorage.getItem("romulus_0-user"));
        // console.log("url set to: ", url);
        // console.log("Document written with ID: ", docRef.id);
        dispatch({ type: "hideLoading" });
        addEvent(docRef.id, post.pages[index].imageURL, sharedInfo);
        await sendNotification(user?.uid, sharedInfo?.sharedById, 'orderCreated', postId, docRef?.id, page)
        //navigate(`/profile/${user.id}`);
        // need to also write this order id to the post with user info and date, show in same popup box above input elements
        if(!alreadyLiked) likePage()
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
        toast.error("Something went wrong");
      });
  };

  const likePage = () => {
    let user = currentUser;
    let updatedLikes = post.likes;
    const pageNumber = index + 1;

    const mapKey = uuidv4();
    updatedLikes.push({
      mapKey: mapKey,
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      page: pageNumber,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    });

    let imageURL = "";
    const mapKey2 = uuidv4();

    for (let i = 0; i < post.pages.length; i++) {
      if (post.pages[i].page === pageNumber) {
        // console.log("Page url found for LikedPage data");
        imageURL = post.pages[i].imageURL;
      }
    }
    // add post page data to user collection, likedPages
    addDoc(collection(db, "users", user.uid, "likedPages"), {
      mapKey: mapKey2,
      userId: user.uid,
      userEmail: user.email,
      userDisplayName: user.displayName,
      postId: post.id,
      postDesc: post.description,
      postCreatedById: post.userId,
      postCreatedByEmail: post.userEmail,
      postCreatedByDisplayName: post.userDisplayName,
      page: pageNumber,
      imageURL: imageURL,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    })
      .then(() => {
        //toast.success("LikedPage data added successfully");
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
        toast.error("Something went wrong");
      });

    setDoc(doc(db, "posts", post.id), {
      ...post,
      likes: updatedLikes,
    })
      .then(() => {
        getData();
        //toast.success("Page liked successfully");
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const unlikePage = () => {
    let user = currentUser;
    let updatedLikes = post.likes;
    let filteredLikes = [];
    const pageNumber = index + 1;

    for (let i = 0; i < updatedLikes.length; i++) {
      if (updatedLikes[i].id !== user.uid) {
        filteredLikes.push(updatedLikes[i]);
      }
    }
    for (let i = 0; i < updatedLikes.length; i++) {
      if (
        updatedLikes[i].id === user.uid &&
        updatedLikes[i].page !== pageNumber
      ) {
        filteredLikes.push(updatedLikes[i]);
      }
    }
    
    getDocs(
      query(
        collection(db, "users", user.uid, "likedPages"),
        where("postId", "==", post?.id),
        where("page", "==", pageNumber)
      )
    ).then(result => {
      const doc = result.docs[0]
      deleteDoc(doc.ref).catch(err => toast.error("An error occurred"))
    })
     .catch(err => toast.error("An error occurred"))

    setDoc(doc(db, "posts", post.id), {
      ...post,
      likes: filteredLikes,
    }).then(() => {
        getData();
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const buyOrUnbuyPage = () => {
    let user = currentUser;
    let updatedBuys = post.buys;
    let filteredBuys = [];
    const pageNumber = index + 1;

    if (alreadyBuy) {
      for (let i = 0; i < updatedBuys.length; i++) {
        if (updatedBuys[i].id !== user.uid) {
          filteredBuys.push(updatedBuys[i]);
        }
      }
      for (let i = 0; i < updatedBuys.length; i++) {
        if (
          updatedBuys[i].id === user.uid &&
          updatedBuys[i].page !== pageNumber
        ) {
          filteredBuys.push(updatedBuys[i]);
        }
      }
      updatedBuys = filteredBuys;
      //console.log("Updated buys: ", updatedBuys);
    } else {
      const mapKey = uuidv4();
      updatedBuys.push({
        mapKey: mapKey,
        id: user.uid,
        email: user.email,
        displayName: user.displayName,
        page: pageNumber,
        createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      });
    }

    setDoc(doc(db, "posts", post.id), {
      ...post,
      buys: updatedBuys,
    })
      .then(() => {
        getData();
        //setAlreadyOrdered(true);
        //toast.success("Page buy request updated successfully");
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  };

  const urgentOrUnurgentPage = () => {
    let user = currentUser;
    let updatedUrgents = post.urgents;
    let filteredUrgents = [];
    const pageNumber = index + 1;

    if (alreadyUrgent) {
      for (let i = 0; i < updatedUrgents.length; i++) {
        if (updatedUrgents[i].id !== user.uid) {
          filteredUrgents.push(updatedUrgents[i]);
        }
      }
      for (let i = 0; i < updatedUrgents.length; i++) {
        if (
          updatedUrgents[i].id === user.uid &&
          updatedUrgents[i].page !== pageNumber
        ) {
          filteredUrgents.push(updatedUrgents[i]);
        }
      }
      updatedUrgents = filteredUrgents;
      //console.log("Updated urgents: ", updatedUrgents);
    } else {
      const mapKey = uuidv4();
      updatedUrgents.push({
        mapKey: mapKey,
        id: user.uid,
        email: user.email,
        displayName: user.displayName,
        page: pageNumber,
        createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      });
    }

    setDoc(doc(db, "posts", post.id), {
      ...post,
      urgents: updatedUrgents,
    })
      .then(() => {
        getData();
        //toast.success("Page urgent request updated successfully");
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  };

  const addComment = () => {
    dispatch({ type: "showLoading" });
    
    const comment = commentText
    setCommentText('')
    
    let updatedComments = post.comments;
    const user = currentUser;
    const pageNumber = index + 1;
    const mapKey = uuidv4();
    updatedComments.push({
      mapKey: mapKey,
      id: user.uid,
      //username: currentUser.forum_username,
      email: user.email,
      page: pageNumber,
      commentText: comment,
      displayName: user.displayName,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      parentId: null
    });

    setDoc(doc(db, "posts", post.id), {
      ...post,
      comments: updatedComments,
    }).then(async () => {
        await sendNotification(currentUser?.uid, post?.userId, 'newComment', post?.id, null, pageNumber, mapKey)
        setCommentText("");
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  };

  const replyComment = (parentId) => {
    setLoadingReply(true)
    
    const comment = replyText
    setReplyText('')

    let postId = params.id.split("#")[0];
    const parentComment = post?.comments?.find(comment => comment.mapKey === parentId);

    if (parentComment && replyText.trim() !== '') {
      const postRef = doc(db, 'posts', postId);

      const user = currentUser;
      const pageNumber = index + 1;
      const mapKey = uuidv4();

      const newComments = [
        ...post?.comments,
        {
          mapKey: mapKey,
          id: user.uid,
          //username: currentUser.forum_username,
          email: user.email,
          page: pageNumber,
          commentText: comment,
          displayName: user.displayName,
          createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
          parentId: parentId
        }
      ]

      setDoc(postRef, {
        ...post,
        comments: newComments,
      })
        .then(async () => {
          //getData();
          // toast.success("Comment posted successfully");
          await sendNotification(currentUser?.uid, post?.userId, 'replyComment', post?.id, null, pageNumber, mapKey)
          setReplyText("");
          setLoadingReply(false)
        })
        .catch(() => {
          toast.error("An error occurred");
        });

      setCommentText('')
    }
  }

  const upHand = () => {
    if(handsUp) return

    let onHold = post.onHold || [];
    const pageNumber = index + 1;

    const mapKey = uuidv4();
    onHold?.push({
      mapKey: mapKey,
      id: currentUser.uid,
      email: currentUser.email,
      displayName: currentUser.displayName,
      page: pageNumber,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    });

    const page = post.pages.find(page => page.page === pageNumber)

    // add post page data to user collection, likedPages
    addDoc(collection(db, "users", currentUser.uid, "onHolds"), {
      mapKey: mapKey,
      userId: currentUser.uid,
      userEmail: currentUser.email,
      userDisplayName: currentUser.displayName,
      postId: post.id,
      postDesc: post.description,
      postCreatedById: post.userId,
      postCreatedByEmail: post.userEmail,
      postCreatedByDisplayName: post.userDisplayName,
      page: pageNumber,
      imageURL: page.imageURL,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    })
      .then(() => {
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
        toast.error("Something went wrong");
      });

    setDoc(doc(db, "posts", post.id), {
      ...post,
      onHold: onHold,
    })
      .then(() => {
        getData();
        //toast.success("Page liked successfully");
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const downHand = () => {
    if(!handsUp) return

    const pageNumber = index + 1

    getDocs(
      query(
        collection(db, "users", currentUser.uid, "onHolds"),
        where("postId", "==", post?.id),
        where("page", "==", pageNumber)
      )
    ).then(result => {
      const doc = result.docs[0]
      deleteDoc(doc.ref).catch(err => toast.error("An error occurred"))
    })
     .catch(err => toast.error("An error occurred"))

    setDoc(doc(db, "posts", post.id), {
      ...post,
      onHold: post.onHold?.filter(obj => obj.id !== currentUser.uid && obj.page !== pageNumber),
    }).then(() => {
        getData();
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const getPageSelection = () => {
    const urlText = window.location.href;
    //console.log("urlText= ", urlText);
    //console.log("window.location.pathname= ", window.location.pathname);

    if (urlText.includes("#") === false) {
      let slide = 0;

      //console.log("slide= ", slide);
    } else {
      let slide = urlText.substring(urlText.lastIndexOf("#"));
      //console.log(slide);
      let removeHash = slide.replace("#", "");
      slide = removeHash;
      slide = parseInt(slide) - 1;
      //console.log("slide= ", slide);
      setIndex(slide);
    }
  };

  const getPageURLset = () => {
    const urlSet = [];
    if (post) {
      for (let index = 0; index < post.pages.length; index++) {
        const url = post.pages[index].imageURL;
        urlSet.push(url);
      }
      setUrlSet(urlSet);
      // return urlSet;
    }
  };

  const goToGridView = () => {
    if (post) navigate(`/post-s-grid/${post.id}`, {state: {scrollY: location?.state?.scrollY}});
  };

  useEffect(() => {
    if(post){
      const postRef = doc(db, 'posts', post.id)
      const unsubscribe = onSnapshot(postRef, (snapshot) => {
        getData()
      });
      return () => unsubscribe()
    }
  }, [post])

  useEffect(() => {
    getPageSelection();
    getData();
  }, [params]);

  useEffect(() => {
    getPost();
    getPageURLset();
  }, [post, index]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        <div className="col-12 pb-5 mb-5">
          {" "}
          {post && urlSet && (
            <Card className="mb-3">
              <Card.Header as="h5">
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                  <div style={{position: 'absolute', left: 0}}>
                    <IoCaretBackSharp
                      size={17}
                      color="dark"
                      style={{ cursor: "pointer" }}
                      onClick={goToGridView}
                    />
                  </div>
                  {post.description}
                </div>
              </Card.Header>

              <Card.Body>
                <span className="text-center">
                  {" "}
                  <Card.Title as="h6">
                    <span className="text-secondary">page </span>
                    <span className="text-muted">{index + 1}</span>
                  </Card.Title>
                </span>

                <>
                  {" "}
                  {/* <div className="d-flex justify-content-center pb-1 mb-3">
                    <MDBBadge
                      className="p-2 mx-3 shadow-sm"
                      // style={{ cursor: "crosshair" }}
                      color="white"
                    >
                      <div className="row">
                        <div className="col mx-2">
                          <IoCaretBackSharp
                            size={18}
                            color="#332D2D"
                            style={{ cursor: "pointer" }}
                            onClick={goToGridView}
                          />
                        </div>
                      </div>
                    </MDBBadge>
                  </div> */}
                  <div className="w-100 pt-1">
                    {" "}
                    <div className="d-block text-center">
                      <Carousel
                        variant="dark"
                        touch={true}
                        keyboard={true}
                        slide={true}
                        fade={true}
                        interval={null}
                        indicators={false}
                        activeIndex={index}
                        onSelect={handleSelect}
                      >
                        {post.pages.map((page) => {
                          return (
                            <Carousel.Item key={page.page}>
                              <img
                                className="img-fluid rounded"
                                style={{ maxHeight: "60vh" }}
                                src={page.imageURL}
                                alt="carousel slide"
                                onClick={() => setLgShow(true)}
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                      {/* </QuickPinchZoom> */}
                    </div>
                  </div>
                  {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}
                  <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        <span className="text-muted h5">
                          {post.description}
                        </span>
                        <span className="text-secondary h5">
                          - page {index + 1}
                        </span>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {" "}
                      <div className="d-flex justify-content-center align-items-center">
                        {" "}
                        <QuickPinchZoom
                          onUpdate={onUpdate}
                          doubleTapToggleZoom={true}
                          doubleTapZoomOutOnMaxScale={true}
                          draggableUnZoomed
                        >
                          <img
                            ref={imgRef}
                            alt="img"
                            src={urlSet[index]}
                            className="img-fluid rounded"
                            style={{
                              cursor: "pointer",
                              maxHeight: "80vh",
                              width: "100",
                            }}
                          />
                        </QuickPinchZoom>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex mt-3 pt-2 justify-content-center pb-1 pb-sm-2">
                    <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
                      <AiFillHeart
                        size={23}
                        color={alreadyLiked ? "red" : "gray"}
                        onClick={alreadyLiked ? unlikePage : likePage}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
                      <FaHandPaper
                        size={23}
                        color={handsUp ? "#FFAB01" : "gray"}
                        onClick={handsUp ? downHand : upHand}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
                      <OverlayTrigger
                        rootClose={true}
                        trigger="click"
                        placement="top"
                        overlay={
                          <Popover id={"popover-positioned-top"}>
                            <Popover.Header
                              as="h3"
                              className="bg-dark text-light text-center"
                              style={{
                                fontWeight: "normal",
                                fontSize: 18,
                                letterSpacing: 1,
                              }}
                            >
                              {alreadyOrdered ? inquired : inquire}
                            </Popover.Header>
                            <Popover.Body>
                              <ul className="p-0">
                                {events.map((event) => {
                                  return (
                                    <li
                                      key={event.mapKey}
                                      className="lh-lg"
                                      style={{
                                        listStyle: "none",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                      }}
                                    >
                                      <h6
                                        className="pb-2"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 15,
                                        }}
                                      >
                                        {/* {event.orderedByDisplayName}  */}
                                        Inquiry Sent
                                      </h6>
                                      <h6
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {/* {event.orderedByDisplayName}  */}
                                        Inquired for potential acquisition
                                      </h6>
                                      <h6
                                        className="text-primary pt-1"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {/* {event.orderedByDisplayName}  */}
                                        Pending seller response
                                      </h6>

                                      {event.urgentChecked ? (
                                        <h6 className="mt-1">
                                          <AiFillThunderbolt
                                            size={18}
                                            //onClick={urgentOrUnurgentPage}
                                            color="orange"
                                            // style={{ cursor: "pointer" }}
                                          />{" "}
                                          <span
                                            className="mx-2 text-danger"
                                            style={{
                                              fontWeight: "normal",
                                              fontSize: 13,
                                            }}
                                          >
                                            {/* {event.orderedByDisplayName}  */}
                                            Urgent request
                                          </span>
                                        </h6>
                                      ) : (
                                        ""
                                      )}

                                      {event.orderText ? (
                                        <h6 className="mt-1">
                                          <BiCommentAdd
                                            size={18}
                                            //onClick={urgentOrUnurgentPage}
                                            color="gray"
                                            // style={{ cursor: "pointer" }}
                                          />{" "}
                                          <span
                                            className="mx-2 text-dark"
                                            style={{
                                              fontWeight: "normal",
                                              fontSize: 13,
                                            }}
                                          >
                                            "{event.orderText}"
                                          </span>
                                        </h6>
                                      ) : (
                                        ""
                                      )}

                                      <div
                                        className=""
                                        style={{
                                          fontWeight: "light",
                                          fontSize: 13,
                                          letterSpacing: 1,
                                        }}
                                      >
                                        {event.createdOn.split(" ")[0]}
                                      </div>

                                      <MDBBadge
                                        // color={alreadyOrdered ? "secondary" : "dark"}
                                        color="dark"
                                        notification
                                        className="d-grid w-100 mt-3 rounded text-light text-center shadow-sm"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 16,
                                          cursor: "pointer",
                                          border: "1px solid gray",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/order-s-status/${event.orderId}`
                                          )
                                        }
                                      >
                                        <div
                                        // className={
                                        //   alreadyOrdered ? "danger" : "secondary"
                                        // }
                                        >
                                          {/* {buys?.length} */}
                                          {/* {alreadyOrdered ? inquired : inquire} */}
                                          Check Status
                                        </div>
                                      </MDBBadge>
                                    </li>
                                  );
                                })}
                                <li
                                  className="lh-lg"
                                  style={{
                                    listStyle: "none",
                                    fontWeight: "normal",
                                    fontSize: 14,
                                  }}
                                >
                                  {alreadyOrdered ? (
                                    ""
                                  ) : (
                                    <div>
                                      <h6
                                        className=""
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 15,
                                        }}
                                      >
                                        Acquisition Request
                                      </h6>
                                      <h6
                                        className="pt-2 pb-2"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 14,
                                        }}
                                      >
                                        Notes:
                                      </h6>
                                      <div className="">
                                        <textarea
                                          className=""
                                          rows="2"
                                          value={orderText}
                                          onChange={(e) =>
                                            setOrderText(e.target.value)
                                          }
                                        ></textarea>
                                      </div>

                                      <div className="form-check form-switch ">
                                        <span
                                          className="align-middle"
                                          style={{
                                            display: "inline-block",
                                          }}
                                        >
                                          {" "}
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            checked={urgentChecked}
                                            onChange={() =>
                                              setUrgentChecked(!urgentChecked)
                                            }
                                          />
                                        </span>
                                        <span
                                          className="align-middle pt-1"
                                          style={{
                                            display: "inline-block",
                                          }}
                                        >
                                          {" "}
                                          <label
                                            className="form-check-label mx-2"
                                            for="flexSwitchCheckDefault"
                                          >
                                            <h6
                                              style={{
                                                fontWeight: "normal",
                                                fontSize: 14,
                                              }}
                                            >
                                              {urgentChecked
                                                ? "Noted urgent"
                                                : "Note as urgent"}
                                            </h6>
                                          </label>
                                        </span>
                                      </div>

                                      <MDBBadge
                                        color={
                                          alreadyOrdered ? "secondary" : "dark"
                                        }
                                        notification
                                        className="d-grid w-100 mt-3 rounded text-light text-center shadow-sm"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 16,
                                          cursor: "pointer",
                                          border: "1px solid gray",
                                        }}
                                        onClick={addOrder}
                                      >
                                        <div
                                          className={
                                            alreadyOrdered
                                              ? "danger"
                                              : "secondary"
                                          }
                                        >
                                          {/* {buys?.length} */}
                                          {/* {loading ? <Loader /> : inquired} */}
                                          {loading ? (
                                            <Loader />
                                          ) : alreadyOrdered ? (
                                            inquired
                                          ) : (
                                            submit
                                          )}
                                        </div>
                                      </MDBBadge>
                                    </div>
                                  )}
                                </li>
                              </ul>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <div>
                          <AiFillDollarCircle
                            size={23}
                            color={alreadyBuy ? "blue" : "gray"}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>

                    {/* <div className="d-flex text-center align-items-center mx-4 ">
                      <BsFlagFill
                        size={20}
                        color={alreadyFlag ? "#5bc0de" : "gray"}
                        // onClick={flagOrUnflagPage}
                        // style={{ cursor: "pointer" }}
                      />
                    </div> */}

                    <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
                      <BiCommentAdd
                        size={23}
                        color={alreadyComment ? "pink" : "gray"}
                      />

                      <OverlayTrigger
                        rootClose={true}
                        trigger="click"
                        placement="top"
                        overlay={
                          <Popover id={"popover-positioned-top"} style={{minWidth: '40%'}}>
                            <Popover.Header
                              as="h3"
                              className="bg-dark text-light text-center"
                              style={{
                                fontWeight: "normal",
                                fontSize: 18,
                                letterSpacing: 1,
                              }}
                            >
                              {"Comments"}
                            </Popover.Header>
                            <Popover.Body>
                              <ul className="p-2" style={{maxHeight: '500px', overflowY: 'auto'}}>
                                {comments?.filter(comment => !comment.parentId).map((comment) => {
                                  return (
                                    <li
                                      key={comment?.mapKey}
                                      className="lh-lg"
                                      style={{
                                        listStyle: "none",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                      }}
                                    >
                                      <h6
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 13,
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {comment?.displayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{comment?.createdOn?.split(" ")[0]}</span>
                                      </h6>
                                      <h6
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {comment?.commentText}
                                      </h6>
                                      <h6
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 13,
                                        }}
                                      >
                                        <a style={{cursor: 'pointer'}} onClick={() => setReplyTo(replyTo === null || replyTo !== comment.mapKey ? comment.mapKey : null)}>
                                          {
                                            comments?.filter(comm => comm.parentId === comment.mapKey).length === 0
                                              ? <><FaReply style={{marginRight: '3px'}}/> Reply</>
                                              : <><FaReply style={{marginRight: '3px'}}/> Replies ({comments?.filter(comm => comm.parentId === comment.mapKey).length})</>
                                          }
                                        </a>
                                      </h6>
                                      {/*<button onClick={() => replyComment(comment.id)}>Reply</button>
                                      {comment.parentId && (
                                        <p>Responding to: {comments?.find(c => c.id === comment.parentId).text}</p>
                                      )}*/}
                                      {/*<div
                                        className=""
                                        style={{
                                          fontWeight: "light",
                                          fontSize: 13,
                                          letterSpacing: 1,
                                        }}
                                      >
                                        {comment?.createdOn?.split(" ")[0]}
                                      </div>*/}
                                      {replyTo === comment.mapKey && (
                                          <>
                                            {comments?.filter(comm => comm.parentId === comment.mapKey).map(com => {
                                              return (
                                                <div style={{marginLeft: '30px', marginBottom: '15px'}}>
                                                  <li
                                                    key={com?.mapKey}
                                                    className="lh-lg"
                                                    style={{
                                                      listStyle: "none",
                                                      fontWeight: "normal",
                                                      fontSize: 16,
                                                    }}
                                                  >
                                                    <h6
                                                      style={{
                                                        fontWeight: "bold",
                                                        fontSize: 13,
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                      }}
                                                    >
                                                      {com?.displayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{com?.createdOn?.split(" ")[0]}</span>
                                                    </h6>
                                                    <h6
                                                      style={{
                                                        fontWeight: "normal",
                                                        fontSize: 13,
                                                      }}
                                                    >
                                                      {com?.commentText}
                                                    </h6>
                                                  </li>
                                                  
                                                  </div>
                                                )
                                            })}
                                            <div style={{display: 'flex', marginLeft: '30px', alignItems: 'center', marginBottom: '15px'}}>
                                              <textarea
                                                style={{width: '100%', borderRadius: '10px', resize: 'none'}}
                                                rows="2"
                                                value={replyText}
                                                onChange={(e) => {
                                                  setReplyText(e.target.value)
                                                }}
                                              />
                                              {loadingReply ? <Loader/> : <a style={{marginLeft: '15px', fontSize: 13, fontWeight: 'bold', cursor: 'pointer'}} onClick={() => replyComment(comment.mapKey)}>Send</a>}
                                            </div>
                                          </>
                                        )
                                      }   
                                      <hr />
                                    </li>
                                  );
                                })}
                              </ul>
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <textarea
                                  style={{width: '100%', borderRadius: '10px', resize: 'none', marginRight: '15px', height: '70px', fontSize: 16}}
                                  rows="2"
                                  value={commentText}
                                  onChange={(e) => {
                                    setCommentText(e.target.value)
                                  }}
                                />
                                {loading ? <Loader/> : <a style={{fontSize: 13, fontWeight: 'bold', cursor: 'pointer'}} onClick={addComment}>Send</a>}
                              </div>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <MDBBadge
                          color={alreadyComment ? "dark" : "secondary"}
                          notification
                          pill
                          className="mx-1"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className={alreadyComment ? "dark" : "secondary"}
                          >
                            {comments?.filter(comment => comment.parentId === null).length}
                          </div>
                        </MDBBadge>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center" style={{whiteSpace: "pre-wrap", paddingTop: '10px'}}>
                    {post.pages[index].text}
                  </div>
                </>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
}

export default PostDescSpage;
