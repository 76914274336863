import React, { useState, useEffect, useRef, useCallback } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { collection, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from '../context/AuthContext'
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import OutgoingCard from "../components/OutgoingCard";
import { Button, Col, Row } from "react-bootstrap";


function ProfileGrid() {

  const navigate = useNavigate()

  const { currentUserRealTime: currentUser } = useAuth()

  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [itemsPerPage] = useState(6)
  const [hasMore, setHasMore] = useState(true)
  const observer = useRef()

  const getPosts = useCallback(async () => {
    if(loading || !hasMore) return;
    setLoading(true)

    try {
      const lastDoc = posts.length > 0 ? posts[posts.length - 1].docRef : null
      let q = query(
        collection(db, 'posts'),
        where('userId', '==', currentUser?.uid),
        where('archived', '==', false),
        orderBy('createdAt', 'desc'),
        limit(itemsPerPage),
      )

      if(lastDoc){
        q = query(q, startAfter(lastDoc))
      }

      const querySnapshot = await getDocs(q)

      if(!querySnapshot.empty){
        const newData = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id, docRef: doc}))
        setPosts(prev => prev.concat(newData))
        if(querySnapshot.size < itemsPerPage) setHasMore(false)
      }else{
        setHasMore(false)
      }

    } catch (error) {
      console.error('Error fetching data:', error)
    } finally { setLoading(false)}

  }, [posts, loading, hasMore, currentUser])

  const lastElementRef = useCallback(node => {
    if(loading) return;
    if(observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore){
        getPosts()
      }
    })
    if(node) observer.current.observe(node)
  }, [loading, hasMore, getPosts])

  useEffect(() => {
    getPosts()
  }, [])

  return (
    <DefaultLayout>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span style={{fontSize: '20px', fontWeight: 'bold'}}>Outgoing Capsules</span>
          <span style={{fontSize: '14px', color: '#262626'}}>Here are capsules you have uploaded to romulus.art. These capsules are private until you share them with your connections.</span>
        </div>
        <Button
          variant="outline-secondary"
          style={{color: 'black', borderColor: '#D8D8D8', borderRadius: '0'}}
          onClick={() => navigate('/archived-shares')}
        >
          View Archived
        </Button>
      </div>
      <Row style={{marginTop: '20px'}}>
      {posts.map((post) => (
        <Col lg={4} md={6} sm={12} key={post.id}>
          <OutgoingCard
            postId={post.id}
            setPosts={setPosts}
            getPosts={getPosts}
          />
        </Col>
      ))}
      </Row>
      {loading && <Loader/>}
      <div ref={lastElementRef}/>
    </DefaultLayout>
  );
}

export default ProfileGrid;
