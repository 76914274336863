import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FaComments } from "react-icons/fa";
import { db } from "../firebaseConfig";
import moment from "moment";
import { useAuth } from "../context/AuthContext";

function AcquisitionChat({ order }) {

  const { currentUser } = useAuth()

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState()

  const chatContainerRef = useRef(null)

  const sendMessage = () => {
    const chatsRef = collection(db, "orders", order?.id, "chat")
    addDoc(chatsRef, {
      from: currentUser?.uid,
      message: message,
      createdAt: moment().format(),
      seen: false,
    })
      .then(() => setMessage(""))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    if(order){
      const chatCollection = collection(db, "orders", order?.id, "chat")
      const unsubscribe = onSnapshot(chatCollection, (snapshot) => {
        setMessages(
          snapshot.docs
            .map(doc => ({
              ...doc.data(),
              id: doc.id,
            }))
        )
      });
      return () => unsubscribe()
    }
  }, [order])

  useEffect(() => {
    if (chatContainerRef.current && messages.length > 0) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [order])

  return (
    <Popover style={{width: '100%', maxWidth: 'none', zIndex: 0}} arrowProps={{style: {display: 'none'}}}>
      <Popover.Header
        as="h3"
        className="bg-dark text-light text-center"
        style={{
          fontWeight: "normal",
          fontSize: 18,
          letterSpacing: 1,
        }}
      >
        Chat
      </Popover.Header>
      <Popover.Body>
        <ul style={{height: '250px', overflowY: 'auto', padding: 0, flexDirection: 'column-reverse'}} ref={chatContainerRef}>
          {messages
            .sort((a,b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf())
            .map(message => (
              <div
                key={message.id}
                style={{
                  display: 'flex',
                  justifyContent: currentUser?.uid === message.from ? 'flex-end' : 'flex-start',
                  marginBottom: '7px',
                  wordBreak: 'break-all',
                  marginLeft: currentUser?.uid === message.from && '30px',
                  marginRight: currentUser?.uid !== message.from && '30px'
                }}
              >
                <li
                  style={{
                    listStyle: "none",
                    fontWeight: "normal",
                    fontSize: 14,
                    backgroundColor: currentUser?.uid === message.from ? 'var(--bs-blue)' : 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    padding: '3px 7px',
                    width: 'fit-content',
                    color: currentUser?.uid === message.from && 'white'
                  }}
                >
                  {message.message}
                </li>
              </div>
          ))}
        </ul>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <textarea
            style={{width: '100%', borderRadius: '10px', resize: 'none', padding: '5px'}}
            rows="2"
            value={message}
            onChange={async (e) => {
              await setMessage(e.target.value)
            }}
          />
          <a
            onClick={sendMessage}
            style={{
              marginLeft: '15px',
              fontSize: 13,
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
          >
            Send
          </a>
        </div>
      </Popover.Body>
    </Popover>

  );
}

export default AcquisitionChat;
