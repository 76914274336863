import { doc, updateDoc } from "firebase/firestore";
import DefaultLayout from "../components/DefaultLayout";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";

const WhoAreYou = () => {
  
  const { currentUser } = useAuth()

  const navigate = useNavigate()

  const selectRole = (role) => {
    updateDoc(doc(db, 'users', currentUser?.uid), {
      role: role,
      hasSub: role !== 'seller'
    }).then(() => {
      navigate('/')
    }).catch(err => {
      console.log(err)
      toast.error("Something went wrong")
    })
  }

  return (
    <DefaultLayout>
      {" "}
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        <div className="col-12 col-lg-10 col-xl-9 pb-5 mb-5">
          <div className="pb-5">
            {" "}
            <Card className="mb-5">
              <span className="text-center">
                <Card.Header as="h4">Who Are You</Card.Header>
              </span>
              <Card.Body>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
                  <Button onClick={() => selectRole('seller')} variant="outline-dark" style={{marginBottom: '20px', width: '50%'}}>Seller</Button>
                  <Button onClick={() => selectRole('buyer')} variant="outline-dark" style={{marginBottom: '20px', width: '50%'}}>Buyer</Button>
                  <Button onClick={() => selectRole('vendor')} variant="outline-dark" style={{width: '50%'}}>Vendor</Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default WhoAreYou;
