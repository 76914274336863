import React from "react";
import { Modal } from "react-bootstrap";

function RequestsModal({show, setShow, requests, title}) {

  return (
    <Modal
      size="lg"
      show={show !== null}
      onHide={() => setShow(null)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen='sm-down'
    >
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: '18px'}}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{minHeight: '300px', display: 'flex', flexDirection: 'column', maxHeight: '90vh'}}>
        {requests.length === 0 ? 
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flex: 1}}>
            <span style={{color: '#7D7D7D', fontSize: '12px'}}>No Requests yet</span>
          </div> : 
          <ul style={{overflowY: 'auto', width: '100%', flex: 1, marginLeft: '-30px'}}>
            {requests.map((request) => {
            return (
              <li
                key={request?.mapKey}
                className="lh-lg"
                style={{
                  listStyle: "none",
                  fontWeight: "normal",
                  fontSize: 16,
                }}
              >
                <h6
                  style={{
                    fontWeight: "bold",
                    fontSize: 13,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {request?.displayName} {title === 'Share Requests' ? 'has asked to share this' : 'has requested a call'}.
                </h6>
                <h6
                  style={{
                    fontWeight: "bold",
                    fontSize: 13,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {request?.createdOn?.split(" ")[0]}
                </h6>
                <hr />
              </li>
            );
          })}
          </ul>
          }
      </Modal.Body>
    </Modal>
  );
}

export default RequestsModal;
