import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import Loader from '../Loader';
import DefaultLayout from '../DefaultLayout';

const ProtectedRoute = ({ children }) => {

  const location = useLocation()

  const { currentUserRealTime, currentUser } = useAuth()

  if(!currentUser) return <Navigate to="/login" state={{from: location}} replace/>

  if(currentUserRealTime?.role === null) return <Navigate to="/whoareyou" state={{from: location}} replace/>
  
  if(currentUserRealTime?.role === 'seller' && !currentUserRealTime?.hasSub) return <Navigate to="/plans" state={{from: location}} replace/>

  if(!!currentUserRealTime?.role) return children

  return <DefaultLayout><Loader/></DefaultLayout>

};

export default ProtectedRoute;
