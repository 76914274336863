import React from "react";
import { FeedbackOverviewListItem } from "../molecules/FeedbackOvervireListItem";

export const FeedbackOverview = () => {
  return (
    <div className="bg-main rounded-xl shadow p-2 md:flex md:flex-col md:justify-center">
      <h2 className="text-white  font-secondary mb-4 text-lg font-[400] text-center md:!text-left ">
        79 Capsules Uploaded
      </h2>
      <h3 className="text-white mb-3 text-[16px] font-[400] font-primary italic">
        Total Feedback Overview
      </h3>
      <div className="grid grid-cols-1 gap-[12px] w-48">
        <FeedbackOverviewListItem
          count={32}
          title={"Buy Request"}
          icon={"money"}
        ></FeedbackOverviewListItem>
        <FeedbackOverviewListItem
          count={2}
          title={"Likes"}
          icon={"heart"}
        ></FeedbackOverviewListItem>
        <FeedbackOverviewListItem
          count={32}
          title={"Hold Request"}
          icon={"hand"}
        ></FeedbackOverviewListItem>
        <FeedbackOverviewListItem
          count={12}
          title={"Comments"}
          icon={"dialog"}
        ></FeedbackOverviewListItem>
      </div>
    </div>
  );
};
