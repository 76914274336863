import React, { useState, useCallback, useRef, useEffect } from "react";
import { Carousel, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useDispatch } from "react-redux";
import { MDBBadge } from "mdb-react-ui-kit";
import {
  AiFillHeart,
  AiFillDollarCircle,
  AiFillThunderbolt,
} from "react-icons/ai";
import { FaChevronLeft, FaChevronRight, FaHandPaper, FaReply } from "react-icons/fa"
import { BiCommentAdd } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import InquirePopover from "./InquirePopover";
import CommentsPopover from "./CommentsPopover";
import { orange } from "@mui/material/colors";
import Loader from "./Loader";
import { sendNotification } from "../utils/notification";

function OutgoingPreviewModal({ post, page, show, setShow, getData }) {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { currentUser } = useAuth()

  const imgRef = useRef()

  const [index, setIndex] = useState(parseInt(page.page))
  const [pages, setPages] = useState([])
  const [likes, setLikes] = useState([])
  const [buys, setBuys] = useState([])
  const [comments, setComments] = useState([])
  const [onHolds, setOnHolds] = useState([])

  const [alreadyLiked, setAlreadyLiked] = useState(false)
  const [loadingLike, setLoadingLike] = useState(false)
  const [alreadyBought, setAlreadyBought] = useState(false)
  const [alreadyCommented, setAlreadyCommented] = useState(false)
  const [handsUp, setHandsUp] = useState(false)
  const [loadingHand, setLoadingHand] = useState(false)

  const [replyTo, setReplyTo] = useState()
  const [replyText, setReplyText] = useState("")
  const [loadingReply, setLoadingReply] = useState(false)


  const [showInquirePopover, setShowInquirePopover] = useState(false)
  const [showCommentsPopover, setShowCommentsPopover] = useState(false)

  const getPages = () => {
    getDocs(query(collection(db, 'posts', post?.id, 'pages'), orderBy('page', 'asc'))).then(res => {
      setPages(res.docs.map(docu => ({...docu.data(), id: docu.id})))
    })
  }

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const replyComment = (parentId) => {
    setLoadingReply(true)
    const comment = replyText
    setReplyText('')
    const parentComment = post?.comments?.find(comment => comment.mapKey === parentId);

    if (parentComment) {
      const postRef = doc(db, 'posts', post.id);

      const pageNumber = index + 1;
      const mapKey = uuidv4();

      const newComments = [
        ...post?.comments,
        {
          mapKey: mapKey,
          id: currentUser.uid,
          //username: currentUser.forum_username,
          email: currentUser.email,
          page: pageNumber,
          commentText: comment,
          displayName: currentUser.displayName,
          createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
          parentId: parentId
        }
      ]

      setDoc(postRef, {
        ...post,
        comments: newComments,
      })
        .then(async () => {
          //getData();
          // toast.success("Comment posted successfully");
          await sendNotification(currentUser?.uid, parentComment?.id, 'replyComment', post?.id, null, pageNumber, mapKey)
          setReplyText("");
          setLoadingReply(false)
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    }
  }

  const getAlreadyLiked = () => {
      if (post.likes) {
        let updatedLikes = post.likes;
        let filteredLikes = [];
        const pageNumber = index;

        for (let i = 0; i < updatedLikes.length; i++) {
          if (updatedLikes[i].page === pageNumber) {
            filteredLikes.push(updatedLikes[i]);
          }
        }

        setLikes(filteredLikes)

        if (filteredLikes.length > 0) {
          setAlreadyLiked(true);
        } else {
          setAlreadyLiked(false);
        }
      }
  }

  const getAlreadyBought = () => {
    if (post.events) {
      let updatedEvents = post.events;
      let filteredEvents = [];
      const pageNumber = index;

      for (let i = 0; i < updatedEvents.length; i++) {
        if (parseInt(updatedEvents[i].pageNumber) === pageNumber) {
          filteredEvents.push(updatedEvents[i]);
        }
      }

      setBuys(filteredEvents)
      console.log(filteredEvents)

      if (filteredEvents.length > 0) {
        setAlreadyBought(true);
      } else {
        setAlreadyBought(false);
      }
    }
  }

  const getAlredyCommented = () => {
    if (post.comments) {
      const filteredComments = [];

      for (let i = 0; i < post.comments?.length; i++) {
        if (
          post.comments[i].page === index &&
          post.comments[i].id === currentUser.uid ||
          post.comments[i].parentId !== null
        ) {
          filteredComments.push(post.comments[i]);
        }
      }
      setComments(filteredComments);
      if (filteredComments.find((user) => user.id === currentUser.uid)) {
        setAlreadyCommented(true);
      } else {
        setAlreadyCommented(false);
      }
    }
  }

  const getAlreadyOnHold = () => {
    if (post.onHold) {
      let updatedOnholds = post.onHold;
      let filteredOnHolds = [];
      const pageNumber = index;

      for (let i = 0; i < updatedOnholds.length; i++) {
        if (updatedOnholds[i].page === pageNumber) {
          filteredOnHolds.push(updatedOnholds[i]);
        }
      }

      setOnHolds(filteredOnHolds)

      if (filteredOnHolds.length > 0) {
        setHandsUp(true);
      } else {
        setHandsUp(false);
      }
    }
  }

  useEffect(() => {
    getPages()
    getAlreadyLiked()
    getAlreadyBought()
    getAlredyCommented()
    getAlreadyOnHold()
  }, [post, index])

  return (
    <Modal
      size="lg"
      show={show !== null}
      onHide={() => setShow(null)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <span className="text-muted h5">{post.description} </span>
          <span className="text-secondary h5">- page {page.page}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeight: '85vh', overflowY: 'auto'}}>
        {" "}
        <div className="d-flex justify-content-center align-items-center" style={{width: '100%'}}>
          <div className="d-flex align-items-center">
            <FaChevronLeft size={24} onClick={() => {if(index > 0) setIndex(prev => prev-1)}} style={{cursor: 'pointer', color: index <= 0 && 'grey'}}/>
          </div>
          <Carousel
            variant="dark"
            touch={true}
            keyboard={true}
            slide={true}
            fade={true}
            interval={null}
            indicators={false}
            controls={false}
            activeIndex={index - 1}
            onSelect={(selectedIndex, e) => {
              setIndex(selectedIndex);
            }}
          >
            {pages.map((page) => {
              return (
                <Carousel.Item key={parseInt(page.page)} style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <QuickPinchZoom
                    onUpdate={onUpdate}
                    doubleTapToggleZoom={true}
                    doubleTapZoomOutOnMaxScale={true}
                    draggableUnZoomed
                  >
                    <img
                      className="img-fluid rounded"
                      style={{
                        cursor: "pointer",
                        maxHeight: "60vh",
                      }}
                      src={page.imageURL}
                      alt="carousel slide"
                    />
                  </QuickPinchZoom>
                </Carousel.Item>
              );
            })}
          </Carousel>
          <div className="d-flex align-items-center">
            <FaChevronRight size={24} onClick={() => {if(index < post?.nPages - 1) setIndex(prev => prev+1)}} style={{cursor: 'pointer', color: index >= post?.nPages - 1 && 'grey'}}/>
          </div>
        </div>
        <div className="d-flex mt-3 pt-2 justify-content-center pb-1 pb-sm-2">
          <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
            <AiFillHeart
              size={23}
              color={alreadyLiked ? "red" : "gray"}
              //onClick={!loadingLike ? alreadyLiked ? unlikePage : likePage : null}
              //style={{ cursor: "pointer" }}
            />
            <OverlayTrigger
              rootClose={true}
              trigger="click"
              placement="top"
              overlay={
                <Popover id={"popover-positioned-top"}>
                  <Popover.Header
                    as="h3"
                    className="bg-dark text-light text-center"
                    style={{
                      fontWeight: "normal",
                      fontSize: 18,
                      letterSpacing: 1,
                    }}
                  >
                    {"Likes"}
                  </Popover.Header>
                  <Popover.Body>
                    <ul className="p-0">
                      {likes?.map((like) => {
                        return (
                          <li
                            key={like.mapKey}
                            className="lh-lg"
                            style={{
                              listStyle: "none",
                              fontWeight: "normal",
                              fontSize: 16,
                            }}
                          >
                            <div>{like.displayName}</div>
                            <div
                              className=""
                              style={{
                                fontWeight: "light",
                                fontSize: 13,
                                letterSpacing: 1,
                              }}
                            >
                              {like.createdOn.split(" ")[0]}
                            </div>
                            <hr />
                          </li>
                        );
                      })}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <MDBBadge
                color={alreadyLiked ? "dark" : "secondary"}
                notification
                pill
                className="mx-1"
                style={{ cursor: "pointer" }}
              >
                <div className={alreadyLiked ? "dark" : "secondary"}>
                  {likes?.length}
                </div>
              </MDBBadge>
            </OverlayTrigger>
          </div>
          <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
            <FaHandPaper
              size={23}
              color={handsUp ? "#FFAB01" : "gray"}
              //onClick={!loadingHand ? handsUp ? downHand : upHand : null}
              //style={{ cursor: "pointer" }}
            />
            <OverlayTrigger
              rootClose={true}
              trigger="click"
              placement="top"
              overlay={
                <Popover id={"popover-positioned-top"}>
                  <Popover.Header
                    as="h3"
                    className="bg-dark text-light text-center"
                    style={{
                      fontWeight: "normal",
                      fontSize: 18,
                      letterSpacing: 1,
                    }}
                  >
                    {"On Hold"}
                  </Popover.Header>
                  <Popover.Body>
                    <ul className="p-0">
                      {onHolds.map((onHold) => {
                        return (
                          <li
                            key={onHold?.mapKey}
                            className="lh-lg"
                            style={{
                              listStyle: "none",
                              fontWeight: "normal",
                              fontSize: 16,
                            }}
                          >
                            <div>{onHold?.displayName}</div>
                            <div
                              className=""
                              style={{
                                fontWeight: "light",
                                fontSize: 13,
                                letterSpacing: 1,
                              }}
                            >
                              {onHold?.createdOn.split(" ")[0]}
                            </div>
                            <hr />
                          </li>
                        );
                      })}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <MDBBadge
                color={handsUp ? "dark" : "secondary"}
                notification
                pill
                className="mx-1"
                style={{ cursor: "pointer" }}
              >
                <div className={handsUp ? "dark" : "secondary"}>
                  {onHolds.length}
                </div>
              </MDBBadge>
            </OverlayTrigger>
          </div>
          <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5" style={{position: 'relative'}}>
            <AiFillDollarCircle
              size={23}
              color={alreadyBought ? "blue" : "gray"}
            />
            <OverlayTrigger
              rootClose={true}
              trigger="click"
              placement="top"
              overlay={
                <Popover id={"popover-positioned-top"}>
                  <Popover.Header
                    as="h3"
                    className="bg-dark text-light text-center"
                    style={{
                      fontWeight: "normal",
                      fontSize: 18,
                      letterSpacing: 1,
                    }}
                  >
                    {alreadyBought ? 'Inquiries' : 'No Inquiries'}
                  </Popover.Header>
                  <Popover.Body>
                    <ul className="p-0">
                      {buys?.map((event) => {
                        return (
                          <li
                            key={event.mapKey}
                            className="lh-lg"
                            style={{
                              listStyle: "none",
                              fontWeight: "normal",
                              fontSize: 16,
                            }}
                          >
                            <h6
                              className="pb-2"
                              style={{
                                fontWeight: "bold",
                                fontSize: 15,
                              }}
                            >
                              {event.orderedByDisplayName}
                            </h6>
                            <h6
                              style={{
                                fontWeight: "normal",
                                fontSize: 13,
                              }}
                            >
                              {event.orderedByDisplayName}
                              <span>
                                {" "}
                                inquired for potential acquisition
                              </span>
                            </h6>
                            <h6
                              className="text-primary pt-1"
                              style={{
                                fontWeight: "normal",
                                fontSize: 13,
                              }}
                            >
                              {/* {event.orderedByDisplayName}  */}
                              Pending seller response
                            </h6>

                            {event.urgentChecked ? (
                              <h6 className="mt-1">
                                <AiFillThunderbolt
                                  size={18}
                                  //onClick={urgentOrUnurgentPage}
                                  color="orange"
                                  // style={{ cursor: "pointer" }}
                                />{" "}
                                <span
                                  className="mx-2 text-danger"
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: 13,
                                  }}
                                >
                                  {/* {event.orderedByDisplayName}  */}
                                  Urgent request
                                </span>
                              </h6>
                            ) : (
                              ""
                            )}

                            {event.orderText ? (
                              <h6 className="mt-1">
                                <BiCommentAdd
                                  size={18}
                                  //onClick={urgentOrUnurgentPage}
                                  color="gray"
                                  // style={{ cursor: "pointer" }}
                                />{" "}
                                <span
                                  className="mx-2 text-dark"
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: 13,
                                  }}
                                >
                                  "{event.orderText}"
                                </span>
                              </h6>
                            ) : (
                              ""
                            )}

                            <div
                              className=""
                              style={{
                                fontWeight: "light",
                                fontSize: 13,
                                letterSpacing: 1,
                              }}
                            >
                              {event.createdOn.split(" ")[0]}
                            </div>

                            <MDBBadge
                              // color={alreadyOrdered ? "secondary" : "dark"}
                              color="dark"
                              notification
                              className="d-grid w-100 mt-3 mb-3 rounded text-light text-center shadow-sm"
                              style={{
                                fontWeight: "normal",
                                fontSize: 16,
                                cursor: "pointer",
                                border: "1px solid gray",
                              }}
                              onClick={() =>
                                navigate(
                                  `/order-o-status/${event.orderId}`
                                )
                              }
                            >
                              <div
                              // className={
                              //   alreadyOrdered ? "danger" : "secondary"
                              // }
                              >
                                {/* {buys?.length} */}
                                {/* {alreadyOrdered ? inquired : inquire} */}
                                Check Status
                              </div>
                            </MDBBadge>
                            <hr />
                          </li>
                        );
                      })}
                      <li
                        className="lh-lg"
                        style={{
                          listStyle: "none",
                          fontWeight: "normal",
                          fontSize: 14,
                        }}
                      ></li>
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <MDBBadge
            color={alreadyBought ? "dark" : "secondary"}
            notification
            pill
            className="mx-1"
            style={{ cursor: "pointer" }}
          >
            <div className={alreadyBought ? "dark" : "secondary"}>
              {buys?.length}
            </div>
          </MDBBadge>
            </OverlayTrigger>
          </div>
          <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5" style={{position: 'relative'}}>
            <BiCommentAdd
              size={23}
              color={alreadyCommented ? "pink" : "gray"}
            />
            {showCommentsPopover &&
              <div style={{position: 'absolute', bottom: '30px', right: '-30px', zIndex: '9'}}>
                <CommentsPopover
                  post={post}
                  page={index}
                  comments={comments}
                  getData={getData}
                  showCommentArea={false}
                />
              </div>
            }
            <MDBBadge
              color={alreadyCommented ? "dark" : "secondary"}
              notification
              pill
              className="mx-1"
              style={{ cursor: "pointer" }}
              onClick={()=> setShowCommentsPopover(!showCommentsPopover)}
            >
              <div
                className={alreadyCommented ? "dark" : "secondary"}
              >
                {comments?.filter(comment => comment.parentId === null).length}
              </div>
            </MDBBadge>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center" style={{whiteSpace: "pre-wrap", paddingTop: '10px'}}>
          {pages.find(page => parseInt(page.page) === index)?.text}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OutgoingPreviewModal;
