import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Loader from "./Loader";
import { SendHorizonal } from "lucide-react";
import { FaReply } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import { sendNotification } from "../utils/notification";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";

function CommentsModal({show, setShow, comments, getPost, post, page, showTextArea}) {

  const { currentUser, isCurious } = useAuth()

  const [loadingComment, setLoadingComment] = useState(false)
  const [loadingReply, setLoadingReply] = useState(false)

  const [replyTo, setReplyTo] = useState()
  
  const [commentText, setCommentText] = useState("")
  const [replyText, setReplyText] = useState("")

  const addComment = () => {
    setLoadingComment(true)
    
    const comment = commentText
    setCommentText('')
    
    let updatedComments = post.comments;
    const user = currentUser;
    const pageNumber = page;
    const mapKey = uuidv4();
    updatedComments.push({
      mapKey: mapKey,
      id: user.uid,
      email: user.email,
      page: pageNumber,
      commentText: comment,
      displayName: user.displayName,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      parentId: null
    });

    setDoc(doc(db, "posts", post.id), {
      ...post,
      comments: updatedComments,
    }).then(async () => {
        await sendNotification(currentUser?.uid, post?.userId, 'newComment', post?.id, null, pageNumber, mapKey)
        setCommentText("");
        setLoadingComment(false)
        getPost()
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const replyComment = (parentId) => {
    setLoadingReply(true)
    
    const comment = replyText
    setReplyText('')

    let postId = post?.id
    const parentComment = post?.comments?.find(comment => comment.mapKey === parentId);

    if (parentComment && replyText.trim() !== '') {
      const postRef = doc(db, 'posts', postId);

      const user = currentUser;
      const pageNumber = page;
      const mapKey = uuidv4();

      const newComments = [
        ...post?.comments,
        {
          mapKey: mapKey,
          id: user.uid,
          email: user.email,
          page: pageNumber,
          commentText: comment,
          displayName: user.displayName,
          createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
          parentId: parentId
        }
      ]

      updateDoc(postRef, {
        comments: newComments,
      })
        .then(async () => {
          await sendNotification(currentUser?.uid, post?.userId, 'replyComment', post?.id, null, pageNumber, mapKey)
          setReplyText("");
          setLoadingReply(false)
          getPost()
        })
        .catch(() => {
          toast.error("An error occurred");
        });

      setCommentText('')
    }
  }

  return (
    <Modal
      size="lg"
      show={show !== null}
      onHide={() => setShow(null)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen='sm-down'
    >
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: '18px'}}>
          Comments
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{minHeight: '300px', display: 'flex', flexDirection: 'column', maxHeight: '90vh'}}>
        {comments?.length === 0 ? 
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flex: 1}}>
            <span style={{color: '#7D7D7D', fontSize: '12px'}}>No Comments yet</span>
          </div> : 
          <ul style={{overflowY: 'auto', width: '100%', flex: 1, marginLeft: '-30px'}}>
            {comments?.filter(comment => !comment.parentId).map((comment) => {
            return (
              <li
                key={comment?.mapKey}
                className="lh-lg"
                style={{
                  listStyle: "none",
                  fontWeight: "normal",
                  fontSize: 16,
                }}
              >
                <h6
                  style={{
                    fontWeight: "bold",
                    fontSize: 13,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {comment?.displayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{comment?.createdOn?.split(" ")[0]}</span>
                </h6>
                <h6
                  style={{
                    fontWeight: "normal",
                    fontSize: 13,
                  }}
                >
                  {comment?.commentText}
                </h6>
                <h6
                  style={{
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  <a style={{cursor: 'pointer'}} onClick={() => setReplyTo(replyTo === null || replyTo !== comment.mapKey ? comment.mapKey : null)}>
                    {
                      comments?.filter(comm => comm.parentId === comment.mapKey).length === 0
                        ? <><FaReply style={{marginRight: '3px'}}/> Reply</>
                        : <><FaReply style={{marginRight: '3px'}}/> Replies ({comments?.filter(comm => comm.parentId === comment.mapKey).length})</>
                    }
                  </a>
                </h6>
                {replyTo === comment.mapKey && (
                    <>
                      {comments?.filter(comm => comm.parentId === comment.mapKey).map(com => {
                        return (
                          <div style={{marginLeft: '30px', marginBottom: '15px'}}>
                            <li
                              key={com?.mapKey}
                              className="lh-lg"
                              style={{
                                listStyle: "none",
                                fontWeight: "normal",
                                fontSize: 16,
                              }}
                            >
                              <h6
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 13,
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {com?.displayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{com?.createdOn?.split(" ")[0]}</span>
                              </h6>
                              <h6
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 13,
                                }}
                              >
                                {com?.commentText}
                              </h6>
                            </li>
                            
                            </div>
                          )
                      })}
                      <div style={{display: 'flex', marginLeft: '30px', alignItems: 'center', marginBottom: '15px'}}>
                        <textarea
                          style={{width: '100%', resize: 'none', borderColor: '#D8D8D8', marginRight: loadingReply && '10px'}}
                          rows="1"
                          value={replyText}
                          onChange={(e) => {
                            setReplyText(e.target.value)
                          }}
                        />
                        {loadingReply ? <Loader/> :
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              border: '1px solid #D8D8D8',
                              borderRadius: '50%',
                              padding: '5px',
                              cursor: 'pointer',
                              marginLeft: '10px'
                            }}
                            onClick={() => replyComment(comment.mapKey)}
                          >
                            <SendHorizonal color="#262626" size='16px'/>
                          </div>
                        }
                      </div>
                    </>
                  )
                }   
                <hr />
              </li>
            );
          })}
          </ul>
          }
        {showTextArea && <div style={{display: 'flex', alignItems: 'center'}}>
          <textarea
            style={{width: '100%', resize: 'none', borderColor: '#D8D8D8', marginRight: loadingComment && '10px'}}
            rows="2"
            value={commentText}
            onChange={(e) => {
              setCommentText(e.target.value)
            }}
          />
          {loadingComment ?
            <Loader/> :
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #D8D8D8',
                borderRadius: '50%',
                padding: '5px',
                cursor: 'pointer',
                marginLeft: '10px'
              }}
              onClick={addComment}
            >
              <SendHorizonal color="#262626" size='16px'/>
            </div>}
        </div>}
      </Modal.Body>
    </Modal>
  );
}

export default CommentsModal;
