import { Table } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";


function AcqusitionsStatusTable({ orders }) {

    const { isConnoisseur, isCurious } = useAuth()

    const navigate = useNavigate()

    return (
        <Table
            responsive
            variant="light"
            size="md"
            className="mt-4 mb-5"
            style={{
                overflow: "scroll",
                maxWidth: "95vw",
            }}
            id="table-to-export"
            >
            <thead>
                <tr>
                <th>Art</th>
                <th>Details</th>
                {isConnoisseur() && <th>Requested By</th>}
                <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order) => (
                <tr
                    className="text-center"
                    style={{cursor: 'pointer'}}
                    onClick={() => navigate(`/order-${isCurious() ? 's' : 'o'}-status/${order.id}`)}
                >
                    <td className="align-middle">
                    <img
                        className="img-thumbnail"
                        style={{
                        minWidth: "15vw",
                        maxWidth: "15vw",
                        }}
                        src={order?.imageURL}
                    />
                    </td>
                    <td className="align-middle">
                    <div
                        className="overflow-auto text-start"
                        style={{
                        display: "inline-block",
                        whiteSpace: "pre-wrap"
                        }}
                    >
                        {order?.details}
                    </div>
                    </td>
                    {isConnoisseur() && <td className="align-middle">{order?.userDisplayName}</td>}
                    <td className="align-middle">
                    <h6
                        className={`bg-white p-2 rounded border border-${order.statusColor} shadow-sm`}
                    >
                        <span
                        style={{
                            fontWeight: "300",
                            fontSize: 17,
                            fontStyle: "italic",
                        }}
                        >
                        {order.status}
                        </span>
                    </h6>
                    </td>
                </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default AcqusitionsStatusTable;
