import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { loaderReducer } from "./redux/loaderReducer";
import { configureStore } from "@reduxjs/toolkit";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { InstantSearch } from "react-instantsearch";
import { searchClient } from "./algoliaConfig";

const store = configureStore({ reducer: loaderReducer });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
          <InstantSearch searchClient={searchClient}>
            <App />
          </InstantSearch>
        </Provider>
      </AuthProvider>
    </BrowserRouter> 
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
