import React, { useEffect, useState } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Input from "../components/Input";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useAuth } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

const ForgotPassword = () => {

  const navigate = useNavigate()

  const location  = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const { changePassword, forgotPassword } = useAuth()

  const emailFromLogin = queryParams.get('email')

  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)

  const [emailSent, setEmailSent] = useState(false)

  useEffect(() => {
    if(emailFromLogin) setEmail(emailFromLogin)
  }, [emailFromLogin])

  const handleForgotPassword = () => {
    setLoading(true)
    forgotPassword(email)
      .then(() => {
        setLoading(false)
        toast.success('A password reset email has been sent to your email address.')
        navigate('/login')
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        toast.error('Error to send password resert email. Please verify your email')
      })
  }

  const passwordResetToken = queryParams.get('oobCode')

  const [newPassword, setNewPassword] = useState("")
  
  const handleResetPassword = () => {
    setLoading(true)
    if (newPassword?.length === 0) {
      setLoading(false)
      toast.error("Password required. Please enter a password.")
      return
    } else if (newPassword?.length < 6) {
      setLoading(false)
      toast.error(
        "Password needs to be at least 6 characters long. Please re-enter a password."
      )
      return
    }

    changePassword(passwordResetToken, newPassword)
      .then(() => {
        setLoading(false)
        toast.success('Password chaned successfully!')
        navigate('/')
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        toast.error('Failed to change password. Please try again!')
      })

  }

  useEffect(() => {
    if(passwordResetToken) setEmailSent(true)
  }, [passwordResetToken])

  return (
    <DefaultLayout>
      {" "}
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        {" "}
        <div
          className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 pb-5 mb-5"
        >
          <div className="pb-5">
            {" "}
            <Card className="mb-5">
              <span className="text-center">
                <Card.Header as="h4">Reset Password</Card.Header>
              </span>
              <Card.Body>
                <span className="text-center">
                  <Card.Title></Card.Title>
                </span>
                <div className="d-flex justify-content-center pb-2 pt-2 pb-3">
                  {emailSent
                    ?
                      <div className="text-center">
                        <Input
                          label="New Password"
                          type="password"
                          value={newPassword}
                          setValue={setNewPassword}
                        />
                        <div className="d-grid mt-2">
                          <Button variant="outline-dark" onClick={handleResetPassword}>
                            {loading ? <Loader /> : "Change Password"}
                          </Button>
                        </div>
                      </div>
                    :
                      <div className="text-center">
                        <Input
                          label="Email"
                          type="email"
                          value={email}
                          setValue={setEmail}
                        />
                        <div className="d-grid mt-2">
                          <Button variant="outline-dark" onClick={handleForgotPassword}>
                            {loading ? <Loader /> : "Send Email"}
                          </Button>
                        </div>
                      </div>
                  }
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ForgotPassword;
