import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { ChevronDown, DollarSign, Hand, Heart, MessageCircle } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Carousel, Dropdown, Image } from "react-bootstrap";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import CommentsModal from "./CommentsModal";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../context/AuthContext";
import InquirePopover from "./InquirePopover";

const CustomRequestToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #D8D8D8',
      padding: '4px 8px',
      cursor: 'pointer'
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <span style={{fontSize: '14px', fontWeight: 'bold'}}>{children}</span>
    <ChevronDown style={{paddingTop: '2px'}}/>
  </div>
));


function IncomingCard({ spId }) {

  const navigate = useNavigate()

  const { currentUser } = useAuth()

  const [sharedPost, setSharedPost] = useState(null)
  const [post, setPost] = useState(null)

  const [loadingPages, setLoadingPages] = useState(false)
  const [pages, setPages] = useState([])
  const [page, setPage] = useState(1)

  const [index, setIndex] = useState(0)
  const handleSelect = (selectIndex) => {
    setIndex(selectIndex)
  }

  const [comments, setComments] = useState([])

  const [loadingLike, setLoadingLike] = useState(false)
  const [loadingHand, setLoadingHand] = useState(false)
  const [alreadyLiked, setAlreadyLiked] = useState(false)
  const [alreadyOnHold, setAlreadyOnHold] = useState(false)
  const [alreadyInquired, setAlreadyInquired] = useState(false)
  const [alreadyPhoned, setAlreadyPhoned] = useState(false)
  const [alreadyShareRequested, setAlreadyShareRequested] = useState(false)

  const [showCommentsModal, setShowCommentsModal] = useState(false)
  const [showInquirePopover, setShowInquirePopover] = useState(false)

  const getPost = async () => {
    getDoc(doc(db, 'sharedPosts', spId)).then(async res => {
      const postDoc = await getDoc(doc(db, 'posts', res.data().post))
      const user = await getDoc(doc(db, 'users', res.data().sharedBy))
      setPost({...postDoc.data(), id: postDoc.id})
      setSharedPost({...res.data(), post: {...postDoc.data(), id: postDoc.id}, sharedBy: user.data()})
    }).catch(err => console.log(err))
  }

  const getPages = () => {
    setLoadingPages(true)
    getDocs(collection(db, 'posts', post.id, 'pages')).then(res => {
      setPages(res.docs.map(d => ({...d.data(), id: d.id})))
    }).finally(() => setLoadingPages(false))
  }

  const handleOnSlid = (e) => {
    setPage(e + 1)
  }

  const getComments = (page) => {
    function checkComment(comment) {
      return comment.page === parseInt(page);
    }
    setComments(post?.comments?.filter(checkComment) || []);
  }

  const likePage = () => {
    setLoadingLike(true)
    let user = currentUser;
    let updatedLikes = post.likes;
    const pageNumber = parseInt(page);

    const mapKey = uuidv4();
    updatedLikes.push({
      mapKey: mapKey,
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      page: pageNumber,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    });

    const imageURL = page.imageURL || null

    addDoc(collection(db, "users", user.uid, "likedPages"), {
      mapKey: mapKey,
      userId: user.uid,
      userEmail: user.email,
      userDisplayName: user.displayName,
      postId: post.id,
      postDesc: post.description,
      postCreatedById: post.userId,
      postCreatedByEmail: post.userEmail,
      postCreatedByDisplayName: post.userDisplayName,
      page: pageNumber,
      imageURL: imageURL,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    })
      .then(() => {
      })
      .catch(() => {
        setLoadingLike(false)
        toast.error("Something went wrong");
      });

    updateDoc(doc(db, "posts", post.id), {
      likes: updatedLikes,
    })
      .then(() => {
        getPost();
        setLoadingLike(false)
      })
      .catch(() => {
        toast.error("An error occurred");
        setLoadingLike(false)
      });
  }

  const unlikePage = () => {
    let user = currentUser;
    let updatedLikes = post.likes;
    let filteredLikes = [];
    const pageNumber = parseInt(page);

    for (let i = 0; i < updatedLikes.length; i++) {
      if (updatedLikes[i].id !== user.uid) {
        filteredLikes.push(updatedLikes[i]);
      }
    }
    for (let i = 0; i < updatedLikes.length; i++) {
      if (
        updatedLikes[i].id === user.uid &&
        updatedLikes[i].page !== pageNumber
      ) {
        filteredLikes.push(updatedLikes[i]);
      }
    }
    
    getDocs(
      query(
        collection(db, "users", user.uid, "likedPages"),
        where("postId", "==", post?.id),
        where("page", "==", pageNumber)
      )
    ).then(result => {
      const doc = result.docs[0]
      deleteDoc(doc.ref).catch(err => toast.error("An error occurred"))
    })
     .catch(err => toast.error("An error occurred"))

    updateDoc(doc(db, "posts", post.id), {
      likes: filteredLikes,
    }).then(() => {
        getPost();
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const upHand = () => {
    setLoadingHand(true)
    if(alreadyOnHold) return

    let onHold = post.onHold || [];
    const pageNumber = parseInt(page);

    const mapKey = uuidv4();
    onHold?.push({
      mapKey: mapKey,
      id: currentUser.uid,
      email: currentUser.email,
      displayName: currentUser.displayName,
      page: pageNumber,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    });

    addDoc(collection(db, "users", currentUser.uid, "onHolds"), {
      mapKey: mapKey,
      userId: currentUser.uid,
      userEmail: currentUser.email,
      userDisplayName: currentUser.displayName,
      postId: post.id,
      postDesc: post.description,
      postCreatedById: post.userId,
      postCreatedByEmail: post.userEmail,
      postCreatedByDisplayName: post.userDisplayName,
      page: pageNumber,
      imageURL: post.imageURL,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    })
      .then(() => {
      })
      .catch((err) => {
        console.log(err)
        setLoadingHand(false)
        toast.error("Something went wrong");
      });

    updateDoc(doc(db, "posts", post.id), {
      onHold: onHold,
    })
      .then(() => {
        getPost();
        setLoadingHand(false)
      })
      .catch(() => {
        toast.error("An error occurred");
        setLoadingHand(false)
      });
  }

  const downHand = () => {
    if(!alreadyOnHold) return

    const pageNumber = parseInt(page)

    getDocs(
      query(
        collection(db, "users", currentUser.uid, "onHolds"),
        where("postId", "==", post?.id),
        where("page", "==", pageNumber)
      )
    ).then(result => {
      const doc = result.docs[0]
      deleteDoc(doc.ref).catch(err => toast.error("An error occurred"))
    })
     .catch(err => toast.error("An error occurred"))

    updateDoc(doc(db, "posts", post.id), {
      onHold: post.onHold?.filter(obj => obj.id !== currentUser.uid && obj.page !== pageNumber),
    }).then(() => {
        getPost();
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const phoneOrUnphonePost = () => {
    const userPhones = post?.phones ?? [];
    const filteredPhones = [];
    if (alreadyPhoned) {
      for (let i = 0; i < post?.phones?.length; i++) {
        if (post?.phones[i]?.id !== currentUser.uid) {
          filteredPhones.push(post?.phones[i]);
        }
      }
      updateDoc(doc(db, "posts", post?.id), {
        phones: filteredPhones,
      })
        .then(() => {
          getPost();
          toast.success("Phone call request canceled");
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    } else {
      const mapKey = uuidv4();
      userPhones.push({
        mapKey: mapKey,
        id: currentUser.uid,
        email: currentUser.email,
        displayName: currentUser.displayName,
        postId: post?.id,
        createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      });
      updateDoc(doc(db, "posts", post?.id), {
        phones: userPhones,
      })
        .then(() => {
          getPost()
          toast.success("Phone call scheduling requested");
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    }
  }

  const shareRequestOrUnshareRequest = () => {
    const userShareRequests = post?.shareRequests ?? []
    const filteredShareRequests = [];
    if (alreadyShareRequested) {
      for (let i = 0; i < post?.shareRequests?.length; i++) {
        if (post?.shareRequests[i].id !== currentUser.uid) {
          filteredShareRequests.push(post?.shareRequests[i]);
        }
      }

      updateDoc(doc(db, "posts", post?.id), {
        shareRequests: filteredShareRequests,
      })
        .then(() => {
          getPost()
          toast.success("Share request canceled");
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    } else {
      const mapKey = uuidv4();
      userShareRequests.push({
        mapKey: mapKey,
        id: currentUser.uid,
        email: currentUser.email,
        displayName: currentUser.displayName,
        postId: post?.id,
        createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      });
      updateDoc(doc(db, "posts", post?.id), {
        shareRequests: userShareRequests,
      })
        .then(() => {
          getPost()
          toast.success("Permission to share requested");
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    }
  }

  useEffect(() => {
    if(sharedPost && page){
      setAlreadyLiked(
        post?.likes?.find(like => like.id === currentUser.uid && like.page === parseInt(page))
      )
      setAlreadyOnHold(
        post?.onHold?.find(hold => hold.id === currentUser.uid && hold.page === parseInt(page))
      )
      setAlreadyInquired(
        post?.buys?.find(buy => buy.id === currentUser.uid && buy.page === parseInt(page))
      )
      setAlreadyPhoned(
        post?.phones?.find(phone => phone.id === currentUser.uid)
      )
      setAlreadyShareRequested(
        post?.shareRequests?.find(sr => sr.id === currentUser.uid)
      )
      getComments(page)
    }
  }, [page, sharedPost])

  useEffect(() => {
    if(sharedPost) getPages()
  }, [sharedPost])

  useEffect(() => {
    getPost()
  }, [spId])

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', border: '1px solid #D8D8D8', marginBottom: '24px'}}>
      <div style={{display: 'flex', justifyContent: 'space-between', padding: '15px', alignItems: 'center', width: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', overflow: 'hidden'}}>
          <span style={{fontSize: '18px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%'}}>{post?.description}</span>
          <span style={{color: '#7D7D7D', fontSize: '12px'}}>{moment(post?.createdAt).format("MMM DD, YYYY")}</span>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #D8D8D8',
            borderRadius: '50%',
            padding: '5px',
            background: alreadyInquired ? 'blue' : 'white',
            position: 'relative',
            cursor: 'pointer'
          }}
        >
          {showInquirePopover && !alreadyInquired &&
            <div  style={{position: 'absolute', bottom: '-312px', right: '0px', zIndex: '9'}}>
              <InquirePopover
                post={post}
                page={page}
                alreadyLiked={alreadyLiked}
                likePage={likePage}
                alreadyBought={alreadyInquired}
                getData={getPost}
              />
            </div>
          }
          <DollarSign color={alreadyInquired ? 'white' : "#262626"} size='16px' onClick={()=> setShowInquirePopover(!showInquirePopover)}/>
        </div>
      </div>
      {loadingPages ?
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px', width: '100%'}}>
          <Loader/>
        </div> : 
        <Carousel interval={null} onSlid={handleOnSlid} indicators={false} style={{margin: '0 -1px'}} activeIndex={index} onSelect={handleSelect}>
          {pages.map(page => (
            <Carousel.Item id={page.id}>
              <Image
                fluid
                src={page.imageURL}
                style={{height: '400px', objectFit: 'cover', width: '100%', cursor: 'pointer'}}
                onClick={() => navigate(`/post-s-grid/${post?.id}`)}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      }
      <div style={{display: 'flex', justifyContent: 'space-between', padding: '15px 15px 0 15px', alignItems: 'center'}}>
        <div style={{display: 'flex', alignItems: 'start'}}>
          <Heart style={{marginRight: '3px', color: '#262626', cursor: 'pointer'}} strokeWidth={1} fill={alreadyLiked ? '#C90000' : 'white'} onClick={!loadingLike ? alreadyLiked ? unlikePage : likePage : null}/>
          <MessageCircle style={{marginRight: '3px', color: '#262626', cursor: 'pointer'}} strokeWidth={1} onClick={() => setShowCommentsModal(!showCommentsModal)}/>
          <Hand style={{marginRight: '3px', color: '#262626', cursor: 'pointer'}} strokeWidth={1} fill={alreadyOnHold ? '#F5CE02' : 'white'} onClick={!loadingHand ? alreadyOnHold ? downHand : upHand : null}/>
        </div>
        <Dropdown>
          <Dropdown.Toggle as={CustomRequestToggle}>
            Requests
          </Dropdown.Toggle>
          <Dropdown.Menu style={{fontSize: '14px'}}>
            <Dropdown.Item onClick={shareRequestOrUnshareRequest}>Request To Share</Dropdown.Item>
            <Dropdown.Item onClick={phoneOrUnphonePost}>Request A Call</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{display: 'flex', alignItems: 'start', flexDirection: 'column', padding: '0 15px 15px 15px'}}>
        <span
          style={{color: '#7D7D7D', fontSize: '10px'}}
        >
          Shared with you by @{sharedPost?.sharedBy?.displayName}
        </span>
      </div>
      {showCommentsModal &&
        <CommentsModal
          show={showCommentsModal}
          setShow={setShowCommentsModal}
          comments={comments}
          getPost={getPost}
          post={post}
          page={page}
          showTextArea
        />}
    </div>
  );
}

export default IncomingCard;
