import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Loader from "./Loader";
import { SendHorizonal } from "lucide-react";
import { FaReply } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import { sendNotification } from "../utils/notification";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";
import { MDBBadge } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { BiCommentAdd } from "react-icons/bi";

function InquiresModal({show, setShow, inquires}) {

  const navigate = useNavigate()

  return (
    <Modal
      size="lg"
      show={show !== null}
      onHide={() => setShow(null)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen='sm-down'
    >
      <Modal.Header closeButton>
        <Modal.Title style={{fontSize: '18px'}}>
          Inquires
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{minHeight: '300px', display: 'flex', flexDirection: 'column', maxHeight: '90vh'}}>
        {inquires.length === 0 ? 
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', flex: 1}}>
            <span style={{color: '#7D7D7D', fontSize: '12px'}}>No Inquires yet</span>
          </div> : 
          <ul style={{overflowY: 'auto', width: '100%', flex: 1, marginLeft: '-30px'}}>
            {inquires.map((inquire) => {
            return (
              <li
                key={inquire?.mapKey}
                className="lh-lg"
                style={{
                  listStyle: "none",
                  fontWeight: "normal",
                  fontSize: 16,
                }}
              >
                <h6
                  style={{
                    fontWeight: "bold",
                    fontSize: 13,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {inquire?.orderedByDisplayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{inquire?.createdOn?.split(" ")[0]}</span>
                </h6>
                <h6
                    className="text-primary"
                    style={{
                      fontWeight: "normal",
                      fontSize: 13,
                    }}
                  >
                    {/* {event.orderedByDisplayName}  */}
                    Pending seller response
                  </h6>
                {inquire.orderText ? (
                    <h6 className="mt-1">
                      <BiCommentAdd
                        size={18}
                        color="gray"
                      />{" "}
                      <span
                        className="mx-2 text-dark"
                        style={{
                          fontWeight: "normal",
                          fontSize: 13,
                        }}
                      >
                        "{inquire.orderText}"
                      </span>
                    </h6>
                  ) : (
                    ""
                  )}
                <h6
                    style={{
                      fontWeight: "normal",
                      fontSize: 13,
                    }}
                  >
                    {/* {event.orderedByDisplayName}  */}
                    {inquire?.orderedByDisplayName} inquired for potential acquisition
                  </h6>
                <h6
                  style={{
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  <MDBBadge
                    color="dark"
                    notification
                    className="d-grid w-100 mt-3 rounded text-light text-center shadow-sm"
                    style={{
                      fontWeight: "normal",
                      fontSize: 16,
                      cursor: "pointer",
                      border: "1px solid gray",
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      navigate(
                        `/order-s-status/${inquire?.orderId}`
                      )
                    }
                  >
                    <div
                    >
                      Check Status
                    </div>
                  </MDBBadge>
                </h6>  
                <hr />
              </li>
            );
          })}
          </ul>
          }
      </Modal.Body>
    </Modal>
  );
}

export default InquiresModal;
