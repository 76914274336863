import React from "react";
import { useAuth } from '../context/AuthContext'
import DefaultLayout from "../components/DefaultLayout";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";


const Home = () => {

  const navigate = useNavigate()

  const { currentUserRealTime: currentUser } = useAuth()

  return (
    <DefaultLayout>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '60vh',
          flexDirection: 'column'
        }}
      >
        <p style={{fontSize: '56px', fontWeight: 200}}>welcome back, @{currentUser?.username}</p>
        <Button
          style={{background: '#919497', border: 'none', marginTop: '150px'}}
          onClick={() => navigate('/dashboard')}>
            view dashboard
        </Button>
      </div>
    </DefaultLayout>
  );
};

export default Home;
