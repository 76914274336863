import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { MDBBadge } from "mdb-react-ui-kit";

import { FaComment, FaThList } from "react-icons/fa";
import {
  AiFillHeart,
  AiFillDollarCircle,
  AiFillEye,
} from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { IoCaretBackSharp } from "react-icons/io5";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { useAuth } from '../context/AuthContext'
import SharedWithPopover from "../components/SharedWithPopover";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import IncomingPreviewModal from "../components/IncomingPreviewModal";


function PostDescSgrid() {

  const [post, setPost] = useState(null);
  const [pages, setPages] = useState()

  const [gridToggled, setGridToggled] = useState(false);

  const [loadingCopy, setLoadingCopy] = useState(false)

  const [showModal, setShowModal] = useState(null)

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { currentUser, isConnoisseur } = useAuth()

  const [scrollY, setScrollY] = useState(0)

  const scrollRef = useRef(null)

  const handleScroll = () => {
    setScrollY(scrollRef.current.scrollTop)
  }

  const getPost = () => {
    dispatch({ type: "showLoading" });
    getDoc(doc(db, "posts", params.id))
      .then(async (response) => {
        setPost({ ...response.data(), id: response.id });
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
      });
  };

  const getPages = () => {
    getDocs(query(collection(db, 'posts', post?.id, 'pages'))).then(res => {
      setPages(res.docs.map(docu => ({...docu.data(), id: docu.id})).sort((a,b) => parseInt(a.id) - parseInt(b.id)))
    }).catch((err) => {
      console.log(err)
    })
  }

  const createCopyPages = async (id) => {
    await Promise.all(pages.map(page => {
      setDoc(doc(db, 'posts', id, 'pages', page.page), page)
      .catch(err => console.log(err))
    }))
  }

  const createCopy = () => {
    setLoadingCopy(true)
    addDoc(collection(db, 'posts'),{
      ...post,
      description: `${post?.description} - Copy`,
      userId: currentUser?.uid,
      userEmail: currentUser?.email,
      userDisplayName: currentUser?.displayName,
      gallery: "",
      artists: [],
      phones: [],
      shareRequests: [],
      likes: [],
      buys: [],
      urgents: [],
      comments: [],
      onHold: [],
      flags: [],
      inventory: [],
      events: [],
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    }).then(async response => {
      await createCopyPages(response.id)
      toast.success('Capsule copy created successfully!')
      navigate(`/post-o-grid/${response.id}`)
      setLoadingCopy(false)
    }).catch(err => {
      console.log(err)
      toast.error('Failed to create copy')
      setLoadingCopy(false)
    })
  }

  const buyCount = (page) => {
    function checkBuy(buy) {
      return buy.page === page;
    }
    const filteredBuys = post.buys?.filter(checkBuy);

    return filteredBuys.length;
  };

  const userBuyCount = (page, user) => {
    function checkBuy(buy) {
      return buy.page === parseInt(page) && buy.id === user;
    }
    const filteredBuys = post?.buys?.filter(checkBuy);
    return filteredBuys?.length;
  };

  const urgentCount = (page) => {
    function checkUrgent(urgent) {
      return urgent.page === parseInt(page);
    }
    const filteredUrgents = post?.urgents?.filter(checkUrgent);
    return filteredUrgents?.length;
  };

  const userUrgentCount = (page, user) => {
    function checkUrgent(urgent) {
      return urgent.page === parseInt(page) && urgent.id === user;
    }
    const filteredUrgents = post?.urgents?.filter(checkUrgent);
    return filteredUrgents?.length;
  };

  const likeCount = (page) => {
    function checkLike(like) {
      return like.page === parseInt(page);
    }
    const filteredLikes = post?.likes?.filter(checkLike);

    return filteredLikes?.length;
  };

  const userLikeCount = (page, user) => {
    function checkLike(like) {
      return like.page === parseInt(page) && like.id === user;
    }
    const filteredLikes = post?.likes?.filter(checkLike);
    return filteredLikes?.length;
  };

  const flagCount = (page) => {
    function checkFlag(flag) {
      return flag.page === parseInt(page);
    }
    const filteredFlags = post?.flags?.filter(checkFlag);
    return filteredFlags?.length;
  };

  const commentCount = (page) => {
    function checkComment(comment) {
      return comment.page === parseInt(page)
        && comment.id === currentUser.uid
        && comment.parentId === null
    }
    const filteredComments = post?.comments?.filter(checkComment);

    return filteredComments?.length;
  };

  const userCommentCount = (page, user) => {
    function checkComment(comment) {
      return comment.page === parseInt(page)
        && comment.id === user
        && comment.parentId === null
    }
    const filteredComments = post?.comments?.filter(checkComment);

    return filteredComments?.length;
  };

  useEffect(() => {
    if(post) getPages();
  }, [post]);

  useEffect(() => {
    getPost();
  }, []);

  const goToShares = () => {
    navigate(`/shares/`);
  };

  const goToPageView = () => {
    navigate(`/post-s-page/${post.id}`);
  };

  useEffect(() => {
    const handleScrollToY = async () => {
      if (scrollRef.current && location.state?.scrollY) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        scrollRef.current.scrollTo({ top: location.state.scrollY, behavior: 'smooth' });
      }
    };
  
    handleScrollToY();
  }, [location.state?.scrollY])

  useEffect(() => {
    if(post && location?.state?.page){
      setShowModal(pages.find(page => page.page === location?.state?.page - 1))
    }
  }, [location?.state?.page, post])

  useEffect(() => {
    getPost()
  }, [params])

  return (
    <DefaultLayout scrollRef={scrollRef} onScroll={handleScroll}>
      <div className="d-flex justify-content-center">
        {post && (
          <>
            <Card>
              <Card.Header as="h5">
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                  <div style={{position: 'absolute', left: 0}}>
                    <IoCaretBackSharp
                      size={17}
                      color="dark"
                      style={{ cursor: "pointer" }}
                      onClick={goToShares}
                    />
                  </div>
                  {post.description}
                </div>
              </Card.Header>

              <Card.Body>

                <div className="text-center">

                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <div className="mt-2 mb-2">
                        <span
                          className={`text-center align-items-center p-2 bg-light  rounded shadow-sm ${
                            gridToggled
                              ? "border border-secondary"
                              : "border-light"
                          }`}
                          onClick={() => {
                            setGridToggled(gridToggled ? false : true);
                          }}
                        >
                          <span>
                            <BsFillGrid3X3GapFill
                              size={17}
                              color="dark"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </span>
                      </div>
                    {isConnoisseur() &&
                      <MDBBadge color="white" className="shadow-sm p-2 d-flex align-items-center">
                      <div
                        className="p-2 bg-light rounded shadow-sm d-flex align-items-center"
                        style={{ cursor: "pointer"}}
                      >
                        <OverlayTrigger
                          rootClose={true}
                          trigger="click"
                          placement="bottom"
                          overlay={<Popover style={{marginTop: '10px'}}><SharedWithPopover post={post}/></Popover>}
                        >
                          <span className="">
                            <FaThList
                              size={18}
                              color="black"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </OverlayTrigger>
                      </div>
                      {loadingCopy ? <Loader/> :
                        <Button
                          variant="outline-dark"
                          onClick={createCopy}
                          style={{marginLeft: '15px'}}
                        >
                          Create copy
                        </Button>
                      }
                    </MDBBadge>
                    }
                  </div>
                  <div className="row text-center">
                      {pages?.map((page) => {
                        let alreadyBuy = false;
                        let alreadyUrgent = false;
                        let alreadyLiked = false;
                        let alreadyComment = false;
                        let showFeedbackBar = false;

                        if (userBuyCount(page.page, currentUser.uid) > 0) {
                          alreadyBuy = true;
                        }
                        if (userUrgentCount(page.page, currentUser.uid) > 0) {
                          alreadyUrgent = true;
                        }
                        if (userLikeCount(page.page, currentUser.uid) > 0) {
                          alreadyLiked = true;
                        }
                        if (userCommentCount(page.page, currentUser.uid) > 0) {
                          alreadyComment = true;
                        }

                        if (
                          alreadyBuy === true ||
                          alreadyLiked === true ||
                          alreadyComment === true
                        ) {
                          showFeedbackBar = true;
                        }
                        return (
                          <div
                            key={page.page}
                            className={`${
                              gridToggled
                                ? "col-6 col-sm-4 col-md-4 col-lg-3 col-xxl-2"
                                : "col-sm-6 col-md-6 col-lg-6 col-xxl-4"
                            } mt-5 mb-2`}
                          >
                            <div className="d-inline-flex position-relative justify-content-center mx-0">
                              <Image
                                fluid thumbnail
                                src={page.imageURL}
                                style={{cursor: 'pointer'}}
                                onClick={() => setShowModal(page)}
                              />
                              <div>
                                <MDBBadge
                                  pill
                                  color="light"
                                  className="position-absolute bottom-100 my-1 start-50 translate-middle-x p-2 bg-info border-dark shadow-sm text-dark"
                                >
                                  {page.page}
                                  <span className="visually-hidden">
                                    Page Number
                                  </span>
                                </MDBBadge>
                              </div>

                              {post?.flags?.map((flag) => {
                                if (flag.page === parseInt(page.page))
                                  return (
                                    <div key={flag.mapKey}>
                                      <MDBBadge
                                        pill
                                        color="info"
                                        className="position-absolute bottom-0 start-50 translate-middle-x p-2 bg-info border-dark shadow-sm"
                                      >
                                        {/* {flagCount(page.page)} */}
                                        <span className="visually-hidden">
                                          New alerts
                                        </span>
                                      </MDBBadge>
                                    </div>
                                  );
                              })}
                            </div>
                            {showFeedbackBar && (
                              <>
                                {" "}
                                <div
                                  className={`bg-white shadow-sm rounded mb-2 pt-2 ${
                                    alreadyUrgent === true
                                      ? "border border-warning"
                                      : ""
                                  }`}
                                >
                                  <span className="d-inline-flex position-relative px-3">
                                    <AiFillHeart
                                      size={28}
                                      color={alreadyLiked ? "red" : "lightGray"}
                                      // ref={likeHeart}
                                    />
                                    {post?.likes?.map((like) => {
                                      if (
                                        like.page === parseInt(page.page) &&
                                        like.id === currentUser.uid
                                      )
                                        return (
                                          <div key={like.mapKey}>
                                            <MDBBadge
                                              pill
                                              color="white"
                                              className="position-absolute p-1 px-2 mx-2 translate-middle-x text-dark"
                                              style={{
                                                border: "1px solid #C0C0C0",
                                              }}
                                            >
                                              {/* {likeCount(page.page)} */}
                                              {userLikeCount(page.page, currentUser.uid)}
                                              <span className="visually-hidden">
                                                New alerts
                                              </span>
                                            </MDBBadge>
                                          </div>
                                        );
                                    })}
                                  </span>
                                  <span className="d-inline-flex position-relative px-5 px-md-4">
                                    <AiFillDollarCircle
                                      size={28}
                                      color={alreadyBuy ? "blue" : "lightGray"}
                                    />

                                    {post?.buys?.map((buy) => {
                                      if (
                                        buy.page === parseInt(page.page) &&
                                        buy.id === currentUser.uid
                                      )
                                        return (
                                          <div key={buy.mapKey}>
                                            <MDBBadge
                                              pill
                                              color={
                                                alreadyUrgent
                                                  ? "warning"
                                                  : "white"
                                              }
                                              className="position-absolute p-1 px-2 mx-2 translate-middle-x text-dark"
                                              style={{
                                                border: "1px solid #C0C0C0",
                                              }}
                                            >
                                              {/* {buyCount(page.page)} */}
                                              {userBuyCount(page.page, currentUser.uid)}
                                              <span className="visually-hidden">
                                                New alerts
                                              </span>
                                            </MDBBadge>
                                          </div>
                                        );
                                    })}
                                  </span>
                                  <span className="d-inline-flex position-relative px-3">
                                    <FaComment
                                      size={28}
                                      color={
                                        alreadyComment ? "pink" : "lightGray"
                                      }
                                    />
                                    {post?.comments?.map((comment) => {
                                      if (
                                        comment.page === parseInt(page.page) &&
                                        comment.id === currentUser.uid
                                      )
                                        return (
                                          <div key={comment.mapKey}>
                                            <MDBBadge
                                              pill
                                              color="white"
                                              className="position-absolute p-1 px-2 mx-2 translate-middle-x text-dark"
                                              style={{
                                                border: "1px solid #C0C0C0",
                                              }}
                                            >
                                              {/* {commentCount(page.page)} */}
                                              {userCommentCount(
                                                page.page,
                                                currentUser.uid
                                              )}
                                              <span className="visually-hidden">
                                                New alerts
                                              </span>
                                            </MDBBadge>

                                            {/* <MDBBadge
                                  color="info"
                                  className="position-absolute top-0 start-100 translate-middle-x  p-2 bg-info border border-muted rounded-circle"
                                >
                                  <span className="visually-hidden">
                                    New alerts
                                  </span>
                                </MDBBadge> */}
                                          </div>
                                        );
                                    })}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                </div>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </>
        )}
      </div>
      {showModal !==  null && <IncomingPreviewModal post={post} page={showModal} show={showModal} setShow={setShowModal} getData={getPost}/>}
    </DefaultLayout>
  );
}

export default PostDescSgrid;
