// Author: Ciaran O'Keeffe
// Copyright © 2023 Ciaran O'Keeffe All Rights Reserved

import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { useAuth } from '../context/AuthContext'
import { db } from "../firebaseConfig";

import Carousel from "react-bootstrap/Carousel";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
// import { GiAbstract050 } from "react-icons/gi";
import { BiCommentAdd } from "react-icons/bi";
import { MDBBadge } from "mdb-react-ui-kit";

import { IoCaretBackSharp } from "react-icons/io5";

import {
  AiFillHeart,
  AiFillFilter,
  AiFillThunderbolt,
  AiFillDollarCircle,
} from "react-icons/ai";

import { FaComments, FaReply } from "react-icons/fa";

import { toast } from "react-toastify";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
// import { v5 as uuidv5 } from "uuid";
import { v4 as uuidv4 } from "uuid";
import Loader from "../components/Loader";
import { sendNotification } from "../utils/notification";

function PostDescOpage() {
  // console.log(
  //   "Code author: Ciaran O'Keeffe, Copyright © 2023 Ciaran OKeeffe All Rights Reserved"
  // );

  const imgRef = useRef();
  const [urlSet, setUrlSet] = useState([]);
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    // check if image exists
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);
  const [lgShow, setLgShow] = useState(false);
  // const currentUser = JSON.parse(localStorage.getItem("romulus_0-user"));
  const inquired = "Inquiries";
  const inquire = "No Inquiries";
  const submit = "Submit";
  const allUsers = { displayName: "all", default: true };
  const allUsers2 = { displayName: "all", default: false };

  const [post, setPost] = useState(null);
  const [pages, setPages] = useState([])

  const [alreadyLiked, setAlreadyLiked] = useState(false);
  const [likes, setLikes] = useState([]);

  const [alreadyBuy, setAlreadyBuy] = useState(false);
  const [buys, setBuys] = useState([]);

  const [alreadyUrgent, setAlreadyUrgent] = useState(false);
  const [urgents, setUrgents] = useState([]);

  const [alreadyFlag, setAlreadyFlag] = useState(false);
  const [sharedBy, setSharedBy] = useState(null);
  const [flags, setFlags] = useState([]);

  const [invText, setInvText] = useState("");
  const [inventory, setInventory] = useState([]);
  const [alreadyInventoryNumber, setAlreadyInventoryNumber] = useState(false);

  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [alreadyComment, setAlreadyComment] = useState(false);
  const [replyTo, setReplyTo] = useState()
  const [replyText, setReplyText] = useState("")
  const [loadingReply, setLoadingReply] = useState(false)

  const [events, setEvents] = useState([]);
  const [alreadyOrdered, setAlreadyOrdered] = useState(false);

  const [urgentChecked, setUrgentChecked] = useState(false);

  const [orderText, setOrderText] = useState("");

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation()

  const [index, setIndex] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [sUser, setSuser] = useState(null);

  const [userData, setUserData] = useState([]);

  const [usersSharedWith, setUsersSharedWith] = useState([]);

  const [userSelect, setUserSelect] = useState(allUsers);

  const [userSelectId, setUserSelectId] = useState("");
  const [userSelectDisplayName, setUserSelectDisplayName] = useState("");
  const [userSet, setUserSet] = useState(false);

  const { currentUser } = useAuth()

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const getPageSelection = () => {
    const urlText = window.location.href;
    //console.log("urlText= ", urlText);
    //console.log("window.location.pathname= ", window.location.pathname);
    // need to save everything in string of urlText up to the # (to include selected user id string for filtering preceding it)
    // so index 0 of string up to index of #
    // put lastIndexOf('#') in a variable and use below instead - then use that variable to find the string up to #

    // location of # in url string
    let hashIndex = urlText.lastIndexOf("#");

    let urlTextMinusSlide = urlText.substring(0, hashIndex);
    // console.log("urlTextMinusSlide", urlTextMinusSlide);

    let slide = urlText.substring(hashIndex);
    //console.log(slide);
    let removeHash = slide.replace("#", "");
    slide = removeHash;
    slide = parseInt(slide) - 1;
    //console.log("slide= ", slide);
    setIndex(slide);

  };

  // const getUserData = async () => {
  //   dispatch({ type: "showLoading" });
  //   const querySnapshot = await getDocs(collection(db, "users"));
  //   const temp = [];
  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     temp.push({ ...doc.data(), id: doc.id });
  //   });
  //   //console.log(temp);
  //   setUserData(temp);
  //   //getUsersSharedWith(temp);
  //   dispatch({ type: "hideLoading" });
  // };

  // const getUserSelection = (urlText) => {
  //   // const urlText = window.location.href;
  //   //console.log("urlText= ", urlText);
  //   //console.log("window.location.pathname= ", window.location.pathname);

  //   if (urlText.includes("@") === false) {
  //     let user = "";
  //     //console.log("slide= ", slide);
  //   } else {
  //     let user = urlText.substring(urlText.lastIndexOf("@"));
  //     //console.log(slide);
  //     let removeHash = user.replace("@", "");
  //     user = removeHash;
  //     user = parseInt(user);
  //     //console.log("slide= ", slide);
  //     setUserSelect(user);
  //   }
  // };

  const getPost = () => {
    // console.log("running getPost()");
    // const cUser = auth.currentUser;
    const pageNumber = index + 1;
    if (post) {
      // get likes for page into state
      let updatedLikes = post.likes;
      let filteredLikes = [];

      for (let i = 0; i < updatedLikes.length; i++) {
        if (userSelectId !== "") {
          if (
            updatedLikes[i].page === pageNumber &&
            updatedLikes[i].id === userSelectId
          ) {
            filteredLikes.push(updatedLikes[i]);
          }
        } else if (userSelectId === "") {
          if (updatedLikes[i].page === pageNumber) {
            filteredLikes.push(updatedLikes[i]);
          }
        }
      }
      setLikes(filteredLikes);
      if (filteredLikes.length > 0) {
        setAlreadyLiked(true);
      } else {
        setAlreadyLiked(false);
      }
      //

      // get buys for page into state
      let updatedBuys = post.buys;
      let filteredBuys = [];

      for (let i = 0; i < updatedBuys.length; i++) {
        if (userSelectId !== "") {
          if (
            updatedBuys[i].page === pageNumber &&
            updatedBuys[i].id === userSelectId
          ) {
            filteredBuys.push(updatedBuys[i]);
          }
        } else if (userSelectId === "") {
          if (updatedBuys[i].page === pageNumber) {
            filteredBuys.push(updatedBuys[i]);
          }
        }
      }
      setBuys(filteredBuys);
      if (filteredBuys.length > 0) {
        setAlreadyBuy(true);
      } else {
        setAlreadyBuy(false);
      }
      //

      // get urgents for page into state
      let updatedUrgents = post.urgents;
      let filteredUrgents = [];

      for (let i = 0; i < updatedUrgents.length; i++) {
        if (userSelectId !== "") {
          if (
            updatedUrgents[i].page === pageNumber &&
            updatedUrgents[i].id === userSelectId
          ) {
            filteredUrgents.push(updatedBuys[i]);
          }
        } else if (userSelectId === "") {
          if (updatedUrgents[i].page === pageNumber) {
            filteredUrgents.push(updatedUrgents[i]);
          }
        }
      }
      setUrgents(filteredUrgents);
      if (filteredUrgents.length > 0) {
        setAlreadyUrgent(true);
      } else {
        setAlreadyUrgent(false);
      }
      //

      // get flags for page into state
      let updatedFlags = post.flags;
      let filteredFlags = [];

      for (let i = 0; i < updatedFlags.length; i++) {
        if (updatedFlags[i].page === pageNumber) {
          filteredFlags.push(updatedFlags[i]);
        }
      }
      setFlags(filteredFlags);
      if (filteredFlags.length > 0) {
        setAlreadyFlag(true);
      } else {
        setAlreadyFlag(false);
      }
      //

      // get comments for page into state
      let updatedComments = post.comments;
      let filteredComments = [];

      for (let i = 0; i < updatedComments.length; i++) {
        if (userSelectId !== "") {
          if (
            updatedComments[i].page === pageNumber &&
            updatedComments[i].id === userSelectId
          ) {
            filteredComments.push(updatedComments[i]);
          }
        } else if (userSelectId === "") {
          if (updatedComments[i].page === pageNumber) {
            filteredComments.push(updatedComments[i]);
          }
        }
      }
      setComments(filteredComments);
      if (filteredComments.length > 0) {
        // console.log("inventory inside of set already flags function: ", comments);
        setAlreadyComment(true);
      } else {
        setAlreadyComment(false);
      }
      //

      // get inventory for page into state
      let updatedInventory = post.inventory;
      let filteredInventory = [];

      for (let i = 0; i < updatedInventory.length; i++) {
        if (updatedInventory[i].page === pageNumber) {
          filteredInventory.push(updatedInventory[i]);
        }
      }
      setInventory(filteredInventory);
      if (filteredInventory.length > 0) {
        // console.log(
        //   "inventory inside of set already flags function: ",
        //   inventory
        // );
        setAlreadyInventoryNumber(true);
      } else {
        setAlreadyInventoryNumber(false);
      }
      //

      // get order events into state
      // console.log("temp.events = ", temp.events);
      if (post.hasOwnProperty("events")) {
        let updatedEvents = post.events;
        let filteredEvents = [];

        for (let i = 0; i < updatedEvents.length; i++) {
          if (userSelectId !== "") {
            if (
              updatedEvents[i].pageNumber === pageNumber &&
              updatedEvents[i].orderedById === userSelectId
            ) {
              filteredEvents.push(updatedEvents[i]);
            }
          } else if (userSelectId === "") {
            if (updatedEvents[i].pageNumber === pageNumber) {
              filteredEvents.push(updatedEvents[i]);
            }
          }
        }
        setEvents(filteredEvents);
        if (filteredEvents.length > 0) {
          setAlreadyOrdered(true);
        } else {
          setAlreadyOrdered(false);
        }
        //console.log("filteredComments.length= ", filteredComments.length);
        //getUsersSharedWith(temp);
      }

      getUsersSharedWith(post);
    }
  };

  const getData = () => {
    // console.log("running getData()");
    //dispatch({ type: "showLoading" });
    let postId = params.id.split("@")[0];
    //console.log("postId from params.split= ", postId);
    getDoc(doc(db, "posts", postId))
      .then((response) => {
        setPost({ ...response.data(), id: response.id });
        getDocs(query(collection(db, 'posts', response.id, 'pages'), orderBy('page', 'asc'))).then(res => {
          setPages(res.docs.map(docu => ({...docu.data(), id: docu.id})))
        })
      })
      .catch(() => {
        //dispatch({ type: "hideLoading" });
      });
  };

  const flagOrUnflagPage = () => {
    //const auth = getAuth();
    let updatedFlags = post.flags;
    let filteredFlags = [];
    const pageNumber = index + 1;

    if (alreadyFlag) {
      for (let i = 0; i < updatedFlags.length; i++) {
        if (updatedFlags[i].id !== currentUser.uid) {
          filteredFlags.push(updatedFlags[i]);
        }
      }
      for (let i = 0; i < updatedFlags.length; i++) {
        if (
          updatedFlags[i].id === currentUser.uid &&
          updatedFlags[i].page !== pageNumber
        ) {
          filteredFlags.push(updatedFlags[i]);
        }
      }
      updatedFlags = filteredFlags;
      //console.log("Updated flags: ", updatedFlags);
    } else {
      const mapKey = uuidv4();
      updatedFlags.push({
        mapKey: mapKey,
        id: currentUser.uid,
        email: currentUser.email,
        displayName: currentUser.displayName,
        page: pageNumber,
        createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      });
    }

    setDoc(doc(db, "posts", post.id), {
      ...post,
      flags: updatedFlags,
    })
      .then(() => {
        getData();
        //toast.success("Page added to flags successfully");
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  };

  const addInventory = () => {
    let updatedInventory = post.inventory;
    const pageNumber = index + 1;
    const mapKey = uuidv4();

    updatedInventory.push({
      mapKey: mapKey,
      id: currentUser.uid,
      //username: currentUser.forum_username,
      email: currentUser.email,
      page: pageNumber,
      invText,
      displayName: currentUser.displayName,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    });

    setDoc(doc(db, "posts", post.id), {
      ...post,
      inventory: updatedInventory,
    })
      .then(() => {
        getData();
        setInvText("");
        toast.success("Inv. # posted successfully");
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  };

  const goToGridView = () => {
    if (post) {
      navigate(`/post-o-grid/${post.id}`, {state: {scrollY: location.state.scrollY || 0}})
    }
  };

  const getUsersSharedWith = () => {
    getDocs(
      query(
        collection(db, 'sharedPosts'),
        where('post', '==', post.id),
        where('sharedBy', '==', currentUser?.uid)
      )
    ).then(async response => {

      const sharedPosts = response.docs.map(docu => ({
        ...docu.data(),
        id: docu.id
      }))

      const sharedPostsWithUser = await Promise.all(
        sharedPosts.map(async sp => {

          const sharedWith = await getDoc(doc(db, 'users', sp.sharedWith))

          return {
            ...sp,
            sharedWith: sharedWith.data()
          } 
        })
      )
      
      setUsersSharedWith(sharedPostsWithUser.map(sp => sp.sharedWith))

    })
  };

  function setUser(user) {
    // console.log("setUser clicked, user received as parameter = ", user);
    // console.log("user.user.id = ", user.user.id);
    setUserSelect(user);
    setUserSelectId(user.id);
    setUserSet(true);
  }

  const showUserSelection = () => {
    if (userSelect.displayName === "all") {
      return "Show All";
    } else {
      return userSelect.displayName;
    }
  };

  function showAllSet() {
    setUserSelect(allUsers2);
    setUserSelectId("");
    setUserSet(false);
  }

  const getPageURLset = () => {
    const urlSet = [];
    if (post) {
      for (let index = 0; index < post.nPages; index++) {
        const url = null
        urlSet.push(url);
      }
      setUrlSet(urlSet);
      // return urlSet;
    }
  };

  const replyComment = (parentId) => {
    setLoadingReply(true)
    const comment = replyText
    setReplyText('')
    const parentComment = post?.comments?.find(comment => comment.mapKey === parentId);

    if (parentComment) {
      const postRef = doc(db, 'posts', post.id);

      const pageNumber = index + 1;
      const mapKey = uuidv4();

      const newComments = [
        ...post?.comments,
        {
          mapKey: mapKey,
          id: currentUser.uid,
          //username: currentUser.forum_username,
          email: currentUser.email,
          page: pageNumber,
          commentText: comment,
          displayName: currentUser.displayName,
          createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
          parentId: parentId
        }
      ]

      setDoc(postRef, {
        ...post,
        comments: newComments,
      })
        .then(async () => {
          //getData();
          // toast.success("Comment posted successfully");
          await sendNotification(currentUser?.uid, parentComment?.id, 'replyComment', post?.id, null, pageNumber, mapKey)
          setReplyText("");
          setLoadingReply(false)
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    }
  }

  useEffect(() => {
    if(post){
      const postRef = doc(db, 'posts', post.id)
      const unsubscribe = onSnapshot(postRef, (snapshot) => {
        getData()
      });
      return () => unsubscribe()
    }
  }, [post])

  useEffect(() => {
    getPageSelection();
    getData();
    // getUserData();
  }, [params]);

  useEffect(() => {
    getPageURLset();
    getPost();
  }, [post, index]);

  useEffect(() => {
    if (setUserSet) getPost();
  }, [userSelectId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   setAlreadyFlags();
  //   //console.log("activeIndex: ", index);
  //   getData();
  // }, [post]);

  // useEffect(() => {
  //   console.log("userSelectId: ", userSelectId);
  // }, [userSelectId]);

  //console.log(index + 1, pages)

  return (
    <DefaultLayout>
      <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
        <div className="col-12 pb-5 mb-5">
          {" "}
          {post && (
            <Card className="mb-3">
              <Card.Header as="h5">
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                  <div style={{position: 'absolute', left: 0}}>
                    <IoCaretBackSharp
                      size={17}
                      color="dark"
                      style={{ cursor: "pointer" }}
                      onClick={goToGridView}
                    />
                  </div>
                  {post.description}
                </div>
              </Card.Header>

              <Card.Body>
                <span className="text-center">
                  {" "}
                  <Card.Title as="h6">
                    <span className="text-secondary">page </span>
                    <span className="text-muted">{index + 1}</span>
                  </Card.Title>
                </span>

                <>
                  {/* <div className="d-flex justify-content-center pb-1 mb-3">
                    <MDBBadge
                      className="p-2 mx-3 shadow-sm"
                      // style={{ cursor: "crosshair" }}
                      color="white"
                    >
                      <div className="row">
                        <div className="col mx-2">
                          <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={
                              <Popover id={"popover-positioned-bottom"}>
                                <Popover.Header
                                  as="h3"
                                  className="bg-dark text-light text-center"
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: 18,
                                    letterSpacing: 1,
                                  }}
                                >
                                  {"Filter"}
                                </Popover.Header>
                                <Popover.Body>
                                  <ul className="p-0">
                                    <li
                                      className="lh-lg text-center"
                                      style={{
                                        listStyle: "none",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                      }}
                                    >
                                      {" "}
                                      <Navbar bg="light">
                                        <Container>
                                          <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                          <Navbar.Collapse id="basic-navbar-nav">
                                            <Nav className="me-auto">
                                              <NavDropdown
                                                title={showUserSelection()}
                                                id="basic-nav-dropdown"
                                              >
                                                <NavDropdown.Item
                                                  onClick={(e) => {
                                                    showAllSet();
                                                  }}
                                                >
                                                  Show All
                                                </NavDropdown.Item>
                                                {usersSharedWith.map((user) => {
                                                  return (
                                                    <NavDropdown.Item
                                                      key={user.id}
                                                      onClick={(e) => {
                                                        setUser(user);
                                                      }}
                                                    >
                                                      {user.displayName}
                                                    </NavDropdown.Item>
                                                  );
                                                })}
                                              </NavDropdown>
                                            </Nav>
                                          </Navbar.Collapse>
                                        </Container>
                                      </Navbar>
                                    </li>
                                  </ul>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <span className="">
                              <AiFillFilter
                                size={18}
                                color={userSet ? "purple" : "#332D2D"}
                                style={{ cursor: "pointer" }}
                              />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </MDBBadge>
                  </div> */}

                  <div className="w-100">
                    {" "}
                    <div className="d-block text-center ">
                      <Carousel
                        variant="dark"
                        touch={true}
                        keyboard={true}
                        slide={true}
                        fade={true}
                        interval={null}
                        indicators={false}
                        activeIndex={index}
                        onSelect={handleSelect}
                      >
                        {pages.map((page) => {
                          return (
                            <Carousel.Item key={page.page}>
                              <img
                                className="img-fluid rounded"
                                style={{ maxHeight: "60vh" }}
                                src={page.imageURL}
                                alt="carousel slide"
                                onClick={() => setLgShow(true)}
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>
                    </div>
                  </div>
                  {/* <Button onClick={() => setLgShow(true)}>Large modal</Button> */}

                  <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        <span className="text-muted h5">
                          {post.description}
                        </span>
                        <span className="text-secondary h5">
                          - page {index + 1}
                        </span>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {" "}
                      <div className="d-flex justify-content-center align-items-center">
                        {" "}
                        <QuickPinchZoom
                          onUpdate={onUpdate}
                          doubleTapToggleZoom={true}
                          doubleTapZoomOutOnMaxScale={true}
                          draggableUnZoomed
                        >
                          <img
                            ref={imgRef}
                            alt="img"
                            src={pages.find(page => parseInt(page.page) === index + 1)?.imageURL}
                            className="img-fluid rounded"
                            style={{
                              cursor: "pointer",
                              maxHeight: "80vh",
                              width: "100",
                            }}
                          />
                        </QuickPinchZoom>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex mt-3 pt-2 justify-content-center pb-1 pb-sm-2">
                    <div className="d-flex text-center align-items-center mx-2">
                      <AiFillHeart
                        size={23}
                        color={alreadyLiked ? "red" : "gray"}
                      />
                      <OverlayTrigger
                        rootClose={true}
                        trigger="click"
                        placement="top"
                        overlay={
                          <Popover id={"popover-positioned-top"}>
                            <Popover.Header
                              as="h3"
                              className="bg-dark text-light text-center"
                              style={{
                                fontWeight: "normal",
                                fontSize: 18,
                                letterSpacing: 1,
                              }}
                            >
                              {"Likes"}
                            </Popover.Header>
                            <Popover.Body>
                              <ul className="p-0">
                                {likes?.map((like) => {
                                  return (
                                    <li
                                      key={like.mapKey}
                                      className="lh-lg"
                                      style={{
                                        listStyle: "none",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                      }}
                                    >
                                      <div>{like.displayName}</div>
                                      <div
                                        className=""
                                        style={{
                                          fontWeight: "light",
                                          fontSize: 13,
                                          letterSpacing: 1,
                                        }}
                                      >
                                        {like.createdOn.split(" ")[0]}
                                      </div>
                                      <hr />
                                    </li>
                                  );
                                })}
                              </ul>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <MDBBadge
                          color={alreadyLiked ? "dark" : "secondary"}
                          notification
                          pill
                          className="mx-1"
                          style={{ cursor: "pointer" }}
                        >
                          <div className={alreadyLiked ? "dark" : "secondary"}>
                            {likes?.length}
                          </div>
                        </MDBBadge>
                      </OverlayTrigger>
                    </div>

                    <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
                      <AiFillDollarCircle
                        size={23}
                        color={alreadyBuy ? "blue" : "gray"}
                      />

                      <OverlayTrigger
                        rootClose={true}
                        trigger="click"
                        placement="top"
                        overlay={
                          <Popover id={"popover-positioned-top"}>
                            <Popover.Header
                              as="h3"
                              className="bg-dark text-light text-center"
                              style={{
                                fontWeight: "normal",
                                fontSize: 18,
                                letterSpacing: 1,
                              }}
                            >
                              {alreadyOrdered ? inquired : inquire}
                            </Popover.Header>
                            <Popover.Body>
                              <ul className="p-0">
                                {events.map((event) => {
                                  return (
                                    <li
                                      key={event.mapKey}
                                      className="lh-lg"
                                      style={{
                                        listStyle: "none",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                      }}
                                    >
                                      <h6
                                        className="pb-2"
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 15,
                                        }}
                                      >
                                        {event.orderedByDisplayName}
                                      </h6>
                                      <h6
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {event.orderedByDisplayName}
                                        <span>
                                          {" "}
                                          inquired for potential acquisition
                                        </span>
                                      </h6>
                                      <h6
                                        className="text-primary pt-1"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {/* {event.orderedByDisplayName}  */}
                                        Pending seller response
                                      </h6>

                                      {event.urgentChecked ? (
                                        <h6 className="mt-1">
                                          <AiFillThunderbolt
                                            size={18}
                                            //onClick={urgentOrUnurgentPage}
                                            color="orange"
                                            // style={{ cursor: "pointer" }}
                                          />{" "}
                                          <span
                                            className="mx-2 text-danger"
                                            style={{
                                              fontWeight: "normal",
                                              fontSize: 13,
                                            }}
                                          >
                                            {/* {event.orderedByDisplayName}  */}
                                            Urgent request
                                          </span>
                                        </h6>
                                      ) : (
                                        ""
                                      )}

                                      {event.orderText ? (
                                        <h6 className="mt-1">
                                          <BiCommentAdd
                                            size={18}
                                            //onClick={urgentOrUnurgentPage}
                                            color="gray"
                                            // style={{ cursor: "pointer" }}
                                          />{" "}
                                          <span
                                            className="mx-2 text-dark"
                                            style={{
                                              fontWeight: "normal",
                                              fontSize: 13,
                                            }}
                                          >
                                            "{event.orderText}"
                                          </span>
                                        </h6>
                                      ) : (
                                        ""
                                      )}

                                      <div
                                        className=""
                                        style={{
                                          fontWeight: "light",
                                          fontSize: 13,
                                          letterSpacing: 1,
                                        }}
                                      >
                                        {event.createdOn.split(" ")[0]}
                                      </div>

                                      <MDBBadge
                                        // color={alreadyOrdered ? "secondary" : "dark"}
                                        color="dark"
                                        notification
                                        className="d-grid w-100 mt-3 mb-3 rounded text-light text-center shadow-sm"
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 16,
                                          cursor: "pointer",
                                          border: "1px solid gray",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/order-o-status/${event.orderId}`
                                          )
                                        }
                                      >
                                        <div
                                        // className={
                                        //   alreadyOrdered ? "danger" : "secondary"
                                        // }
                                        >
                                          {/* {buys?.length} */}
                                          {/* {alreadyOrdered ? inquired : inquire} */}
                                          Check Status
                                        </div>
                                      </MDBBadge>
                                      <hr />
                                    </li>
                                  );
                                })}
                                <li
                                  className="lh-lg"
                                  style={{
                                    listStyle: "none",
                                    fontWeight: "normal",
                                    fontSize: 14,
                                  }}
                                ></li>
                              </ul>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <MDBBadge
                          color={alreadyOrdered ? "dark" : "secondary"}
                          notification
                          className="mx-1"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className={alreadyOrdered ? "dark" : "secondary"}
                          >
                            {/* {buys?.length} */}
                            {alreadyOrdered ? inquired : inquire}
                          </div>
                        </MDBBadge>
                      </OverlayTrigger>
                    </div>

                    {/* <div className="d-flex text-center align-items-center mx-4">
                      <BsFlagFill
                        size={20}
                        color={alreadyFlag ? "#5bc0de" : "gray"}
                        onClick={flagOrUnflagPage}
                        style={{ cursor: "pointer" }}
                      />
                    </div> */}

                    {/* <div className="d-flex text-center align-items-center mx-4 ">
                      <AiOutlineBarcode
                        size={25}
                        color={alreadyInventoryNumber ? "green" : "gray"}
                      />

                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={
                          <Popover id={"popover-positioned-top"}>
                            <Popover.Header
                              as="h3"
                              className="bg-dark text-light text-center"
                              style={{
                                fontWeight: "normal",
                                fontSize: 18,
                                letterSpacing: 1,
                              }}
                            >
                              {"Inv. #"}
                            </Popover.Header>
                            <Popover.Body>
                              <ul className="p-0">
                                {inventory.map((inv) => {
                                  return (
                                    <li
                                    key={inventory.mapKey}
                                      className="lh-lg"
                                      style={{
                                        listStyle: "none",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                      }}
                                    >
                                      <h6
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 16,
                                        }}
                                      >
                                        {inv.invText}
                                      </h6>

                                      <div
                                        className=""
                                        style={{
                                          fontWeight: "light",
                                          fontSize: 13,
                                          letterSpacing: 1,
                                        }}
                                      >
                                        {inv.createdOn.split(" ")[0]}
                                      </div>
                                      <hr />
                                    </li>
                                  );
                                })}
                                <li
                                  className="lh-lg"
                                  style={{
                                    listStyle: "none",
                                    fontWeight: "normal",
                                    fontSize: 16,
                                  }}
                                >
                                  <div className="">
                                    <textarea
                                      className=""
                                      rows="2"
                                      value={invText}
                                      onChange={(e) => setInvText(e.target.value)}
                                    ></textarea>

                                    <button
                                      className="d-grid w-100 rounded bg-light text-dark text-center border-light shadow-sm"
                                      style={{
                                        buttonStyle: "none",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                      }}
                                      onClick={addInventory}
                                    >
                                      Add Inv. #
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <MDBBadge
                          color={alreadyInventoryNumber ? "dark" : "secondary"}
                          notification
                          pill
                          className="mx-1"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className={alreadyInventoryNumber ? "dark" : "secondary"}
                          >
                            {inventory.length}
                          </div>
                        </MDBBadge>
                      </OverlayTrigger>
                    </div> */}

                    <div className="d-flex text-center align-items-center mx-2">
                      <FaComments
                        size={23}
                        color={alreadyComment ? "pink" : "gray"}
                      />

                      <OverlayTrigger
                        rootClose={true}
                        trigger="click"
                        placement="top"
                        overlay={
                          <Popover id={"popover-positioned-top"} style={{minWidth: '40%'}}>
                            <Popover.Header
                              as="h3"
                              className="bg-dark text-light text-center"
                              style={{
                                fontWeight: "normal",
                                fontSize: 18,
                                letterSpacing: 1,
                              }}
                            >
                              {"Comments"}
                            </Popover.Header>
                            <Popover.Body>
                              <ul className="p-2" style={{maxHeight: '500px', overflowY: 'auto'}}>
                                {comments?.filter(comment => !comment.parentId).map((comment) => {
                                  return (
                                    <li
                                      key={comment.mapKey}
                                      className="lh-lg"
                                      style={{
                                        listStyle: "none",
                                        fontWeight: "normal",
                                        fontSize: 16,
                                      }}
                                    >
                                      <h6
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 13,
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {comment?.displayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{comment?.createdOn?.split(" ")[0]}</span>
                                      </h6>
                                      <h6
                                        style={{
                                          fontWeight: "normal",
                                          fontSize: 13,
                                        }}
                                      >
                                        {comment?.commentText}
                                      </h6>

                                      <h6
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: 13,
                                        }}
                                      >
                                        <a style={{cursor: 'pointer'}} onClick={() => setReplyTo(replyTo === null || replyTo !== comment.mapKey ? comment.mapKey : null)}>
                                          {
                                            comments?.filter(comm => comm.parentId === comment.mapKey).length === 0
                                              ? <><FaReply style={{marginRight: '3px'}}/> Reply</>
                                              : <><FaReply style={{marginRight: '3px'}}/> Replies ({comments?.filter(comm => comm.parentId === comment.mapKey).length})</>
                                          }
                                        </a>
                                      </h6>
                                      {replyTo === comment.mapKey && (
                                          <>
                                            {comments?.filter(comm => comm.parentId === comment.mapKey).map(com => {
                                              return (
                                                <div style={{marginLeft: '30px', marginBottom: '15px'}}>
                                                  <li
                                                    key={com?.mapKey}
                                                    className="lh-lg"
                                                    style={{
                                                      listStyle: "none",
                                                      fontWeight: "normal",
                                                      fontSize: 16,
                                                    }}
                                                  >
                                                    <h6
                                                      style={{
                                                        fontWeight: "bold",
                                                        fontSize: 13,
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                      }}
                                                    >
                                                      {com?.displayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{com?.createdOn?.split(" ")[0]}</span>
                                                    </h6>
                                                    <h6
                                                      style={{
                                                        fontWeight: "normal",
                                                        fontSize: 13,
                                                      }}
                                                    >
                                                      {com?.commentText}
                                                    </h6>
                                                  </li>
                                                  
                                                  </div>
                                                )
                                            })}
                                            <div style={{display: 'flex', marginLeft: '30px', alignItems: 'center', marginBottom: '15px'}}>
                                              <textarea
                                                style={{width: '100%', borderRadius: '10px', resize: 'none'}}
                                                rows="2"
                                                value={replyText}
                                                onChange={(e) => {
                                                  setReplyText(e.target.value)
                                                }}
                                              />
                                              {loadingReply ? <Loader/> : <a style={{marginLeft: '15px', fontSize: 13, fontWeight: 'bold', cursor: 'pointer'}} onClick={() => replyComment(comment.mapKey)}>Send</a>}
                                            </div>
                                          </>
                                        )
                                      }
                                      <hr />
                                    </li>
                                  );
                                })}
                              </ul>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <MDBBadge
                          color={alreadyComment ? "dark" : "secondary"}
                          notification
                          pill
                          className="mx-1"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className={alreadyComment ? "dark" : "secondary"}
                          >
                            {comments?.filter(comment => comment.parentId === null).length}
                          </div>
                        </MDBBadge>
                      </OverlayTrigger>
                    </div>
                  </div>
                </>
                {/* <Card.Text>
                  With supporting text below as a natural lead-in to additional
                  content.
                </Card.Text> */}
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
}

export default PostDescOpage;
