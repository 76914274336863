import React, { useState, useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { query, collection, doc, getDoc, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Card from "react-bootstrap/Card";
import WishlistClientGrid from "../components/WishlistClientGrid";
import { useAuth } from "../context/AuthContext";

function LikedPagesClient() {

  const { currentUser } = useAuth()

  const [likedPages, setLikedPages] = useState([]);

  const getLikedPages = async () => {
    let likesTemp = []
    const docRef = doc(db, "users", currentUser?.uid)
    const docSnap = await getDoc(docRef)
    const likesQuery = query(
      collection(docSnap.ref, "likedPages"),
      orderBy("createdOn", "desc")
    )
    const likesSnap = await getDocs(likesQuery)
    likesSnap.forEach((like) => {
      likesTemp.push({ ...like.data(), id: like.id })
    });
    setLikedPages(likesTemp)
  }

  useEffect(() => {
    getLikedPages();
  }, [currentUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      {currentUser && (
        <>
          {" "}
          <Card>
            <span className="text-center">
              <Card.Header as="h5">MY FEEDBACK</Card.Header>{" "}
            </span>

            <Card.Body>
              <span className="text-center">
                {" "}
                <Card.Title as="h6">
                  <span className="text-secondary">
                    capsule pages you’ve liked
                  </span>
                </Card.Title>
              </span>

              <div className="d-flex justify-content-center row text-center">
                {/* <div className="mt-0 text-center">
                  <MDBBadge
                    className="px-3 pb-1 pt-2 text-center text-dark mt-1 mb-1 shadow-sm"
                    // style={{ cursor: "crosshair" }}
                    color="white"
                  >
                    <h5
                      style={{
                        fontWeight: "300",
                        fontSize: 20,
                        // fontStyle: "italic",
                      }}
                    >
                      Pages liked
                    </h5>
                  </MDBBadge>
                </div> */}

                <div className="row text-center mt-4 mb-5">
                  {likedPages.map((like) => {
                    return (
                      <div
                        key={like.mapKey}
                        className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4"
                      >
                        {" "}
                        <div className="d-inline-flex position-relative" style={{width: '100%'}}>
                          <WishlistClientGrid like={like} />
                          {/* <div>
                        <MDBBadge
                          pill
                          color={order.statusColor}
                          className="position-absolute bottom-100 my-0 start-50 translate-middle-x p-2 border-dark shadow-sm text-dark"
                        >
                          <span className="visually-hidden">Status Color</span>
                        </MDBBadge>
                      </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <Card.Text>
                With supporting text below as a natural lead-in to additional
                content.
              </Card.Text> */}
              {/* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
          </Card>
        </>
      )}
    </DefaultLayout>
  );
}

export default LikedPagesClient;
