import { ChevronDown } from "lucide-react";
import React from "react";
import { NavBarDropDownItem } from "./NavBarDropDownItem";
/**
 * @typedef {import("./types").NavBarDropDownProps}  NavBarDropDownProps
 */
/**
 * @param {object} props
 * @param {NavBarDropDownProps} props.tab
 * @param {boolean} props.isOpen
 * @param {function(string):void} [props.onClick]
 */
export function NavBarDropDown({ tab, isOpen = true, onClick }) {
  return (
    <li
      key={tab.title}
      className="mt-3 lg:!mt-0 pl-2 text-lg md:text-base lg:p-4 text-black  font-medium lg:flex relative cursor-pointer lg:items-center lg:align-middle"
      onClick={() => onClick?.(tab.title)}
    >
      {tab.title}
      <ChevronDown size={18} className="hidden lg:block ml-1"></ChevronDown>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } lg:absolute lg:top-[62px] lg:right-0`}
      >
        <div className="font-normal bg-white divide-y divide-gray-100 rounded-lg lg:shadow w-full lg:w-44 ">
          <ul className="py-2  lg:px-2  text-gray-700 dark:text-gray-400 ">
            {tab.subItems.map((subItem) => (
              <NavBarDropDownItem
                key={subItem.path}
                item={subItem}
              ></NavBarDropDownItem>
            ))}
          </ul>
        </div>
      </div>
    </li>
  );
}
