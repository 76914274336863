import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { IoCaretBackSharp } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";

import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";

import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

import { toast } from "react-toastify";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { sendNotification } from "../utils/notification";
import { useAuth } from "../context/AuthContext";
import AcquisitionChat from "../components/AcquisitionChat";
import VendorShareModal from "../components/VendorShareModal";


function OrderStatusOpage() {

  const [order, setOrder] = useState(null);
  const [artDescription, setArtDesription] = useState(null)

  const [status, setStatus] = useState(null);
  const [statusColor, setStatusColor] = useState("info");
  const [statusNotes, setStatusNotes] = useState(null);

  const { currentUser } = useAuth()

  const params = useParams();
  const navigate = useNavigate()

  const [show, setShow] = useState(false);

  const [showShareWithVendorModal, setShowShareWithVendorModal] = useState(false)

  const [isEditing, setIsEditing] = useState()

  const handleClose = () => {
    setStatus(null)
    setStatusNotes(null)
    setStatusColor("info")
    setIsEditing()
    setShow(false)
  };
  const handleShow = () => setShow(true);

  const theme = createTheme({
    palette: {
      primary: {
        main: grey[700],
      },
    },
  });

  const getData = () => {
    getDoc(doc(db, "orders", params.id))
      .then((response) => {
        setOrder({ ...response.data(), id: response.id });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const getPostInfo = async (id) => {
        const pagesSnapshot = await getDoc(doc(db, 'posts', id, 'pages', order.pageNumber < 10 ? `0${order.pageNumber}` : order.pageNumber.toString())).catch((error) => console.log(error))
        const page = pagesSnapshot.data()
        setArtDesription(page.text)
  }

  const updateStatus = () => {
    let updatedEvents = order.events;

    if (status) {
      const mapKey = uuidv4();
      updatedEvents.push({
        mapKey: mapKey,
        purpose: status,
        purposeNotes: statusNotes ? statusNotes : "",
        purposeColor: statusColor,
        postId: order.postId,
        orderId: order.id,
        orderedById: order.userId,
        orderedByEmail: order.userEmail,
        orderedByDisplayName: order.userDisplayName,
        orderText: order.orderText,
        urgentChecked: order.urgentChecked,
        pageNumber: order.pageNumber,
        imageURL: order.imageURL,
        shareInfo: order.shareInfo,
        createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
      });

      setDoc(doc(db, "orders", order.id), {
        ...order,
        updatedOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
        events: updatedEvents,
        status: status,
        statusNotes: statusNotes ? statusNotes : "",
        statusPrev: order.status,
        statusNotesPrev: order.statusNotes,
        statusColor: statusColor,
        statusPrevColor: order.statusColor,
      })
        .then(async () => {
          await sendNotification(currentUser?.uid, order?.userId, 'orderUpdated', order?.postId, order?.id, order?.pageNumber)
          getData();
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    }
    handleClose();
  };

  const updatePreviousStatus = () => {
    const lastEvent = order.events[order.events.length - 1]

    let updatedEvents = order.events.map(event => {
      if(event.mapKey === isEditing.mapKey)
        return {
          ...event,
          purpose: status,
          purposeNotes: statusNotes ? statusNotes : "",
          purposeColor: statusColor,
        }
      else return event
    })

    if(lastEvent.mapKey === isEditing.mapKey){
      setDoc(doc(db, "orders", order.id), {
        ...order,
        updatedOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
        events: updatedEvents,
        status: status,
        statusNotes: statusNotes ? statusNotes : "",
        statusPrev: order.status,
        statusNotesPrev: order.statusNotes,
        statusColor: statusColor,
        statusPrevColor: order.statusColor,
      })
        .then(async () => {
          await sendNotification(currentUser?.uid, order?.userId, 'orderUpdated', order?.postId, order?.id, order?.pageNumber)
          getData();
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    }else{
      setDoc(doc(db, "orders", order.id), {
        ...order,
        events: updatedEvents
      }).then(() => getData()).catch(() => toast.error("An error occurred"))
    }
    handleClose()
  }

  useEffect(() => {
    getData();
  }, [params]);

  useEffect(() => {
    if(order) getPostInfo(order?.postId)
  }, [order])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      <div className="container" style={{ height: "100vh" }}>
        {order && (
          <Card>
            <Card.Header as="h5">
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
                <div style={{position: 'absolute', left: 0}}>
                  <IoCaretBackSharp
                    size={17}
                    color="dark"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(-1)}
                  />
                </div>
                Sale status
              </div>
            </Card.Header>
            <Card.Body className="d-flex justify-content-center">
              <div>
                <div className="d-flex justify-content-center flex-wrap py-3">
                  {" "}
                  <ThemeProvider theme={theme}>
                    <Box
                      sx={{
                        width: "90%",
                      }}
                    >
                      <Stepper
                        activeStep={order.events.length - 1}
                        alternativeLabel
                        sx={{
                          maxWidth: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {order.events.map((event) => (
                          <Step key={event.mapKey}>
                            <StepLabel
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setIsEditing(event)
                                setStatus(event.purpose)
                                setStatusNotes(event.purposeNotes)
                                setStatusColor(event.purposeColor)
                                handleShow()
                              }}
                            >
                              <div
                                className="p-2"
                                style={{
                                  fontWeight: "390",
                                  fontSize: 14,
                                  // fontStyle: "italic",
                                }}
                              >
                                {" "}
                                {event.purpose}
                              </div>
                            </StepLabel>
                          </Step>
                        ))}
                        <Step>
                          <StepLabel
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={handleShow}
                          >
                            <Button
                              variant="secondary"
                              onClick={handleShow}
                              className="p-1 px-2"
                            >
                              <span
                                style={{
                                  fontWeight: "420",
                                  fontSize: 14,
                                  fontStyle: "italic",
                                }}
                              >
                                {" "}
                                Update
                              </span>
                            </Button>
                          </StepLabel>
                        </Step>
                        {/* <Step>
                  <StepLabel>Acquisition Complete</StepLabel>
                </Step> */}
                      </Stepper>
                    </Box>
                  </ThemeProvider>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Update Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group
                          className="mb-2"
                          controlId="statusForm.ControlInput1"
                        >
                          <span
                            className={`me-2 bg-${statusColor} text-${statusColor} rounded shadow-sm`}
                          >
                            ##
                          </span>
                          <Form.Label>Status:</Form.Label>

                          <Form.Control
                            type="status"
                            placeholder="Enter here, select color below"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            // value={status}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="statusForm.ControlTextArea1"
                        >
                          <div className="row">
                            {" "}
                            <ButtonGroup
                              aria-label="Color-selection"
                              className="shadow-sm"
                            >
                              <Button
                                variant="danger"
                                // className="shadow border-dark mx-1"
                                onClick={() => setStatusColor("danger")}
                              ></Button>
                              <Button
                                variant="info"
                                onClick={() => setStatusColor("info")}
                              ></Button>
                              <Button
                                variant="primary"
                                onClick={() => setStatusColor("primary")}
                              ></Button>
                              <Button
                                variant="warning"
                                onClick={() => setStatusColor("warning")}
                              ></Button>
                              <Button
                                variant="success"
                                onClick={() => setStatusColor("success")}
                              ></Button>
                            </ButtonGroup>
                          </div>
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="statusForm.ControlTextArea1"
                        >
                          <Form.Label>Notes:</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            type="notes"
                            placeholder="Additional details..."
                            value={statusNotes}
                            onChange={(e) => setStatusNotes(e.target.value)}
                            // value={statusNotes}
                          />
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="dark" onClick={isEditing ? updatePreviousStatus : updateStatus}>
                        {isEditing ? 'Update' : 'Submit'}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                <div className="d-flex row w-100 justify-content-center g-0 pb-3">
                  <div className="col-12 col-md-6 d-flex">
                    <div className="card rounded bg-white text-center w-100 d-flex flex-column" style={{height: 'inherit'}}>
                      <h6
                        className={`bg-white p-2 pt-3 pb-3 m-2 rounded border border-${order.statusColor} shadow-sm text-dark`}
                        style={{
                          fontWeight: "350",
                          fontSize: 18,
                          letterSpacing: 1,
                          fontStyle: "italic",
                        }}
                      >
                        {order.status}
                      </h6>
                      <h6
                        className="bg-light p-2 pt-3 pb-3 mx-2 mt-0 mb-0 rounded border border-muted shadow-sm text-dark"
                        style={{
                          fontWeight: "300",
                          fontSize: 15,
                          //letterSpacing: 1,
                          //fontStyle: "italic",
                        }}
                      >
                        {order.statusNotes}
                      </h6>

                      <div className="bg-white p-2 pt-3 pb-3 m-2 rounded border border-muted shadow-sm h-100">
                        <h5
                          className="mb-3"
                          style={{
                            fontWeight: "350",
                            fontSize: 16,
                            // fontStyle: "italic",
                          }}
                        >
                          Inquiry Summary
                        </h5>
                        <hr />
                        <h6
                          className="mb-3"
                          style={{
                            fontWeight: "350",
                            fontSize: 15,
                            // fontStyle: "italic",
                          }}
                        >
                          Requested by:{" "}
                          <span
                            className="ps-1"
                            style={{
                              fontStyle: "italic",
                            }}
                          >
                            {order.userDisplayName}
                          </span>
                        </h6>
                        <h6
                          className=""
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            // fontStyle: "italic",
                          }}
                        >
                          {order.postDescription}
                        </h6>
                        <h6
                          className="mb-3"
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            // fontStyle: "italic",
                          }}
                        >
                          Page {order.pageNumber}
                        </h6>
                        <h6
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            // fontStyle: "italic",
                          }}
                        >
                          Client Request Notes:
                          <span
                            className="ps-1"
                            style={{
                              fontStyle: "italic",
                            }}
                          >
                            {" "}
                            {order.orderText}
                          </span>
                        </h6>
                        {order.urgentChecked && (
                          <h6
                            className=""
                            style={{
                              fontWeight: "300",
                              fontSize: 14,
                              // fontStyle: "italic",
                            }}
                          >
                            Marked Urgent
                          </h6>
                        )}
                        <h6
                          className="pt-2"
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            // fontStyle: "italic",
                          }}
                        >
                          Submitted on {order.createdOn.split(" ")[0]}
                        </h6>
                        <h5
                          className="mb-3"
                          style={{
                            fontWeight: "350",
                            fontSize: 16,
                            marginTop: '30px'
                            // fontStyle: "italic",
                          }}
                        >
                          Art Details
                        </h5>
                        <hr />
                        <h6
                          className=""
                          style={{
                            fontWeight: "300",
                            fontSize: 14,
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          {artDescription}
                        </h6>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-12 col-md-3 mt-2 mt-sm-0 d-flex flex-column" style={{marginLeft: '10px'}}>
                    <img
                      className="p-2 img-thumbnail w-100"
                      src={order.imageURL}
                      alt="art"
                    />
                    <Button
                      className="my-2"
                      variant="outline-dark"
                      onClick={() => setShowShareWithVendorModal(true)}
                    >
                      Share With Vendor
                    </Button>
                    <AcquisitionChat order={order}/> 
                  </div>
                </div>
              </div>
              {/* <Card.Text>
                    With supporting text below as a natural lead-in to additional
                    content.
                  </Card.Text>
                  <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
          </Card>
        )}
      </div>
      {showShareWithVendorModal && <VendorShareModal show={showShareWithVendorModal} setShow={setShowShareWithVendorModal} orderId={order?.id} getOrder={getData}/>}
    </DefaultLayout>
  );
}

export default OrderStatusOpage;
