// @ts-check

import { CustomButton } from "components/atoms/CustomButton";
import Loader from "components/Loader";
import { CircularIcon } from "components/molecules/CircularIcon";
import { UserListItem } from "components/molecules/UserListItem";
import { useAuth } from "context/AuthContext";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "firebaseConfig";
import React, { useMemo } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendNotification } from "utils/notification";
export const ConnectionListItem = ({ item, type, refreshList }) => {
  const { currentUserRealTime: currentUser } = useAuth();
 const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    deleteDoc(
      doc(
        db,
        "users",
        currentUser?.uid,
        !!item.nonUser ? "notMembersInvited" : type,
        !!item.nonUser ? item?.name : item?.id
      )
    )
      .then(() => {
        if (!!item?.nonUser === false) {
          deleteDoc(doc(db, "users", item?.id, type, currentUser?.uid))
            .then(() => {
              toast.success("User removed succesfully");
              refreshList();
              setLoading(false);
            })
            .catch((err) => console.log(err));
        } else {
          toast.success("User removed succesfully");
          refreshList();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error while removing user. Try again!");
        setLoading(false);
      });
  };

  const handleInviteChange = (option) => {
    setLoading(true);

    if (option === "accepted") {
      updateDoc(doc(db, "users", currentUser?.uid, type, item?.id), {
        status: option,
      })
        .then(() => {
          updateDoc(doc(db, "users", item?.id, type, currentUser?.uid), {
            status: option,
          })
            .then(async () => {
              toast.success(`Invite ${option}`);
              await sendNotification(
                currentUser?.uid,
                item?.id,
                "connectionResponse"
              );
            })
            .catch((err) => {
              console.log(err);
              toast.error(
                "Error while responding invitation. Please, try again!"
              );
            });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error while responding invitation. Please, try again!");
        })
        .finally(() => {
          refreshList();
          setLoading(false);
        });
    } else {
      deleteDoc(doc(db, "users", currentUser?.uid, type, item?.id))
        .then(() => {
          deleteDoc(doc(db, "users", item?.id, type, currentUser?.uid))
            .then(async () => {
              toast.success(`Invite ${option}`);
              await sendNotification(
                currentUser?.uid,
                item?.id,
                "connectionResponse"
              );
            })
            .catch((err) => {})
            .catch((err) => {
              console.log(err);
              toast.error(
                "Error while responding invitation. Please, try again!"
              );
            });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error while responding invitation. Please, try again!");
        })
        .finally(() => {
          refreshList();
          setLoading(false);
        });
    }
  };

  const handleFav = () => {
    updateDoc(doc(db, "users", currentUser?.uid, type, item?.id), {
      isFav: !item?.isFav,
    }).then(() => {
        toast.success(
          `User ${item?.isFav ? "removed from":  "added to"}  favorites`
        );
        refreshList()
      })
      .catch((err) =>
        toast.error(
          `Error to ${
            !item.isFav ? "favorite" : "unfavorite"
          }. Please, try again!`
        )
      );
  };

  const userSentInvitation = item?.createdBy === currentUser?.uid;

  const invitationIsPending = item?.status === "pending";

  const subtitle = useMemo(() => {
    if (invitationIsPending && userSentInvitation) {
      return "pending";
    } else {
      return item?.sub || "Not Registered";
    }
  }, [invitationIsPending, item?.sub, userSentInvitation]);
  const navigateToUserProfile = ()=>{
    if(item.nonUser) return 
    if (item?.username) navigate(`/profile/${item.username}`);
  }

  return (
    <>
      <div className="flex items-center md:p-2 md:mx-2 ">
        <UserListItem
          onAvatarClick={()=>navigateToUserProfile()}
          title={item?.name}
          subtitle={subtitle}
          profilePictureUrl={item.profilePicUrl}
        ></UserListItem>
        {((invitationIsPending && userSentInvitation) || item.nonUser) && (
          <PendingInvitationOptions
            loading={loading}
            handleDelete={handleDelete}
          />
        )}
        {invitationIsPending && !userSentInvitation && (
          <>
            {loading ? (
              <Loader />
            ) : (
              <InvitationReceivedOptions
                handleInviteChange={handleInviteChange}
              />
            )}
          </>
        )}{" "}
        {!invitationIsPending && !item.nonUser && (
          <>
            {loading ? (
              <Loader />
            ) : (
              <CurrentFriendOptions
                item={item}
                handleFav={handleFav}
                handleDelete={() => setShowDeleteModal(true)}
              />
            )}
          </>
        )}
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        onDeleteConfirmed={handleDelete}
      ></DeleteConfirmationModal>
    </>
  );
};

const PendingInvitationOptions = ({ loading, handleDelete }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      {loading ? (
        <Loader />
      ) : (
        <CustomButton
          onClick={handleDelete}
          variant="secondary"
          className="text-red-accent h-7 ml-3"
        >
          Cancel
        </CustomButton>
      )}
    </div>
  );
};
const InvitationReceivedOptions = ({ handleInviteChange }) => {
  return (
    <>
      <div className=" align-items-center justify-content-center hidden lg:flex ">
        <CustomButton
          variant="secondary"
          className="h-7"
          onClick={() => handleInviteChange("accepted")}
        >
          Accept
        </CustomButton>
        <CustomButton
          variant="secondary"
          className="text-red-accent h-7 ml-3"
          onClick={() => handleInviteChange("rejected")}
        >
          Decline
        </CustomButton>
      </div>

      <div className="flex lg:hidden align-items-center justify-content-center">
        <CircularIcon
          type="check"
          onClick={() => handleInviteChange("accepted")}
        ></CircularIcon>
        <CircularIcon
          type="x"
          onClick={() => handleInviteChange("rejected")}
          className="text-red-accent !h-8 !w-8 !ml-3"
        ></CircularIcon>
      </div>
    </>
  );
};
const CurrentFriendOptions = ({ item, handleFav, handleDelete }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <CircularIcon
        type="star"
        onClick={handleFav}
        className={`${item?.isFav ? "bg-black text-white" : ""} !h-8 !w-8`}
      ></CircularIcon>
      <CircularIcon
        type="x"
        onClick={handleDelete}
        className="text-red-accent !h-8 !w-8 !ml-3"
      ></CircularIcon>
    </div>
  );
};

const DeleteConfirmationModal = ({ show, setShow ,onDeleteConfirmed }) => {
  return (
    <Modal size="lg" show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <CircularIcon
          type={"x"}
          size={20}
          strokeWidth="1"
          className="!ml-auto !mr-0  hover:cursor-pointer stroke-1"
          onClick={() => setShow(false)}
        />
        <div className="flex flex-col justify-center items-center w-full m-auto !mt-8 ">
          <h3 className="!mb-5">
            Are you sure that you want to remove this connection?
          </h3>
          <p className="!mb-9">
            You won’t be able to receive any other capsules from this person.
            Both can send a new request to each other to be part of your network
            again.
          </p>
          <div className=" grid grid-cols-2 !gap-2 md:!gap-5 w-full">
            <CustomButton variant="secondary" onClick={() => setShow(false)}>Cancel</CustomButton>
            <CustomButton
              className="bg-red-accent border-red-accent"
              onClick={ ()=>{
                setShow(false)
                onDeleteConfirmed()
              }}
            >
              Remove
            </CustomButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
