import React, { useState, useEffect, useCallback, useRef } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAuth } from "../context/AuthContext"
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import IncomingCard from "../components/IncomingCard";
import { Button, Col, Row } from "react-bootstrap";


function Shares() {

  const navigate = useNavigate()

  const [sharedPosts, setSharedPosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [itemsPerPage] = useState(8)
  const [hasMore, setHasMore] = useState(true)
  const observer = useRef()

  const { currentUserRealTime: currentUser } = useAuth()

  const getPosts = useCallback(async () => {
    if(loading || !hasMore) return;
    setLoading(true)

    try {
      const lastDoc = sharedPosts.length > 0 ? sharedPosts[sharedPosts.length - 1].docRef : null
      let q = query(
        collection(db, 'sharedPosts'),
        where('sharedWith', '==', currentUser?.uid),
        where('isArchived', '==', false),
        orderBy('createdAt', 'desc'),
        limit(itemsPerPage)
      )

      if(lastDoc){
        q = query(q, startAfter(lastDoc))
      }

      const querySnapshot = await getDocs(q)

      if(!querySnapshot.empty){
        const newData = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id, docRef: doc}))
        const temp = await Promise.all(
          newData.map(async sh => {
            const postDoc = await getDoc(doc(db, 'posts', sh.post))
            const user = await getDoc(doc(db, 'users', sh.sharedBy))
            return {...sh, post: {...postDoc.data(), id: postDoc.id}, sharedBy: user.data()}
          })
        )
        setSharedPosts(prev => prev.concat(temp))
        if(querySnapshot.size < itemsPerPage) setHasMore(false)
      }else{
        setHasMore(false)
      }

    } catch (error) {
      console.error('Error fetching data:', error)
    } finally { setLoading(false) }

  }, [sharedPosts, loading, hasMore, currentUser])

  const lastElementRef = useCallback(node => {
    if(loading) return;
    if(observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore){
        getPosts()
      }
    })
    if(node) observer.current.observe(node)
  }, [loading, hasMore, getPosts])

  useEffect(() => {
    getPosts();
  }, []);

  const buyCount = (post) => {
    function checkBuy(buy) {
      return buy.id === currentUser?.uid;
    }
    const filteredBuys = post.buys?.filter(checkBuy);
    return filteredBuys.length;
  };

  const urgentCount = (post) => {
    function checkUrgent(urgent) {
      return urgent.id === currentUser?.uid;
    }
    const filteredUrgents = post.urgents.filter(checkUrgent);
    return filteredUrgents.length;
  };

  const likeCount = (post) => {
    function checkLike(like) {
      return like.id === currentUser?.uid;
    }
    const filteredLikes = post.likes?.filter(checkLike);
    return filteredLikes.length;
  };

  const flagCount = (post) => {
    function checkFlag(flag) {
      return flag;
    }
    const filteredFlags = post.flags.filter(checkFlag);
    return filteredFlags.length;
  };

  const commentCount = (post) => {
    function checkComment(comment) {
      return comment.id === currentUser?.uid;
    }
    const filteredComments = post.comments?.filter(checkComment);
    return filteredComments.length;
  };

  return (
    <DefaultLayout>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <span style={{fontSize: '20px', fontWeight: 'bold'}}>Incoming Capsules</span>
          <span style={{fontSize: '14px', color: '#262626'}}>Here are capsules that have been shared with you by sellers in your connections.</span>
        </div>
        <Button
          variant="outline-secondary"
          style={{color: 'black', borderColor: '#D8D8D8', borderRadius: '0'}}
          onClick={() => navigate('/archived-shares')}
        >
          View Archived
        </Button>
      </div>
      <Row style={{marginTop: '20px'}}>
        {sharedPosts.map((sp) => (
          <Col lg={4} md={6} sm={12} key={sp.id}>
            <IncomingCard spId={sp.id}/>
          </Col>
        ))}
      </Row>
      {loading && <Loader/>}
      <div ref={lastElementRef}/>
    </DefaultLayout>
  );
}

export default Shares;
