// @ts-check

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "firebaseConfig";
import moment from "moment";
import { sendNotification } from "utils/notification";
import { isEmail } from "utils/text";
import { SharedPost } from "./SharedPost";
import { Invitation } from "./Invitation";
import { PublicShareLink } from "./PublicShareLink";
import { NetworkUser } from "./NetworkUser";

export class User {
  id;
  bio;
  displayName;
  email;
  hasSub;
  profilePicUrl;
  role;
  stripeId;
  stripeLink;
  username;
  network;
  /**
   * @param {object} params
   * @param {string} [params.id]
   * @param {string} [params.bio]
   * @param {string} [params.displayName]
   * @param {string} [params.email]
   * @param {string} [params.hasSub]
   * @param {string} [params.profilePicUrl]
   * @param {"seller"|"buyer"|"vendor"} [params.role]
   * @param {string} [params.stripeId]
   * @param {string} [params.stripeLink]
   * @param {string} [params.username]
   */
  constructor({
    id,
    bio,
    displayName,
    email,
    hasSub,
    profilePicUrl,
    role,
    stripeId,
    stripeLink,
    username,
  }) {
    this.id = id;
    this.bio = bio;
    this.displayName = displayName;
    this.email = email;
    this.hasSub = hasSub;
    this.profilePicUrl = profilePicUrl;
    this.role = role;
    this.stripeId = stripeId;
    this.stripeLink = stripeLink;
    this.username = username;
  }

  isSeller = () => this?.role === "seller";
  /**
   * @param {User} user
   */
  acceptInvitationFrom = async (user) => {
    await updateDoc(doc(db, "users", this.id, "network", user.id), {
      status: "accepted",
    });
    await updateDoc(doc(db, "users", user.id, "network", this.id), {
      status: "accepted",
    });
  };
  /**
   * @param {User} user
   */
  inviteUserToNetwork = async (user, notify = true) => {
    const friendRequestDoc = await getDoc(
      doc(db, "users", this.id, "network", user.id)
    );

    if (friendRequestDoc.exists()) {
      const friendRequest = friendRequestDoc.data();
      if (friendRequest.status === "pending") {
        const personAlreadyInvitedMe = friendRequest.createdBy == user.id;
        if (personAlreadyInvitedMe) {
          await this.acceptInvitationFrom(user);
        }
      }

      return;
    }

    const invitedUser = {
      sub: user?.role || "buyer",
      name: user?.displayName,
      email: user?.email,
      username: user?.username,
      isFav: false,
      createdAt: moment().format(),
      createdBy: this.id,
      status: "pending",
    };

    const requesterUser = {
      sub: this.isSeller() ? "Seller" : "Buyer",
      name: this?.displayName,
      email: this?.email,
      username: this?.username,
      isFav: false,
      createdAt: moment().format(),
      createdBy: this?.id,
      status: "pending",
    };

    try {
      await setDoc(
        doc(db, "users", this?.id, "network", user?.id),
        invitedUser
      );
      await setDoc(
        doc(db, "users", user?.id, "network", this?.id),
        requesterUser
      );
      if (notify)
        await sendNotification(this?.id, user?.id, "connectionInvite");
    } catch (err) {
      console.log(err);
    }
  };

  notMemberAlreadyInvited = async (email) => {
    const invitationFound = await Invitation.getByEmail({ email });

    // old process
    const isUserInInvitedList = await getDoc(
      doc(db, "users", this.id, "notMembersInvited", email)
    );

    return invitationFound || isUserInInvitedList.exists();
  };

  /**
   *
   * @param {string} email
   * @param {"seller"|"buyer"|"vendor"} asRole
   * @returns
   */
  inviteNewUser = async (email, asRole, notify = true) => {
    const isEmailValid = isEmail(email);
    if (!isEmailValid) {
      throw new Error(`${email} is not a valid email`);
    }
    const alredyInvited = await this.notMemberAlreadyInvited(email);
    if (alredyInvited) {
      throw new Error(`Invite alredy sent to ${email}`);
    }
    if (email === this?.email) {
      throw new Error(`Cannot invite your own email (${email})`);
    }
    const usersDocs = await getDocs(
      query(collection(db, "users"), where("email", "==", email))
    );

    const alreadyUser = usersDocs.docs[0];

    if (alreadyUser) {
      const newUser = new User({ ...alreadyUser.data(), id: alreadyUser.id });
      this.inviteUserToNetwork(newUser, notify);
      return newUser;
    }

    try {
      Invitation.create({ fromUserId: this.id, toEmail: email });

      // TODO old process to invite user starts
      const newUser = {
        type: "network",
        createdAt: moment().format(),
        invitedByAdminAs: asRole,
      };

      setDoc(doc(db, "users", this.id, "notMembersInvited", email), newUser);
      // old process ends

      if (notify)
        await sendNotification(
          this.id,
          null,
          "connectionInvite",
          null,
          null,
          null,
          null,
          email
        );
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * @param {string} postId
   * @param {Array<string>} emailsToInvite
   */
  async sharePostAndInviteByEmail(postId, emailsToInvite, notify = true) {
    const invitingUser = this;
    const shareLink = await PublicShareLink.getOrCreate({
      capsuleId: postId,
      sharedByUserId: this.id,
    });
    for (const email of emailsToInvite) {
      let userAlreadyRegistered;
      try {
        userAlreadyRegistered = await invitingUser.inviteNewUser(
          email,
          "buyer",
          false
        );
      } catch (e) {
        console.log("Invite User error " + e.message);
      }
      if (userAlreadyRegistered) {
        await this.sharePost(postId, userAlreadyRegistered, notify);
        return;
      } else {
        await SharedPost.create({
          postId,
          fromUserId: invitingUser.id,
          toEmail: email,
        });
      }
      if (notify)
        await sendNotification(
          invitingUser.id,
          null,
          "sharePublicPost",
          shareLink.id,
          null,
          null,
          null,
          email
        );
    }
  }

  /**
   * @param {string} postId
   * @param {Array<string>} usersToInvite
   */
  async sharePostAndInviteUsers(postId, usersToInvite, notify = true) {
    const invitingUser = this;
    for (const userId of usersToInvite) {
      const user = await User.getById(userId);
      await invitingUser.inviteUserToNetwork(user, false);
      await SharedPost.create({
        postId,
        fromUserId: invitingUser.id,
        toUserId: user.id,
      });
      if (notify) {
        await sendNotification(invitingUser.id, user.id, "sharePost", postId);
      }
    }
  }

  /**
   * @param {string} postId
   * @param {User} user
   */
  async sharePost(postId, user, notify = true) {
    const invitingUser = this;
    await SharedPost.create({
      postId,
      fromUserId: invitingUser.id,
      toUserId: user.id,
    });
    if (notify) {
      await sendNotification(invitingUser.id, user.id, "sharePost", postId);
    }
  }

  static async getById(id) {
    const userResponse = await getDoc(doc(db, "users", id));
    return new User({ ...userResponse.data(), id: userResponse.id });
  }

  static async getUserNetwork(userId) {
    if (this.network) return this.network;
    const network = NetworkUser.getUserNetwork(userId);
    this.network = network;
    return network;
  }

  static async getAllPublic() {
    const publicUsersQuery = query(
      collection(db, "users"),
      where("role", "!=", "vendor")
    );
    const usersDocs = await getDocs(publicUsersQuery);
    const users = usersDocs.docs
      .filter((doc) => doc.data().hasOwnProperty("role"))
      .map((doc) => new User({ ...doc.data(), id: doc.id }));
    return users;
  }
}
