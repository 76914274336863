import React, { useEffect, useState } from "react";
import { AiFillPhone } from "react-icons/ai";
import { FaShareAlt } from "react-icons/fa";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import moment from "moment";

function SharedWithPopover({ post }) {

  const { currentUser } = useAuth()

  const [usersSharedWith, setUsersSharedWith] = useState([])

  const [alreadyPhoned, setAlreadyPhoned] = useState(false)
  const [alreadyShareRequested, setAlreadyShareRequested] = useState(false)

  const checkRequests = () => {
      setAlreadyShareRequested(post.shareRequests?.length > 0)
      setAlreadyPhoned(post.phones?.length > 0)
  }

  const getUsersSharedWith = () => {
    getDocs(
      query(
        collection(db, 'sharedPosts'),
        where('post', '==', post.id),
        where('sharedBy', '==', currentUser?.uid)
      )
    ).then(async response => {

      const sharedPosts = response.docs.map(docu => ({
        ...docu.data(),
        id: docu.id
      }))

      const sharedPostsWithUser = await Promise.all(
        sharedPosts.map(async sp => {

          const sharedWith = await getDoc(doc(db, 'users', sp.sharedWith))

          return {
            ...sp,
            sharedWith: sharedWith.data()
          } 
        })
      )
      
      setUsersSharedWith(sharedPostsWithUser)

    })
  };

  useEffect(() => {
    if(post){
      getUsersSharedWith(post)
      checkRequests()
    }
  }, [post])


  return (
    <>
      <Popover.Header
        as="h3"
        className="bg-dark text-light text-start"
        style={{
          fontWeight: "normal",
          fontSize: 18,
          letterSpacing: 1,
        }}
      >
        {"Shared with:"}
      </Popover.Header>
      <Popover.Body>
        <ul className="p-0">
          {usersSharedWith.map((sw) => {
            return (
              <li
                key={sw.id}
                className="lh-lg"
                style={{
                  listStyle: "none",
                  fontWeight: "normal",
                  fontSize: 16,
                }}
              >
                <div>{sw.sharedWith.displayName}</div>
                <div
                  className=""
                  style={{
                    fontWeight: "light",
                    fontSize: 13,
                    letterSpacing: 1,
                  }}
                >
                  {moment(sw.createdAt).format('YYYY-MM-DD')}
                </div>
                <hr />
              </li>
            );
          })}
        </ul>
        <div className="d-flex flex-row justify-content-between mb-1">
          {" "}
          <span className="p-2 bg-light border-light rounded shadow-sm">
            <OverlayTrigger
              rootClose={true}
              trigger="click"
              placement="top"
              overlay={
                <Popover
                  id={"popover-positioned-bottom"}
                >
                  <Popover.Header
                    as="h3"
                    className="bg-dark text-light text-center"
                    style={{
                      fontWeight: "normal",
                      fontSize: 18,
                      letterSpacing: 1,
                    }}
                  >
                    {"Call Requests"}
                  </Popover.Header>
                  <Popover.Body>
                    <ul className="p-0">
                      {post?.phones?.map((phone) => {
                        return (
                          <li
                            key={phone.mapKey}
                            className="lh-lg"
                            style={{
                              listStyle: "none",
                              fontWeight: "normal",
                              fontSize: 16,
                            }}
                          >
                            <div>
                              {phone.displayName} has
                              requested a call.
                            </div>

                            <div
                              className=""
                              style={{
                                fontWeight: "light",
                                fontSize: 13,
                                letterSpacing: 1,
                              }}
                            >
                              {
                                phone.createdOn.split(
                                  " "
                                )[0]
                              }
                            </div>
                            <hr />
                          </li>
                        );
                      })}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <span className="">
                <AiFillPhone
                  size={18}
                  color={
                    alreadyPhoned ? "purple" : "dark"
                  }
                  style={{ cursor: "pointer" }}
                />
              </span>
            </OverlayTrigger>
          </span>
          <span className="p-2 bg-light border-light rounded shadow-sm">
            <OverlayTrigger
              rootClose={true}
              trigger="click"
              placement="top"
              overlay={
                <Popover
                  id={"popover-positioned-bottom"}
                >
                  <Popover.Header
                    as="h3"
                    className="bg-dark text-light text-center"
                    style={{
                      fontWeight: "normal",
                      fontSize: 18,
                      letterSpacing: 1,
                    }}
                  >
                    {"Share Requests"}
                  </Popover.Header>
                  <Popover.Body>
                    <ul className="p-0">
                      {post?.shareRequests?.map(
                        (shareRequest) => {
                          return (
                            <li
                              key={
                                shareRequest.mapKey
                              }
                              className="lh-lg"
                              style={{
                                listStyle: "none",
                                fontWeight: "normal",
                                fontSize: 16,
                              }}
                            >
                              <div>
                                {
                                  shareRequest.displayName
                                }{" "}
                                has asked to share
                                this.
                              </div>
                              <div
                                className=""
                                style={{
                                  fontWeight: "light",
                                  fontSize: 13,
                                  letterSpacing: 1,
                                }}
                              >
                                {
                                  shareRequest.createdOn.split(
                                    " "
                                  )[0]
                                }
                              </div>
                              <hr />
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </Popover.Body>
                </Popover>
              }
            >
              <span className="">
                <FaShareAlt
                  size={18}
                  color={
                    alreadyShareRequested
                      ? "green"
                      : "dark"
                  }
                  style={{ cursor: "pointer" }}
                />
              </span>
            </OverlayTrigger>
          </span>
        </div>
      </Popover.Body>
    </>
  );
}

export default SharedWithPopover;
