import React, { useState, useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import Loader from "../components/Loader";
import Card from "react-bootstrap/Card";
import { useAuth } from "../context/AuthContext";
import { toast } from "react-toastify";
import { IoCaretBackSharp } from "react-icons/io5";
import { User } from "domainModels/User";
import { SelectShareUser } from "components/organisms/SelectShareUser";

function Sharepost() {
  const { loading } = useSelector((store) => store);
  const [post, setPost] = useState(null);
  const [data, setData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [alreadySharedUsers, setAlreadySharedUsers] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { currentUserRealTime: currentUser } = useAuth();

  const getData = () => {
    dispatch({ type: "showLoading" });
    getDocs(collection(db, "users", currentUser?.uid, "network"))
      .then((response) => {
        const net = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const netWithCurrentUser = [
          ...net,
          {
            id: currentUser?.uid,
            name: currentUser?.displayName,
            isFav: false,
          },
        ];
        setData(
          netWithCurrentUser.sort((a, b) => {
            if (a.isFav !== b.isFav) return b.isFav - a.isFav;
            return a.name.localeCompare(b.name);
          })
        );
        dispatch({ type: "hideLoading" });
      })
      .catch((err) => {
        dispatch({ type: "hideLoading" });
        console.log(err);
      });
  };

  const getUsersSharedWith = () => {
    getDocs(
      query(collection(db, "sharedPosts"), where("post", "==", post?.id))
    ).then((response) => {
      setAlreadySharedUsers(response.docs.map((doc) => doc.data().sharedWith));
    });
  };

  const getPost = () => {
    dispatch({ type: "showLoading" });
    getDoc(doc(db, "posts", params?.id))
      .then((response) => {
        setPost({ ...response.data(), id: response.id });
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
      });
  };

  const sharePost = async () => {
    dispatch({ type: "showLoading" });
    const user = await User.getById(currentUser?.uid);

    const selectedUsersEmails = selectedUsers
      .filter((user) => user.__isNew__)
      .map((user) => user.value);

    const selectedUsersIds = selectedUsers
      .filter((user) => !user.__isNew__)
      .map((user) => user.id);

    try {
      await user.sharePostAndInviteByEmail(post.id, selectedUsersEmails);
      await user.sharePostAndInviteUsers(post.id, selectedUsersIds);

      toast.success("Post shared successfully");
      dispatch({ type: "hideLoading" });
      navigate(`/profile-grid/`);
    } catch (error) {
      dispatch({ type: "hideLoading" });
      toast.error(error.message);
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      getData();
      getPost();
    }
  }, [currentUser]);

  useEffect(() => {
    if (post) getUsersSharedWith();
  }, [post]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayout>
      <>
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center pb-5 mb-5">
          <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 pb-5 mb-5">
            <div className="pb-sm-4 pb-lg-5 mb-md-3 mb-lg-4 mb-xl-5">
              <Card className="mt-10">
                <Card.Header as="h5">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <div style={{ position: "absolute", left: 0 }}>
                      <IoCaretBackSharp
                        size={17}
                        color="dark"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(-1)}
                      />
                    </div>
                    Share Capsule
                  </div>
                </Card.Header>
                <Card.Body>
                  <span className="text-center">
                    <Card.Title></Card.Title>
                  </span>
                  <div
                    className="d-flex justify-content-center"
                    // style={{ height: "80vh" }}
                  >
                    {post && data && (
                      <>
                        <div className="col-10">
                          <div>
                            <span className="d-flex justify-content-center">
                              {" "}
                              <img
                                src={post?.imageURL}
                                className="img-fluid w-50 row"
                                alt=""
                              />
                            </span>
                            <span>
                              <h6 className="px-3 py-2 mt-2 text-center rounded border-seconary shadow-sm ">
                                Select Users
                              </h6>
                            </span>
                            <SelectShareUser
                              shareUsersList={data}
                              alreadySharedUsersIds={alreadySharedUsers}
                              onChange={(users) => setSelectedUsers(users)}
                            />
                            <span className="d-flex justify-content-center p-1 card mb-3">
                              {" "}
                              <div className="d-grid">
                                <Button
                                  handleClick={sharePost}
                                  disabled={selectedUsers.length === 0}
                                  type="button"
                                  text={loading ? <Loader /> : "Share"}
                                  size="md"
                                />
                              </div>
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </>
    </DefaultLayout>
  );
}

export default Sharepost;
