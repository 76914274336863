import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Popover from "react-bootstrap/Popover";
import { FaReply } from "react-icons/fa";
import { toast } from "react-toastify";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../context/AuthContext";
import { sendNotification } from "../utils/notification";


const CommentsPopover = ({ post, page, comments, getData, showCommentArea }) => {

    const dispatch = useDispatch()
    const { loading } = useSelector((store) => store)

    const { currentUser } = useAuth()

    const [commentText, setCommentText] = useState("")

    const [replyTo, setReplyTo] = useState()
    const [replyText, setReplyText] = useState("")
    const [loadingReply, setLoadingReply] = useState(false)

    const addComment = () => {
      dispatch({ type: "showLoading" });
      
      const comment = commentText
      setCommentText('')
      
      let updatedComments = post.comments;
      const user = currentUser;
      const pageNumber = page;
      const mapKey = uuidv4();
      updatedComments.push({
        mapKey: mapKey,
        id: user.uid,
        //username: currentUser.forum_username,
        email: user.email,
        page: pageNumber,
        commentText: comment,
        displayName: user.displayName,
        createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
        parentId: null
      });
  
      setDoc(doc(db, "posts", post.id), {
        ...post,
        comments: updatedComments,
      }).then(async () => {
          await sendNotification(currentUser?.uid, post?.userId, 'newComment', post?.id, null, pageNumber, mapKey)
          setCommentText("");
          dispatch({ type: "hideLoading" });
          getData()
        })
        .catch(() => {
          toast.error("An error occurred");
        });
    };
  
    const replyComment = (parentId) => {
      setLoadingReply(true)
      
      const comment = replyText
      setReplyText('')
  
      let postId = post?.id
      const parentComment = post?.comments?.find(comment => comment.mapKey === parentId);
  
      if (parentComment && replyText.trim() !== '') {
        const postRef = doc(db, 'posts', postId);
  
        const user = currentUser;
        const pageNumber = page;
        const mapKey = uuidv4();
  
        const newComments = [
          ...post?.comments,
          {
            mapKey: mapKey,
            id: user.uid,
            email: user.email,
            page: pageNumber,
            commentText: comment,
            displayName: user.displayName,
            createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
            parentId: parentId
          }
        ]
  
        setDoc(postRef, {
          ...post,
          comments: newComments,
        })
          .then(async () => {
            await sendNotification(currentUser?.uid, post?.userId, 'replyComment', post?.id, null, pageNumber, mapKey)
            setReplyText("");
            setLoadingReply(false)
            getData()
          })
          .catch(() => {
            toast.error("An error occurred");
          });
  
        setCommentText('')
      }
    }

    return(
      <Popover id={"popover-positioned-top"} style={{minWidth: '300px'}}>
        <Popover.Header
          as="h3"
          className="bg-dark text-light text-center"
          style={{
            fontWeight: "normal",
            fontSize: 18,
            letterSpacing: 1,
          }}
        >
          {"Comments"}
        </Popover.Header>
        <Popover.Body>
          <ul className="p-2" style={{maxHeight: '500px', overflowY: 'auto'}}>
            {comments?.filter(comment => !comment.parentId).map((comment) => {
              return (
                <li
                  key={comment?.mapKey}
                  className="lh-lg"
                  style={{
                    listStyle: "none",
                    fontWeight: "normal",
                    fontSize: 16,
                  }}
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: 13,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {comment?.displayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{comment?.createdOn?.split(" ")[0]}</span>
                  </h6>
                  <h6
                    style={{
                      fontWeight: "normal",
                      fontSize: 13,
                    }}
                  >
                    {comment?.commentText}
                  </h6>
                  <h6
                    style={{
                      fontWeight: "bold",
                      fontSize: 13,
                    }}
                  >
                    <a style={{cursor: 'pointer'}} onClick={() => setReplyTo(replyTo === null || replyTo !== comment.mapKey ? comment.mapKey : null)}>
                      {
                        comments?.filter(comm => comm.parentId === comment.mapKey).length === 0
                          ? <><FaReply style={{marginRight: '3px'}}/> Reply</>
                          : <><FaReply style={{marginRight: '3px'}}/> Replies ({comments?.filter(comm => comm.parentId === comment.mapKey).length})</>
                      }
                    </a>
                  </h6>
                  {/*<button onClick={() => replyComment(comment.id)}>Reply</button>
                  {comment.parentId && (
                    <p>Responding to: {comments?.find(c => c.id === comment.parentId).text}</p>
                  )}*/}
                  {/*<div
                    className=""
                    style={{
                      fontWeight: "light",
                      fontSize: 13,
                      letterSpacing: 1,
                    }}
                  >
                    {comment?.createdOn?.split(" ")[0]}
                  </div>*/}
                  {replyTo === comment.mapKey && (
                      <>
                        {comments?.filter(comm => comm.parentId === comment.mapKey).map(com => {
                          return (
                            <div style={{marginLeft: '30px', marginBottom: '15px'}}>
                              <li
                                key={com?.mapKey}
                                className="lh-lg"
                                style={{
                                  listStyle: "none",
                                  fontWeight: "normal",
                                  fontSize: 16,
                                }}
                              >
                                <h6
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: 13,
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  {com?.displayName} <span style={{fontWeight: 'normal', fontSize: 10, marginLeft: '5px'}}>{com?.createdOn?.split(" ")[0]}</span>
                                </h6>
                                <h6
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: 13,
                                  }}
                                >
                                  {com?.commentText}
                                </h6>
                              </li>
                              
                              </div>
                            )
                        })}
                        <div style={{display: 'flex', marginLeft: '30px', alignItems: 'center', marginBottom: '15px'}}>
                          <textarea
                            style={{width: '100%', borderRadius: '10px', resize: 'none'}}
                            rows="2"
                            value={replyText}
                            onChange={(e) => {
                              setReplyText(e.target.value)
                            }}
                          />
                          {loadingReply ? <Loader/> : <a style={{marginLeft: '15px', fontSize: 13, fontWeight: 'bold', cursor: 'pointer'}} onClick={() => replyComment(comment.mapKey)}>Send</a>}
                        </div>
                      </>
                    )
                  }   
                  <hr />
                </li>
              );
            })}
          </ul>
          {showCommentArea &&
            <div style={{display: 'flex', alignItems: 'center'}}>
              <textarea
                style={{width: '100%', borderRadius: '10px', resize: 'none', marginRight: '15px', height: '70px', fontSize: 16}}
                rows="2"
                value={commentText}
                onChange={(e) => {
                  setCommentText(e.target.value)
                }}
              />
              {loading ? <Loader/> : <a style={{fontSize: 13, fontWeight: 'bold', cursor: 'pointer'}} onClick={addComment}>Send</a>}
            </div>
          }
        </Popover.Body>
      </Popover>
    )
}

export default CommentsPopover;