// Author: Ciaran O'Keeffe
// Copyright © 2023 Ciaran O'Keeffe All Rights Reserved

import React, { useState, useEffect } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useDispatch } from "react-redux";
import { MDBBadge } from "mdb-react-ui-kit";
import Card from "react-bootstrap/Card";
import OrderClientGrid from "../components/OrderGrid";
import { useAuth } from "../context/AuthContext";
import OrderGrid from "../components/OrderGrid";

function StatusSclientGrid() {
  
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);

  const { currentUser, isCurious, isConnoisseur } = useAuth()

  const getOrders = async () => {
    const filter = where(isConnoisseur() ? 'shareInfo.sharedById' : 'userId', '==', currentUser?.uid)

    const queryRef = query(collection(db, "orders"), filter)
    const querySnapshot = await getDocs(queryRef);

    const temp = [];

    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      temp.push({ ...doc.data(), id: doc.id });
    });

    if (temp === null) dispatch({ type: "hideLoading" });

    const filteredOrders = temp.filter((order) => order.userId === currentUser?.uid);
    setOrders(filteredOrders);
    // console.log(filteredOrders);
    //setOrders(temp);
    dispatch({ type: "hideLoading" });
  };

  const filterOrders = order => {
    if(isConnoisseur()) return !order.archivedByConnoisseur
    if(isCurious()) return !order.archivedByCurious
    return true
  }

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <DefaultLayout>
      {currentUser && (
        <>
          {" "}
          <Card>
            <span className="text-center">
              <Card.Header as="h5">Pending acquisitions</Card.Header>
            </span>
            <Card.Body>
              <span className="text-center">
                <Card.Title as="h6">
                  <span className="text-secondary">your sale requests</span>
                </Card.Title>
              </span>

              <div className="d-flex justify-content-center row text-center">
                {/* <div className="mt-0 text-center">
                  <MDBBadge
                    className="px-3 pb-1 pt-2 text-center text-dark mt-1 mb-1 shadow-sm"
                    // style={{ cursor: "crosshair" }}
                    color="white"
                  >
                    <h5
                      style={{
                        fontWeight: "300",
                        fontSize: 20,
                        // fontStyle: "italic",
                      }}
                    >
                      Pending acquisitions
                    </h5>
                  </MDBBadge>
                </div> */}

                <div className="row text-center mt-4 mb-5">
                  {orders.filter(filterOrders).map((order) => {
                    // let alreadyBuy = false;
                    // let alreadyUrgent = false;
                    // let alreadyLiked = false;
                    // let alreadyComment = false;
                    // let showFeedbackBar = false;
                    // if (post.buys.length > 0) {
                    //   alreadyBuy = true;
                    // }
                    // if (post.urgents.length > 0) {
                    //   alreadyUrgent = true;
                    // }
                    // if (post.likes.length > 0) {
                    //   alreadyLiked = true;
                    // }
                    // if (post.comments.length > 0) {
                    //   alreadyComment = true;
                    // }
                    // if (
                    //   alreadyBuy === true ||
                    //   alreadyLiked === true ||
                    //   alreadyComment === true
                    // ) {
                    //   showFeedbackBar = true;
                    // }
                    return (
                      <div
                        key={order.id}
                        className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4"
                      >
                        {" "}
                        <div className="d-inline-flex position-relative">
                          <OrderGrid order={order} getOrders={getOrders}/>
                          <div>
                            <MDBBadge
                              pill
                              color={order.statusColor}
                              className="position-absolute top-0 my-4 mx-3 end-0 p-2 border-dark shadow-sm text-dark"
                            >
                              <span className="visually-hidden">
                                Status Color
                              </span>
                            </MDBBadge>
                          </div>
                          {/* {post.flags.map((flag) => {
                        if (post.flags.length > 0)
                          return (
                            <div key={flag.mapKey}>
                              <MDBBadge
                                pill
                                color="info"
                                className="position-absolute top-0 start-0 p-2 bg-primary border-dark"
                              >
                                {post.flags.length}
                                <span className="visually-hidden">
                                  New alerts
                                </span>
                              </MDBBadge>
                            </div>
                          );
                      })} */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <Card.Text>
                With supporting text below as a natural lead-in to additional
                content.
              </Card.Text>
              <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
          </Card>
        </>
      )}
    </DefaultLayout>
  );
}

export default StatusSclientGrid;
