import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { ChevronDown, Hand, Heart, Menu, MessageCircle } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Carousel, Dropdown, Image } from "react-bootstrap";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import CommentsModal from "./CommentsModal";
import InquiresModal from "./InquiresModal";
import RequestsModal from "./RequestsModal";

const CustomMenuToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #D8D8D8',
      borderRadius: '50%',
      padding: '5px',
      cursor: 'pointer'
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const CustomRequestToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #D8D8D8',
      padding: '4px 8px',
      cursor: 'pointer'
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <span style={{fontSize: '14px', fontWeight: 'bold'}}>{children}</span>
    <ChevronDown style={{paddingTop: '2px'}}/>
  </div>
));


function OutgoingCard({ postId, setPosts, getPosts }) {

  const navigate = useNavigate()

  const [post, setPost] = useState(null)

  const [loadingPages, setLoadingPages] = useState(false)
  const [pages, setPages] = useState([])
  const [page, setPage] = useState(1)

  const [nThreads, setNThreads] = useState(0)
  const [likes, setLikes] = useState([])
  const [onHolds, setOnHolds] = useState([])
  const [comments, setComments] = useState([])
  const [inquires, setInquires] = useState([])
  const [shareRequests, setShareRequests] = useState([])
  const [callRequests, setCallRequests] = useState([])

  const [showCommentsModal, setShowCommentsModal] = useState(false)
  const [showInquiresModal, setShowInquiresModal] = useState(false)
  const [showShareRequestsModal, setShowShareRequestsModal] = useState(false)
  const [showCallRequestsModal, setShowCallRequestsModal] = useState(false)

  const getPost = () => {
    getDoc(doc(db, 'posts', postId)).then(res => {
      setPost({...res.data(), id: res.id})
    }).catch(err => console.log(err))
  }

  const getPages = () => {
    setLoadingPages(true)
    getDocs(collection(db, 'posts', post?.id, 'pages')).then(res => {
      setPages(res.docs.map(d => ({...d.data(), id: d.id})))
    }).finally(() => setLoadingPages(false))
  }

  const handleOnSlid = (e) => {
    setPage(e + 1)
  }

  const archivePost = async () => {
    updateDoc(doc(db, 'posts', post?.id), {
      archived: true
    }).then(() => {
      toast.success("Capsule archived");
      setPosts(prev => prev.filter(p => p.id !== post?.id))
      getPosts()
    }).catch(err => console.log(err))
  }

  const getLikes = (page) => {
    function checkLike(like) {
      return like.page === parseInt(page);
    }
    setLikes(post?.likes?.filter(checkLike) || [])
  }

  const getOnHolds = (page) => {
    const checkOnHold = (hold) => hold.page === parseInt(page)
    setOnHolds(post?.onHold?.filter(checkOnHold) || [])
  }

  const getComments = (page) => {
    function checkComment(comment) {
      return comment.page === parseInt(page);
    }
    setComments(post?.comments?.filter(checkComment) || []);
  }

  const getInquires = (page) => {
    function checkInquires(inquire) {
      return inquire.pageNumber === parseInt(page)
    }
    setInquires(post?.events?.filter(checkInquires) || [])
  }

  const getShareRequests = () => {
    setShareRequests(post?.shareRequests)
  }

  const getCallRequests = () => {
    setCallRequests(post?.phones)
  }

  useEffect(() => {
    setNThreads(likes?.length + onHolds.length)
  }, [likes, onHolds])

  useEffect(() => {
    if(post && page){
      getLikes(page)
      getOnHolds(page)
      getComments(page)
      getInquires(page)
      getShareRequests()
      getCallRequests()
    }
  }, [page, post])

  useEffect(() => {
    if(post) getPages()
  }, [post])

  useEffect(() => {
    getPost()
  }, [postId])

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', border: '1px solid #D8D8D8', marginBottom: '24px'}}>
      <div style={{display: 'flex', justifyContent: 'space-between', padding: '15px', alignItems: 'center', width: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', overflow: 'hidden'}}>
          <span style={{fontSize: '18px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%'}}>{post?.description}</span>
          <span style={{color: '#7D7D7D', fontSize: '12px'}}>{moment(post?.createdAt).format("MMM DD, YYYY")}</span>
        </div>
        <Dropdown style={{flex: '0 0 auto'}}>
          <Dropdown.Toggle as={CustomMenuToggle}>
            <Menu color="#262626" size='16px'/>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{fontSize: '14px'}}>
            <Dropdown.Item onClick={() => navigate(`/sharepost/${post?.id}`)}>Share...</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowInquiresModal(!showInquiresModal)}>Inquires</Dropdown.Item>
            <Dropdown.Divider/>
            <Dropdown.Item style={{color: 'red'}} onClick={archivePost}>Archive Capsule</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {loadingPages ?
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px', width: '100%'}}>
          <Loader/>
        </div> : 
        <Carousel interval={null} onSlid={handleOnSlid} indicators={false} style={{margin: '0 -1px'}}>
          {pages.map(page => (
            <Carousel.Item id={page.id}>
              <Image
                fluid
                src={page.imageURL}
                style={{height: '400px', objectFit: 'cover', width: '100%', cursor: 'pointer'}}
                onClick={() => navigate(`/post-o-grid/${post?.id}`)}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      }
      <div style={{display: 'flex', justifyContent: 'space-between', padding: '15px 15px 0 15px', alignItems: 'center'}}>
        <div style={{display: 'flex', alignItems: 'start'}}>
          <Heart style={{marginRight: '3px', color: '#262626'}} strokeWidth={1} fill={likes?.length > 0 ? '#C90000' : 'white'}/>
          <MessageCircle style={{marginRight: '3px', color: '#262626'}} strokeWidth={1}/>
          <Hand style={{marginRight: '3px', color: '#262626'}} strokeWidth={1} fill={onHolds.length > 0 ? '#F5CE02' : 'white'}/>
        </div>
        <Dropdown>
          <Dropdown.Toggle as={CustomRequestToggle}>
            Requests
          </Dropdown.Toggle>
          <Dropdown.Menu style={{fontSize: '14px'}}>
            <Dropdown.Item onClick={() => setShowCallRequestsModal(!showCallRequestsModal)}>See Call Requests</Dropdown.Item>
            <Dropdown.Item onClick={() => setShowShareRequestsModal(!showShareRequestsModal)}>See Share Requests</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{display: 'flex', alignItems: 'start', flexDirection: 'column', padding: '0 15px 15px 15px'}}>
        <span style={{color: '#262626', fontSize: '10px'}}>{nThreads} Threads</span>
        <span
          style={{color: '#7D7D7D', fontSize: '10px', cursor: 'pointer'}}
          onClick={() => setShowCommentsModal(!showCommentsModal)}
        >
          View {comments?.filter(comm => comm.parentId === null).length !== 0 && comments?.filter(comm => comm.parentId === null).length} Comments
        </span>
      </div>
      {showCommentsModal &&
        <CommentsModal
          show={showCommentsModal}
          setShow={setShowCommentsModal}
          comments={comments}
          getPost={getPost}
          post={post}
          page={page}
          showTextArea={false}
        />
      }
      {showInquiresModal &&
        <InquiresModal
          show={showCommentsModal}
          setShow={setShowCommentsModal}
          inquires={inquires}
        />
      }
      {showShareRequestsModal && 
        <RequestsModal
          show={showShareRequestsModal}
          setShow={setShowShareRequestsModal}
          requests={shareRequests}
          title='Share Requests'
        />
      }
      {showCallRequestsModal && 
        <RequestsModal
          show={showCallRequestsModal}
          setShow={setShowCallRequestsModal}
          requests={callRequests}
          title='Call Requests'
        />
      }
    </div>
  );
}

export default OutgoingCard;
