/**
 * @typedef {import("../types").NavBarDropDownProps}  NavBarDropDownProps
 */
/**
 * @type {NavBarDropDownProps}
 */
export const buyerTab = {
  title: "I Want to Buy",
  subItems: [
    {
      title: "Incoming Capsules",
      path: `/shares/`,
    },
    {
      title: "Pending Purchases",
      path: `/acquisition-status-buyer/`,
    },
    {
      title: "My Feedback",
      path: `/my-feedback/`,
    },
    {
      title: "My Collection",
      path: `/collection-client/`,
    },
  ],
};
/**
 * @type {NavBarDropDownProps}
 */
export const sellerTab = {
  title: "I Want to Sell",
  subItems: [
    {
      title: "Outgoing Capsules",
      path: `/profile-grid/`,
    },
    {
      title: "Pending Sales",
      path: `/acquisition-status-seller/`,
    },
    {
      title: "Upload New Capsule",
      path: `/addPost/`,
    },
  ],
};
/**
 * @type {NavBarDropDownProps}
 */
export const vendorTab = {
  title: "VENDOR",
  subItems: [
    {
      title: "Pending Purchases",
      path: `/acquisition-status-buyer/`,
    },
  ],
};
/**
 * @type {NavBarDropDownProps}
 */
export const NoAccountTab = {
  title: "Account",
  subItems: [
    {
      title: "Log in",
      path: "/login",
    },
    {
      title: "Register",
      path: "/register",
    },
  ],
};
