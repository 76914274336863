import React, { useState, useCallback, useRef, useEffect } from "react";
import { Carousel, Modal } from "react-bootstrap";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import { useAuth } from "../context/AuthContext";
import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useDispatch } from "react-redux";
import { MDBBadge } from "mdb-react-ui-kit";
import {
  AiFillHeart,
  AiFillDollarCircle,
} from "react-icons/ai";
import { FaChevronLeft, FaChevronRight, FaHandPaper } from "react-icons/fa"
import { BiCommentAdd } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import InquirePopover from "./InquirePopover";
import CommentsPopover from "./CommentsPopover";

function IncomingPreviewModal({ post, page, show, setShow, getData }) {

  const dispatch = useDispatch()

  const { currentUser } = useAuth()

  const imgRef = useRef()

  const [index, setIndex] = useState(parseInt(page.page))
  const [pages, setPages] = useState([])

  const [alreadyLiked, setAlreadyLiked] = useState(false)
  const [loadingLike, setLoadingLike] = useState(false)
  const [alreadyBought, setAlreadyBought] = useState(false)
  const [alreadyCommented, setAlreadyCommented] = useState(false)
  const [handsUp, setHandsUp] = useState(false)
  const [loadingHand, setLoadingHand] = useState(false)


  const [showInquirePopover, setShowInquirePopover] = useState(false)
  const [showCommentsPopover, setShowCommentsPopover] = useState(false)

  const [comments, setComments] = useState([])

  const getPages = () => {
    getDocs(query(collection(db, 'posts', post?.id, 'pages'))).then(res => {
      setPages(res.docs.map(docu => ({...docu.data(), id: docu.id})).sort((a,b) => parseInt(a.id) - parseInt(b.id)))
    }).catch((err) => {
      console.log(err)
    })
  }

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;
    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, []);

  const likePage = () => {
    setLoadingLike(true)
    let user = currentUser;
    let updatedLikes = post.likes;
    const pageNumber = index;

    const mapKey = uuidv4();
    updatedLikes.push({
      mapKey: mapKey,
      id: user.uid,
      email: user.email,
      displayName: user.displayName,
      page: pageNumber,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    });
    const mapKey2 = uuidv4();

    const imageURL = page.imageURL || null
    // add post page data to user collection, likedPages
    addDoc(collection(db, "users", user.uid, "likedPages"), {
      mapKey: mapKey2,
      userId: user.uid,
      userEmail: user.email,
      userDisplayName: user.displayName,
      postId: post.id,
      postDesc: post.description,
      postCreatedById: post.userId,
      postCreatedByEmail: post.userEmail,
      postCreatedByDisplayName: post.userDisplayName,
      page: pageNumber,
      imageURL: imageURL,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    })
      .then(() => {
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
        toast.error("Something went wrong");
      });

    setDoc(doc(db, "posts", post.id), {
      ...post,
      likes: updatedLikes,
    })
      .then(() => {
        getData();
        setLoadingLike(false)
      })
      .catch(() => {
        toast.error("An error occurred");
        setLoadingLike(false)
      });
  }

  const unlikePage = () => {
    let user = currentUser;
    let updatedLikes = post.likes;
    let filteredLikes = [];
    const pageNumber = index;

    for (let i = 0; i < updatedLikes.length; i++) {
      if (updatedLikes[i].id !== user.uid) {
        filteredLikes.push(updatedLikes[i]);
      }
    }
    for (let i = 0; i < updatedLikes.length; i++) {
      if (
        updatedLikes[i].id === user.uid &&
        updatedLikes[i].page !== pageNumber
      ) {
        filteredLikes.push(updatedLikes[i]);
      }
    }
    
    getDocs(
      query(
        collection(db, "users", user.uid, "likedPages"),
        where("postId", "==", post?.id),
        where("page", "==", pageNumber)
      )
    ).then(result => {
      const doc = result.docs[0]
      deleteDoc(doc.ref).catch(err => toast.error("An error occurred"))
    })
     .catch(err => toast.error("An error occurred"))

    setDoc(doc(db, "posts", post.id), {
      ...post,
      likes: filteredLikes,
    }).then(() => {
        getData();
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const upHand = () => {
    setLoadingHand(true)
    if(handsUp) return

    let onHold = post.onHold || [];
    const pageNumber = index;

    const mapKey = uuidv4();
    onHold?.push({
      mapKey: mapKey,
      id: currentUser.uid,
      email: currentUser.email,
      displayName: currentUser.displayName,
      page: pageNumber,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    });

    addDoc(collection(db, "users", currentUser.uid, "onHolds"), {
      mapKey: mapKey,
      userId: currentUser.uid,
      userEmail: currentUser.email,
      userDisplayName: currentUser.displayName,
      postId: post.id,
      postDesc: post.description,
      postCreatedById: post.userId,
      postCreatedByEmail: post.userEmail,
      postCreatedByDisplayName: post.userDisplayName,
      page: pageNumber,
      imageURL: page.imageURL,
      createdOn: moment().format("YYYY-MM-DD hh:mm:ss a"),
    })
      .then(() => {
        dispatch({ type: "hideLoading" });
      })
      .catch(() => {
        dispatch({ type: "hideLoading" });
        toast.error("Something went wrong");
      });

    setDoc(doc(db, "posts", post.id), {
      ...post,
      onHold: onHold,
    })
      .then(() => {
        getData();
        setLoadingHand(false)
      })
      .catch(() => {
        toast.error("An error occurred");
        setLoadingHand(false)
      });
  }

  const downHand = () => {
    if(!handsUp) return

    const pageNumber = index

    getDocs(
      query(
        collection(db, "users", currentUser.uid, "onHolds"),
        where("postId", "==", post?.id),
        where("page", "==", pageNumber)
      )
    ).then(result => {
      const doc = result.docs[0]
      deleteDoc(doc.ref).catch(err => toast.error("An error occurred"))
    })
     .catch(err => toast.error("An error occurred"))

    setDoc(doc(db, "posts", post.id), {
      ...post,
      onHold: post.onHold?.filter(obj => obj.id !== currentUser.uid && obj.page !== pageNumber),
    }).then(() => {
        getData();
      })
      .catch(() => {
        toast.error("An error occurred");
      });
  }

  const getAlreadyLiked = () => {
      if (post.likes) {
        let updatedLikes = post.likes;
        let filteredLikes = [];
        let finalFilteredLikes = [];
        const pageNumber = index;

        for (let i = 0; i < updatedLikes.length; i++) {
          if (updatedLikes[i].page === pageNumber) {
            filteredLikes.push(updatedLikes[i]);
          }
        }
        for (let i = 0; i < filteredLikes.length; i++) {
          if (filteredLikes[i].id !== currentUser.uid) {
            finalFilteredLikes.push(filteredLikes[i]);
          }
        }

        if (filteredLikes.find((user) => user.id === currentUser.uid)) {
          setAlreadyLiked(true);
        } else {
          setAlreadyLiked(false);
        }
      }
  }

  const getAlreadyBought = () => {
    if (post.buys) {
      let updatedBuys = post.buys;
      let filteredBuys = [];
      let finalFilteredBuys = [];
      const pageNumber = index;

      for (let i = 0; i < updatedBuys.length; i++) {
        if (updatedBuys[i].page === pageNumber) {
          filteredBuys.push(updatedBuys[i]);
        }
      }
      for (let i = 0; i < filteredBuys.length; i++) {
        if (filteredBuys[i].id !== currentUser.uid) {
          finalFilteredBuys.push(filteredBuys[i]);
        }
      }

      if (filteredBuys.find((user) => user.id === currentUser.uid)) {
        setAlreadyBought(true);
      } else {
        setAlreadyBought(false);
      }
    }
  }

  const getAlredyCommented = () => {
    if (post.comments) {
      const filteredComments = [];

      for (let i = 0; i < post.comments?.length; i++) {
        if (
          post.comments[i].page === index &&
          post.comments[i].id === currentUser.uid ||
          post.comments[i].parentId !== null
        ) {
          filteredComments.push(post.comments[i]);
        }
      }
      setComments(filteredComments);
      if (filteredComments.find((user) => user.id === currentUser.uid)) {
        setAlreadyCommented(true);
      } else {
        setAlreadyCommented(false);
      }
    }
  }

  const getAlreadyOnHold = () => {
    getDocs(query(collection(db, 'users',  currentUser.uid, 'onHolds'), where('postId', '==', post.id))).then(response => {
      if(response.docs.map(doc => doc.data()).find(obj => obj.page === index)){
        setHandsUp(true)
      }else{
        setHandsUp(false)
      }
    })
  }

  useEffect(() => {
    getPages()
    getAlreadyLiked()
    getAlreadyBought()
    getAlredyCommented()
    getAlreadyOnHold()
  }, [post, index])

  return (
    <Modal
      size="lg"
      show={show !== null}
      onHide={() => setShow(null)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <span className="text-muted h5">{post.description} </span>
          <span className="text-secondary h5">- page {page.page}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{maxHeight: '85vh', overflowY: 'auto'}}>
        {" "}
        <div className="d-flex justify-content-center align-items-center" style={{width: '100%'}}>
          <div className="d-flex align-items-center">
            <FaChevronLeft size={24} onClick={() => {if(index > 0) setIndex(prev => prev-1)}} style={{cursor: 'pointer', color: index <= 0 && 'grey'}}/>
          </div>
          <Carousel
            variant="dark"
            touch={true}
            keyboard={true}
            slide={true}
            fade={true}
            interval={null}
            indicators={false}
            controls={false}
            activeIndex={index - 1}
            onSelect={(selectedIndex, e) => {
              setIndex(selectedIndex);
            }}
          >
            {pages.map((page) => {
              return (
                <Carousel.Item key={parseInt(page.page)} style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <QuickPinchZoom
                    onUpdate={onUpdate}
                    doubleTapToggleZoom={true}
                    doubleTapZoomOutOnMaxScale={true}
                    draggableUnZoomed
                  >
                    <img
                      className="img-fluid rounded"
                      style={{
                        cursor: "pointer",
                        maxHeight: "60vh",
                      }}
                      src={page.imageURL}
                      alt="carousel slide"
                    />
                  </QuickPinchZoom>
                </Carousel.Item>
              );
            })}
          </Carousel>
          <div className="d-flex align-items-center">
            <FaChevronRight size={24} onClick={() => {if(index < post?.nPages - 1) setIndex(prev => prev+1)}} style={{cursor: 'pointer', color: index >= post?.nPages - 1 && 'grey'}}/>
          </div>
        </div>
        <div className="d-flex mt-3 pt-2 justify-content-center pb-1 pb-sm-2">
          <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
            <AiFillHeart
              size={23}
              color={alreadyLiked ? "red" : "gray"}
              onClick={!loadingLike ? alreadyLiked ? unlikePage : likePage : null}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5">
            <FaHandPaper
              size={23}
              color={handsUp ? "#FFAB01" : "gray"}
              onClick={!loadingHand ? handsUp ? downHand : upHand : null}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5" style={{position: 'relative'}}>
            {showInquirePopover &&
              <div  style={{position: 'absolute', bottom: '30px', right: '-70px', zIndex: '9'}}>
                <InquirePopover
                  post={post}
                  page={page}
                  alreadyLiked={alreadyLiked}
                  likePage={likePage}
                  alreadyBought={alreadyBought}
                  getData={getData}
                />
              </div>
              }
              <div>
                <AiFillDollarCircle
                  size={23}
                  color={alreadyBought ? "blue" : "gray"}
                  style={{ cursor: "pointer" }}
                  onClick={()=> setShowInquirePopover(!showInquirePopover)}
                />
              </div>

          </div>
          <div className="d-flex text-center align-items-center mx-2 mx-sm-3 mx-md-4 mx-xl-5" style={{position: 'relative'}}>
            {showCommentsPopover &&
              <div  style={{position: 'absolute', bottom: '30px', right: '-30px', zIndex: '9'}}>
                <CommentsPopover
                  post={post}
                  page={index}
                  comments={comments}
                  getData={getData}
                  showCommentArea={true}
                />
              </div>
              }
            <BiCommentAdd
              size={23}
              color={alreadyCommented ? "pink" : "gray"}
              onClick={()=> setShowCommentsPopover(!showCommentsPopover)}
            />
            <MDBBadge
              color={alreadyCommented ? "dark" : "secondary"}
              notification
              pill
              className="mx-1"
              style={{ cursor: "pointer" }}
              onClick={()=> setShowCommentsPopover(!showCommentsPopover)}
            >
              <div
                className={alreadyCommented ? "dark" : "secondary"}
              >
                {comments?.filter(comment => comment.parentId === null).length}
              </div>
            </MDBBadge>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center" style={{whiteSpace: "pre-wrap", paddingTop: '10px'}}>
          {pages.find(page => parseInt(page.page) === index)?.text}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default IncomingPreviewModal;
