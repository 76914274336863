// Author: Ciaran O'Keeffe (Ciaran OKeeffe)
// Copyright © 2023 Ciaran OKeeffe All Rights Reserved

import React from "react";
// import { useSelector } from "react-redux";
// import Loader from "./Loader";
// import NavDarkReact from "./NavDarkReact";
//import NavDarkReact from "./NavDarkReact";
//import Header from "./Header";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";

function DefaultLayoutEmbedded(props) {
  // console.log(
  //   "Code author: Ciaran O'Keeffe, Copyright © 2023 Ciaran OKeeffe All Rights Reserved"
  // );
  // const { loading } = useSelector((store) => store);

  return (
    <>
      {/* {loading && <Loader />} */}
      <Container fluid className="mt-5 pt-3 px-0 mx-0">
        {/* <Row className="mb-5 pb-3 px-0 mx-0">
          <Col className="px-0 mx-0 pb-xl-3">
            <NavDarkReact />
          </Col>
        </Row> */}
        {props.children}
      </Container>
      {/* <div className="container">
        <div className="mb-5">
          <NavDarkReact />
        </div>

        <div className="pt-3 pt-lg-4 pt-xl-5">{props.children}</div>
      </div> */}
    </>
  );
}

export default DefaultLayoutEmbedded;

//#f2f2f2
