import React, { useEffect } from "react";
import DefaultLayoutEmbedded from "../components/DefaultLayoutEmbedded";

const Drive = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultLayoutEmbedded>
      {" "}
      <div
        className=""
        style={{
          height: "100vh",
          border: "0",
          overflowY: "scroll",
          scrollbarWidth: "none" /* Firefox */,
          msOverflowStyle: "none",
          paddingLeft: "0vw",
          paddingRight: "0vw",
          margin: "0",
          // scrolling: "no",
        }}
      >
        <iframe
          src="https://drive.google.com/embeddedfolderview?id=1bA5hkiRCJpvE8lt6KvRMas2C2uzzk9U1#grid"
          style={{
            width: "100vw",
            height: "100vh",
            border: "0",
            paddingLeft: "0vw",
            paddingRight: "0vw",
            margin: "0",
          }}
          className=""
        ></iframe>
      </div>
    </DefaultLayoutEmbedded>
  );
};

export default Drive;
