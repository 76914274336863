import React from "react";

function Loader() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
      <div
        className="spinner-border text-secondary"
        style={{
          height: "2vh",
          width: "2vh",
          borderWidth: "1px",
        }}
        role="status"
      >
      </div>
    </div>
  );
}

export default Loader;
